import VueRouter from 'vue-router'
import UserController from '@/controllers/UserController'
import link from '@/utils/links'
import AppService from '@/services/AppService'
import LoginService from '@/components/signin/LoginService'
import RollbarFactory from '@/factory/RollbarFactory'
import InviteYourTeamScreen from '@/components/Onboarding/Components/InviteYourTeamScreen.vue'
import BillingService from '@/components/configuration/Services/BillingService'
import V6RedirectionController from '@/controllers/V6RedirectionController'

// Lazy loading all the components
// const SigninScreen = () => import('@/components/signin/SigninScreen.vue')
// const SignupScreen = () => import('@/components/signup/signUpScreen.vue')
const ResetPassword = () => import('@/components/ResetPassword/ResetPassword.vue')
const HomeScreen = () => import(/* webpackChunkName: "superAdminDashboard" */ '@/components/dashboard/ui/HomeScreen.vue')
const ClientsHomeScreen = () => import(/* webpackChunkName: "adminDashboard" */ '@/components/dashboard/clients/ClientsHomeScreen.vue')
const ClientScreen = () => import('@/components/dashboard/ui/ClientScreen.vue')
const ConversationsScreen = () => import(/* webpackChunkName: "conversations" */ '@/components/conversations/components/ConversationsScreen.vue')
const CampaignsScreen = () => import('@/components/campaigns/CampaignsScreen.vue')
const NewCampaignScreen = () => import('@/components/campaigns/NewCampaignScreen.vue')
const AutomateScreen = () => import('@/components/automate/components/AutomateScreen.vue')
const TriggersScreen = () => import('@/components/automate/components/Triggers/TriggersScreen.vue')
const TriggersTaskAction = () => import('@/components/automate/components/Triggers/TriggersTaskAction.vue')
const TriggersListAction = () => import('@/components/automate/components/Triggers/TriggersListAction.vue')
const TriggerDetail = () => import('@/components/automate/components/Triggers/TriggerDetail.vue')
const SegmentDetails = () => import('@/components/empathize/people/segments/SegmentDetails.vue')
const PeopleScreen = () => import('@/components/empathize/people/ui/PeopleScreen.vue')
const PersonDetails = () => import('@/components/empathize/people/persons/PersonDetails.vue')
const FormsScreen = () => import('@/components/empathize/forms/ui/FormsScreen.vue')
const CreateEditForm = () => import('@/components/empathize/forms/CreateEditForm.vue')
const FormDetails = () => import('@/components/empathize/forms/FormDetails.vue')
const BusinessesScreen = () => import('@/components/empathize/people/businesses/ui/BusinessesScreen.vue')
const BusinessDetails = () => import('@/components/empathize/people/businesses/BusinessDetails.vue')
const EditBusiness = () => import('@/components/empathize/people/businesses/EditBusiness.vue')
const AutoassignConfig = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/conversations/Autoassign/AutoassignConfig.vue')
const ChatConfigContainer = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/conversations/Chats/ChatConfigContainer.vue')
const ConfigurationScreen = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/general/ConfigurationScreen.vue')
const ChannelsScreen = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/conversations/channels/ChannelsScreen.vue')
const v4Channels = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/conversations/channels/v4Channels.vue')
const Administrators = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/administrators/Administrators.vue')
const Roles = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/roles/Roles.vue')
const JourneyScreen = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/journeys/ui/JourneyScreen.vue')
const App2 = () => import('@/App2.vue')
const AuthenticatorScreen = () => import('@/components/empathize/authenticator/ui/AuthenticatorScreen.vue')
const ImportsScreen = () => import('@/components/empathize/imports/ui/ImportsScreen.vue')
const PipelinesList = () => import('@/components/empathize/pipelines/PipelinesList.vue')
const Pipeline = () => import('@/components/empathize/pipelines/Pipeline.vue')
// Billing
const BillingScreen = () => import(/* webpackChunkName: "billing" */ '@/components/configuration/Billing/BillingScreen.vue')
const BillingHistory = () => import(/* webpackChunkName: "billing" */ '@/components/configuration/Billing/BillingHistory.vue')
const Brands = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/brands/Brands.vue')
const AppConfig = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/apps/AppConfig.vue')
const DomainsConfig = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/domains/DomainsConfig.vue')
const ConnectionsConfig = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/connections/ConnectionsConfig.vue')
const ActiveConnections = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/connections/ActiveConnections.vue')
const BillingConversationsReport = () => import('@/components/conversations/components/BillingConversationsReport/BillingConversationsReport.vue')

const EmailTemplateCreator = () => import(/** webpackChunkName: "EmailTemplateCreator" */ '@/components/campaigns/templates/EmailTemplateCreator.vue')
const EmailTemplateCreatorHome = () => import(/** webpackChunkName: "EmailTemplateCreatorHome" */ '@/components/campaigns/templates/EmailTemplateCreatorHome.vue')
const CampaignList = () => import(/* webpackChunkName: "CampaignsList" */ '@/components/campaigns/components/stepTemplate/CampaignList.vue')
const TemplateHomeList = () => import(/* webpackChunkName: "TemplateHomeList" */ '@/components/campaigns/components/stepTemplate/TemplateHomeList.vue')
const CampaignDetail = () => import(/* webpackChunkName: "CampaignDetail" */ '@/components/campaigns/components/stepTemplate/CampaignDetail.vue')

const OnboardingScreen = () => import(/* webpackChunkName: "onboarding" */ '@/components/Onboarding/Components/OnboardingScreen.vue')
const RegisterInvitedScreen = () => import(/* webpackChunkName: "onboarding" */ '@/components/signup/RegisterInvited/RegisterInvitedScreen.vue')

const ExtensionsScreen = () => import(/* webpackChunkName: "extensions" */ '@/components/Extensions/Screens/ExtensionsScreen.vue')

const WizardScreen = () => import(/* webpackChunkName: "wizard" */ '@/components/Wizard/ui/Screens/WizardScreen.vue')
const LoginScreen = () => import(/* webpackChunkName: "wizard" */ '@/components/Auth/ui/Screens/LoginScreen.vue')

//  mobile connections
const FacebookMobileConfig = () => import(/* webpackChunkName: "mobile" */ '@/components/configuration/channels/mobile/FacebookMobileConfig.vue')
const InstagramMobileConfig = () => import(/* webpackChunkName: "mobile" */ '@/components/configuration/channels/mobile/InstagramMobileConfig.vue')
const FacebookRefreshConfig = () => import(/* webpackChunkName: "mobile" */ '@/components/configuration/channels/mobile/FacebookRefreshConfig.vue')
const InstagramRefreshConfig = () => import(/* webpackChunkName: "mobile" */ '@/components/configuration/channels/mobile/InstagramRefreshConfig.vue')

// Microsites
const MicrositesWelcomeScreen = () => import(/* webpackChunkName: "microsites" */ '@/components/microsites/MicrositesWelcomeScreen.vue')
const MicrositeHomeScreen = () => import(/* webpackChunkName: "microsites" */ '@/components/microsites/MicrositeHomeScreen.vue')
const MicrositeCreateScreen = () => import(/* webpackChunkName: "microsites" */ '@/components/microsites/ui/MicrositeCreateScreen.vue')
const MicrositeEditScreen = () => import(/* webpackChunkName: "microsites" */ '@/components/microsites/ui/MicrositeEditScreen.vue')

// Events
const NewEventScreen = () => import(/* webpackChunkName: "NewEventScreen" */ '@/components/events/ui/NewEventScreen.vue')
const CreateEventScreen = () => import(/* webpackChunkName: "CreateEventScreen" */ '@/components/events/ui/CreateEventScreen.vue')
const EventList = () => import(/* webpackChunkName: "EventList" */ '@/components/events/EventList.vue')
const EventsResume = () => import(/* webpackChunkName: "EventsResume" */ '@/components/events/EventsResume.vue')
const EditEventScreen = () => import(/* webpackChunkName: "EditEventScreen" */ '@/components/events/ui/EditEventScreen.vue')

const CustomizeAdminPermissions = () => import(/* webpackChunkName: "permissions" */ '@/components/configuration/administrators/CustomizePermissions.vue')

// Configure catalog
const CatalogConfigureScreen = () => import(/* webpackChunkName: "configuration" */ '@/components/configuration/catalog/CatalogConfigureScreen.vue')

const conversationRollbar = RollbarFactory.getInstance('conversations')

const authGuard = async (to, from, next) => {
  const unProtectedRoutes = [link.signin, link.signup, link.gql, link.invitedWizard]
  const token = to.query.token
  const app = to.query.app
  const phone: string = to.query.phone
  const country: string = to.query.country
  const state = JSON.parse(localStorage.getItem('keybeStore')) || {}
  const isLoggedIn = state.UserStore?.isLoggedIn || false

  updateBillingInfo()

  const tokenV6 = to.query?.tokenV6
  if (tokenV6) {
    router.app.$store.commit('UserStore/SET_LOADING', true, { root: true })
    const redirect = await V6RedirectionController.handleRedirection(tokenV6)
    if (!redirect) {
      router.app.$store.commit('UserStore/SET_LOADING', false, { root: true })
      router.app.$store.commit('UserStore/CHANGE_KEYBE_AUTH', false, { root: true })
      return next({ path: link.signin })
    } else {
      await updateBillingInfo()
      router.app.$store.commit('UserStore/SET_LOADING', false, { root: true })
      return next({ path: link.conversations })
    }
  }

  if (token && app) {
    try {
      router.app.$store.commit('UserStore/SET_LOADING', true, { root: true })
      const response = await LoginService.validateV4Token(token)
      if (!response.status) {
        router.app.$store.commit('UserStore/SET_LOADING', false, { root: true })
        router.app.$store.commit('UserStore/CHANGE_KEYBE_AUTH', false, { root: true })
        return next({ path: link.signin })
      }
      router.app.$store.commit('UserStore/SET_KEYBE_USER', response.data.user, { root: true })
      router.app.$store.commit('UserStore/SET_TOKEN', token, { root: true })
      router.app.$store.commit('UserStore/SET_PHONE', phone, { root: true })
      router.app.$store.commit('UserStore/SET_COUNTRY', country, { root: true })
      router.app.$store.commit('UserStore/SET_V4TOKEN', token, { root: true })
      await AppService.getApps('')
      await AppService.selectByApp(router.app.$store.state.AppStore.apps.find(a => a.uuid === app))

      router.app.$store.commit('UserStore/CHANGE_KEYBE_AUTH', true, { root: true })
      router.app.$store.commit('UserStore/SET_FIRST_RENDER', false, { root: true })
      router.app.$store.commit('UserStore/SET_LOADING', false, { root: true })
      UserController.setCountry(country)
      UserController.setPhone(phone)
      const filter = to.query.filter
      return next({ path: to.path, query: { filter } })
    } catch (e) {
      router.app.$store.commit('UserStore/SET_LOADING', false, { root: true })
      router.app.$store.commit('UserStore/CHANGE_KEYBE_AUTH', false, { root: true })
      return next({ path: link.signin })
    }
  } else {
    // Send users to conversations instead of super admin
    if (to.path === link.home) {
      return next({ path: link.conversations })
    }
    // Validate if is Rigo to see Rigo Dashboard
    if (to.path === link.panel && state.AppStore?.selectedApp.uuid !== '171xeml22l0h7ork9') {
      return next({ path: link.conversations })
    }
    if (!unProtectedRoutes.includes(to.path) && !isLoggedIn) {
      console.log('not logged in', isLoggedIn)
      if (localStorage.getItem('keybeStore')) {
        return next({ path: link.signin })
      } else {
        console.log('no store')
        return next({ path: link.signup })
      }
    } else if (unProtectedRoutes.includes(to.path) && isLoggedIn) {
      return next(link.home)
    }

    return next()
  }
}

const updateBillingInfo = async () => {
  const appConfig = await BillingService.getAppConfig()
  if (!appConfig) return
  router.app.$store.commit('BillingStore/SET_APP_CONFIG', appConfig, { root: true })
}

const routes = [
  {
    path: link.home,
    component: HomeScreen,
    beforeEnter: authGuard
  },
  {
    path: link.clientDashboard,
    component: ClientsHomeScreen,
    beforeEnter: authGuard
  },
  {
    path: link.panel,
    component: ClientScreen,
    beforeEnter: authGuard
  },
  {
    path: link.signin,
    component: LoginScreen,
    beforeEnter: authGuard
  },
  {
    path: link.resetPassword,
    component: ResetPassword
  },
  { path: link.gql, component: App2 },
  {
    path: link.conversations,
    component: ConversationsScreen,
    beforeEnter: authGuard
  },
  {
    path: link.campaigns,
    component: CampaignsScreen,
    name: 'CampaignsScreen',
    beforeEnter: authGuard
  },
  {
    path: link.newCampaign,
    name: 'createCampaign',
    props: true,
    component: NewCampaignScreen,
    beforeEnter: authGuard
  },
  {
    path: link.automate,
    component: AutomateScreen,
    beforeEnter: authGuard
  },
  {
    path: link.triggers,
    component: TriggersScreen,
    beforeEnter: authGuard
  },
  {
    path: link.editTrigger,
    name: 'EditTrigger',
    component: TriggersTaskAction,
    beforeEnter: authGuard
  },
  {
    path: link.trigger,
    name: 'Trigger',
    component: TriggerDetail,
    beforeEnter: authGuard
  },
  {
    name: 'CreateTrigger',
    path: link.actions,
    component: TriggersTaskAction,
    beforeEnter: authGuard
  },
  {
    path: link.actionsList,
    component: TriggersListAction,
    beforeEnter: authGuard
  },
  {
    path: link.signup,
    component: WizardScreen,
    beforeEnter: authGuard
  },
  {
    path: link.people,
    component: PeopleScreen,
    beforeEnter: authGuard
  },
  {
    path: link.segments,
    component: PeopleScreen,
    beforeEnter: authGuard
  },
  {
    path: link.person,
    name: 'Person',
    component: PersonDetails,
    beforeEnter: authGuard
  },
  {
    path: link.segment,
    name: 'Segment',
    component: SegmentDetails,
    beforeEnter: authGuard
  },
  {
    path: link.forms,
    component: FormsScreen,
    beforeEnter: authGuard
  },
  {
    path: link.form,
    name: 'Form',
    component: FormDetails,
    beforeEnter: authGuard
  },
  {
    path: link.formCreate,
    component: CreateEditForm,
    name: 'CreateForm',
    beforeEnter: authGuard
  },
  {
    path: link.editForm,
    name: 'EditForm',
    component: CreateEditForm,
    beforeEnter: authGuard
  },
  {
    path: link.businesses,
    component: PeopleScreen,
    beforeEnter: authGuard
  },
  {
    path: link.business,
    name: 'Business',
    component: BusinessDetails,
    beforeEnter: authGuard
  },
  {
    path: link.editBusiness,
    name: 'BusinessEdit',
    component: EditBusiness,
    beforeEnter: authGuard
  },
  {
    path: link.editBusiness,
    component: BusinessesScreen,
    beforeEnter: authGuard
  },
  { path: link.auth, component: AuthenticatorScreen },
  { path: link.imports, component: ImportsScreen },
  { path: link.pipelines, component: PipelinesList },
  { path: link.pipeline, name: 'Pipeline', component: Pipeline },
  {
    path: link.autoassignConfig,
    component: AutoassignConfig,
    beforeEnter: authGuard
  },
  {
    path: link.chatConfig,
    component: ChatConfigContainer,
    beforeEnter: authGuard
  },
  {
    path: link.channelConfig,
    component: v4Channels,
    beforeEnter: authGuard
  },
  {
    path: link.config,
    component: ConfigurationScreen,
    beforeEnter: authGuard
  },
  { path: link.adminConfig, component: Administrators, beforeEnter: authGuard },
  { path: link.journeyConfig, component: JourneyScreen, beforeEnter: authGuard },
  { path: link.rolesConfig, component: Roles, beforeEnter: authGuard },
  { path: link.brandsConfig, component: Brands, beforeEnter: authGuard },
  { path: link.appConfig, component: AppConfig, beforeEnter: authGuard },
  { path: link.billingConfigs, component: BillingScreen, beforeEnter: authGuard },
  { path: link.billingHistory, component: BillingHistory, beforeEnter: authGuard },
  { path: link.domainsConfig, component: DomainsConfig, beforeEnter: authGuard },
  { path: link.connectionsConfig, component: ConnectionsConfig, beforeEnter: authGuard },
  { path: link.activeDevices, component: ActiveConnections, beforeEnter: authGuard },
  {
    path: link.templateCreator,
    component: EmailTemplateCreator,
    beforeEnter: authGuard
  },
  {
    path: link.emailCreator,
    component: EmailTemplateCreatorHome,
    beforeEnter: authGuard
  },
  {
    path: link.campaignsList,
    component: CampaignList,
    name: 'CampaignList',
    beforeEnter: authGuard
  },
  {
    path: link.templates,
    component: TemplateHomeList,
    beforeEnter: authGuard
  },
  { path: link.domainsConfig, component: DomainsConfig, beforeEnter: authGuard },
  {
    path: link.campaignDetail,
    component: CampaignDetail,
    name: 'CampaignDetail',
    beforeEnter: authGuard
  },
  { path: link.newChannelsConfig, component: ChannelsScreen, beforeEnter: authGuard },
  { path: link.conversationBillingReport, component: BillingConversationsReport, beforeEnter: authGuard },
  { path: link.onboarding, component: OnboardingScreen, beforeEnter: authGuard },
  { path: link.inviteTeam, component: InviteYourTeamScreen, beforeEnter: authGuard },
  { path: link.invitedWizard, component: RegisterInvitedScreen, beforeEnter: authGuard },
  { path: link.extensions, component: ExtensionsScreen, beforeEnter: authGuard },
  { path: `${link.instagramMobileConfig}${link.extensionPath}`, component: InstagramMobileConfig },
  { path: `${link.facebookMobileConfig}${link.extensionPath}`, component: FacebookMobileConfig },
  {
    path: `${link.instagramMobileConfig}${link.extensionPath}${link.extensionInstagram}`,
    component: InstagramRefreshConfig
  },
  { path: `${link.facebookMobileConfig}${link.extensionPath}${link.extensionPage}`, component: FacebookRefreshConfig },
  { path: link.customizeAdminPermissions, component: CustomizeAdminPermissions, beforeEnter: authGuard },
  {
    path: link.micrositeHome,
    component: MicrositeHomeScreen,
    beforeEnter: authGuard
  },
  {
    path: link.micrositeWelcome,
    component: MicrositesWelcomeScreen,
    beforeEnter: authGuard
  },
  {
    path: link.micrositeNew,
    component: MicrositeCreateScreen,
    beforeEnter: authGuard
  },
  {
    path: link.micrositeEdit,
    component: MicrositeEditScreen,
    beforeEnter: authGuard
  },
  {
    path: link.catalogConfig,
    component: CatalogConfigureScreen,
    beforeEnter: authGuard
  },
  {
    path: link.events,
    component: EventList,
    beforeEnter: authGuard
  },
  {
    path: link.newEvent,
    component: NewEventScreen,
    beforeEnter: authGuard
  },
  {
    path: link.createEvent,
    component: CreateEventScreen,
    beforeEnter: authGuard
  },
  {
    path: link.resumeEvent,
    name: 'ResumeEvent',
    component: EventsResume,
    beforeEnter: authGuard
  },
  {
    path: link.editEvent,
    name: 'EditEvent',
    component: EditEventScreen,
    beforeEnter: authGuard
  }
]

const router = new VueRouter({
  base: process.env.VUE_APP_BASE_ROUTE,
  mode: 'history',
  routes
})

router.onError(error => {
  const chunkStringValidation = error.message.toLowerCase().includes('loading chunk')
  const failedStringValidation = error.message.toLowerCase().includes('failed')
  if (chunkStringValidation && failedStringValidation) {
    conversationRollbar.reportError('Chunk Error', error)
    window.location.reload()
  }
})

export default router
