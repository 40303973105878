import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import Colors from '@/utils/Colors'
const tiktokIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/microsites/tiktok.svg')
const moneySignIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/microsites/money-sign.svg')
const telegramIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/microsites/telegram.svg')
const galleryIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/conversations/chat/autocomplete/gallery.svg')
const headphonesIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/conversations/chat/autocomplete/headphones.svg')
const pdfIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/conversations/chat/autocomplete/pdf.svg')
const shoppingIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/conversations/chat/autocomplete/shopping.svg')
const textIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/conversations/chat/autocomplete/text.svg')
const videoIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/conversations/chat/autocomplete/video.svg')
const pipelinesStandardIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/pipelines/pipelines-standard.svg')
const pipelinesTicketIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/pipelines/pipelines-ticket.svg')
const pipelinesFlareIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/pipelines/pipelines-flare.svg')
const discordIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/microsites/discord.svg')
const twitchIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/microsites/twitch.svg')
const transferRoomIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/conversations/chat/actions/transfer-room.svg')
const closeRoomIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/conversations/chat/actions/close-room.svg')
const salesIcon = () => import(/* webpackChunkName: "shared" */'@/assets/icons/conversations/chat/actions/sales.svg')

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      tiktok: {
        // @ts-ignore
        component: tiktokIcon,
        props: {
          name: 'tiktok'
        }
      },
      money: {
        // @ts-ignore
        component: moneySignIcon,
        props: {
          name: 'money'
        }
      },
      telegram: {
        // @ts-ignore
        component: telegramIcon,
        props: {
          name: 'telegram'
        }
      },
      gallery: {
        // @ts-ignore
        component: galleryIcon,
        props: {
          name: 'gallery'
        }
      },
      headphones: {
        // @ts-ignore
        component: headphonesIcon,
        props: {
          name: 'headphones'
        }
      },
      pdf: {
        // @ts-ignore
        component: pdfIcon,
        props: {
          name: 'pdf'
        }
      },
      shopping: {
        // @ts-ignore
        component: shoppingIcon,
        props: {
          name: 'shopping'
        }
      },
      text: {
        // @ts-ignore
        component: textIcon,
        props: {
          name: 'text'
        }
      },
      video: {
        // @ts-ignore
        component: videoIcon,
        props: {
          name: 'video'
        }
      },
      pipelinesStandard: {
        // @ts-ignore
        component: pipelinesStandardIcon,
        props: {
          name: 'pipelinesStandard'
        }
      },
      pipelinesTicket: {
        // @ts-ignore
        component: pipelinesTicketIcon,
        props: {
          name: 'pipelinesTicket'
        }
      },
      pipelinesFlare: {
        // @ts-ignore
        component: pipelinesFlareIcon,
        props: {
          name: 'pipelinesFlare'
        }
      },
      discord: {
        // @ts-ignore
        component: discordIcon,
        props: {
          name: 'discord'
        }
      },
      twitch: {
        // @ts-ignore
        component: twitchIcon,
        props: {
          name: 'twitch'
        }
      },
      transferRoom: {
        // @ts-ignore
        component: transferRoomIcon,
        props: {
          name: 'transferRoom'
        }
      },
      closeRoom: {
        // @ts-ignore
        component: closeRoomIcon,
        props: {
          name: 'closeRoom'
        }
      },
      sales: {
        // @ts-ignore
        component: salesIcon,
        props: {
          name: 'sales'
        }
      }
    }
  },
  theme: {
    themes: {
      light: {
        primary: Colors.verdeBird,
        secondary: Colors.dark2,
        accent: Colors.blue4,
        error: Colors.automate2,
        dark4: Colors.dark4,
        details: Colors.white,
        component: Colors.light1,
        background: Colors.light2,
        light3: Colors.light3,
        base: Colors.dark3,
        highlight: Colors.white,
        black: Colors.black,
        text: Colors.dark1,
        light: Colors.light3,
        offWhite: Colors.offWhite,
        light4: Colors.light4
      },
      dark: {
        primary: Colors.verdeBird,
        secondary: Colors.light3,
        accent: Colors.blue4,
        error: Colors.automate2,
        dark4: Colors.darkThemeDark4,
        details: Colors.darkThemeDark4,
        light3: Colors.darkThemeDark4,
        component: Colors.darkThemeLight1,
        background: Colors.darkThemeDark4,
        base: Colors.darkThemeDark3,
        highlight: Colors.darkThemeWhite,
        black: Colors.white,
        text: Colors.white,
        light: Colors.darkThemeLight3,
        offWhite: Colors.darkThemeOffWhite,
        light4: Colors.darkThemeLight4
      }
    }
  }
})
