import store from '@/store/index'

export default class UserController {
  static validatePhoneAndCountry (): boolean {
    const phone = store.getters['UserStore/getPhone']
    const country = store.getters['UserStore/getCountry']
    return phone && country
  }

  static setPhone (value: string): void {
    store.commit('UserStore/SET_PHONE', value)
  }

  static setCountry (value: string): void {
    store.commit('UserStore/SET_COUNTRY', value)
  }

  static getPhone (): string {
    return store.getters['UserStore/getPhone']
  }

  static getCountry (): string {
    return store.getters['UserStore/getCountry']
  }
}
