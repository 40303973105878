<template>
  <div class="mr-2">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          rounded
          :color="balanceAlert"
          elevation="0"
          class="ma-2"
          small
          @click="loadAddBalance"
          dark>
            <div class="font-weight-bold">{{ `USD ${formatCurrency(balanceComputed, 'USD') || 0}` }}</div>
            <v-icon class="ml-2" v-show="creditComputed" dark left>mdi-cash</v-icon>
        </v-btn>
      </template>
      <span>{{$t('balanceTooltip')}}</span>
    </v-tooltip>
    <AddCreditCardDialog
      v-model="creditCardDialog"
      :upgrade="shouldUpgrade"
      @input="creditCardDialog=$event"
      @finishUpgrade="onFinishUpgrade"
      @success="openSuccessDialog"
      @error="openErrorDialog"/>
    <SuccessDialog v-model="successDialog" @input="successDialog=$event"/>
    <ErrorDialog v-model="errorDialog" @input="errorDialog=$event"/>
    <FinishUpgradeDialog v-model="finishUpgradeDialog" @input="finishUpgradeDialog=$event"/>
    <!--Show dialog when balance is 0-->
    <v-dialog v-model="dialog" persistent max-width="800px">
      <div class="modal-container">
        <img loading="lazy" src="https://storage.googleapis.com/keybe/files/bluebird/billing/credit-card.svg"
             alt="credit card" class="mb-8">
        <strong>¡Llegaste al límite de tu crédito!</strong>
        <span class="text-center">Estas en el Plan Essential recarga o pasate al Plan Pro para seguir obteniendo los beneficios de keybe.</span>
        <div class="flex justify-space-around px-16 w-full mt-8">
          <v-btn color="#40a9f2" elevation="0" dark rounded large @click="dialog = false; creditCardDialog=true">
            Quiero recargar
          </v-btn>
          <v-btn color="#40a9f2" elevation="0" dark rounded large @click="upgrade">
            Quiero ser Pro
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <add-balance-dialog v-model="balanceDialog" @input="dialog = $event" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BillingService from '@/components/configuration/Services/BillingService'
import BillingValidations from '@/components/configuration/Billing/BillingValidations'
import { EventBus } from '@/eventBus'

const AddCreditCardDialog = () => import(/* webpackChunkName: "toolbar-billing" */ '@/components/Dialogs/CreditCard/AddCreditCardDialog')
const SuccessDialog = () => import(/* webpackChunkName: "toolbar-billing" */ '@/components/Dialogs/CreditCard/SuccessDialog')
const ErrorDialog = () => import(/* webpackChunkName: "toolbar-billing" */ '@/components/Dialogs/CreditCard/ErrorDialog')
const FinishUpgradeDialog = () => import(/* webpackChunkName: "toolbar-billing" */ '@/components/Dialogs/CreditCard/FinishUpgradeDialog')
const AddBalanceDialog = () => import(/* webpackChunkName: "toolbar-billing" */ '@/components/Dialogs/AddBalance/AddBalance.vue')

export default {
  name: 'AccountStatus',
  components: {
    SuccessDialog,
    AddCreditCardDialog,
    ErrorDialog,
    FinishUpgradeDialog,
    AddBalanceDialog
  },
  data () {
    return {
      balance: null,
      dialog: false,
      balanceDialog: false,
      creditCardDialog: false,
      successDialog: false,
      errorDialog: false,
      shouldUpgrade: false,
      finishUpgradeDialog: false
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapState('UserStore', ['v4Token']),
    ...mapState('BillingStore', ['appConfig']),
    hasBalance () {
      return BillingValidations.isEssential() || BillingValidations.hasBalanceProfessional()
    },
    balanceAlert () {
      return this.appConfig.balanceUSD >= 30 ? '#7DDA09' : this.appConfig?.balanceUSD > 19 ? '#F9B240' : '#FF715B'
    },
    balanceComputed () {
      let value = this.appConfig.balanceUSD
      // console.log('INFO: ', typeof this.appConfig.balanceUSD, this.appConfig?.balanceUSD)
      if (value) value = this.appConfig.balanceUSD
      return value
    },
    creditComputed () {
      return !!this.appConfig?.hasCredit
    }
  },
  methods: {
    ...mapActions('BillingStore', ['setAppConfig']),
    onFinishUpgrade () {
      this.finishUpgradeDialog = true
      this.balanceDialog = false
    },
    loadAddBalance () {
      this.balanceDialog = true
    },
    openSuccessDialog () {
      this.successDialog = true
      EventBus.$emit('billing:update-credit-card', true)
    },
    openErrorDialog () {
      this.errorDialog = true
      EventBus.$emit('billing:update-credit-card', true)
    },
    openCreditCardDialog () {
      this.dialog = false
      this.creditCardDialog = true
    },
    upgrade () {
      this.shouldUpgrade = true
      this.openCreditCardDialog()
    },
    addCreditCard () {
      this.shouldUpgrade = false
      this.openCreditCardDialog()
    },
    async updateBillingInfo () {
      const appConfig = await BillingService.getAppConfig()
      this.setAppConfig(appConfig)
    },
    formatCurrency (value, currency) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
      })
      return formatter.format(value)
    }
  },
  async mounted () {
    this.updateBillingInfo()
    // FIX: estandarizar con el area de uso billing:update-balance
    EventBus.$on('upgrade-to-pro', (mode) => {
      if (!mode) return this.upgrade()
      this.addCreditCard()
    })
    EventBus.$on('billing:update-balance', () => {
      this.updateBillingInfo()
    })
    EventBus.$on('billing:add-balance-dialog', (mode) => {
      this.balanceDialog = mode
    })
  },
  watch: {
    balance (val) {
      this.dialog = val === 0
    },
    selectedCompany () {
      if (this.selectedCompany.uuid) {
        this.updateBillingInfo()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  padding: 4rem
}
</style>
