import axios from 'axios'
import store from '@/store/index'
import { User } from 'src/components/models/User'
import { UserPermissions } from '@/Enums/UserPermissions'

export const UserStore = {
  namespaced: true,
  state: {
    analyticsTimeRange: 30,
    firstRender: true,
    isLoading: false,
    isLoggedIn: false,
    token: '',
    phone: '',
    country: '',
    v4Token: '',
    user: {},
    companies: [],
    search: '',
    selectedApp: {},
    status: false,
    selectedCompany: null,
    administratorRoles: [],
    hideInstagramDialog: false,
    selectedPeopleHeaders: [],
    allPeopleHeaders: [],
    personalizedTags: [],
    personalizedExtradata: [],
    orderedExtradata: [],
    orderedTags: [],
    changePassword: false,
    showPopUpVideo: true,
    isOwner: false,
    userExtradata: [],
    deviceToken: '',
    showFormPop: true,
    darkMode: ''
  },
  mutations: {
    SET_KEYBE_USER (state, user: User) {
      state.user = user
    },
    SET_TOKEN (state, token: string) {
      state.token = token
    },
    SET_V4TOKEN (state, token: string) {
      state.v4Token = token
    },
    SET_PHONE (state, phone: string) {
      state.phone = phone
    },
    SET_COUNTRY (state, country: string) {
      state.country = country
    },
    CHANGE_KEYBE_AUTH (state, isLoggedIn: boolean) {
      state.isLoggedIn = isLoggedIn
    },
    SET_ROLES (state, roles) {
      state.administratorRoles = roles
    },
    CLEAR_USER_STATE (state) {
      state.isLoggedIn = false
      state.token = ''
      state.v4Token = ''
      state.user = {}
      state.companies = []
      state.search = ''
      state.selectedApp = {}
      state.status = false
      state.selectedCompany = null
      state.administratorRoles = []
      state.userExtradata = []
      state.deviceToken = ''
    },
    SET_LOADING (state, status: boolean) {
      state.isLoading = status
    },
    SET_FIRST_RENDER (state, status: boolean) {
      state.firstRender = status
    },
    HIDE_INSTAGRAM_NOTIFICATION (state, status) {
      state.hideInstagramDialog = status
    },
    SET_METRICS_DAYS_PERIOD (state, days: number) {
      state.analyticsTimeRange = days
    },
    SET_SELECTED_HEADERS (state, headers) {
      state.selectedPeopleHeaders = headers
    },
    SET_HEADERS (state, headers) {
      state.allPeopleHeaders = headers
    },
    SET_PERSONALIZED_EXTRADATA (state, personalizedExtradata) {
      state.personalizedExtradata = personalizedExtradata
    },
    SET_PERSONALIZED_TAGS (state, personalizedTags) {
      state.personalizedTags = personalizedTags
    },
    SET_ORDERED_EXTRADATA (state, ordered) {
      state.orderedExtradata = ordered
    },
    SET_ORDERED_TAGS (state, ordered) {
      state.orderedTags = ordered
    },
    SET_CHANGE_PASSWORD (state, status) {
      state.changePassword = status
    },
    SET_SHOW_POP_UP_VIDEO (state, show) {
      state.showPopUpVideo = show
    },
    SET_IS_OWNER (state, status) {
      state.isOwner = status
    },
    SET_USER_EXTRADATA (state, extradata) {
      state.userExtradata = extradata
    },
    SET_DEVICE_TOKEN (state, token) {
      state.deviceToken = token
    },
    SET_SHOW_FORM (state, value) {
      state.showFormPop = value
    },
    SET_DARK_MODE (state, mode) {
      state.darkMode = mode
    }
  },
  actions: {
    setIsLoading ({ commit }, status: boolean) {
      commit('SET_LOADING', status)
    },
    async selectedAppUsers (context, company) {
      const companyId = company.uuid
      const AppId = context.state.selectedApp.uuid
      try {
        const response = await axios.get(
                    `${process.env.VUE_APP_ANALYTICS_URL}/analytics/company/${companyId}/app/${AppId}/crm/totalusers/`,
                    {
                      headers: {
                        Authorization: process.env.VUE_APP_AUTH_PUBLIC_TOKEN
                      }
                    }
        )
        if (response.data.apps.rows.length > 0) {
          return response.data.apps.rows[0].total
        }
      } catch (e) {
        console.error(e)
      }
    },
    async totalUsers (context) {
      try {
        const response = await axios.get(
          'https://analytics.keybe.bio/analytics/crm/totalusers',
          {
            headers: {
              Authorization: 'Bearer ' + context.rootState.UserStore.v4Token
            }
          }
        )
        return response.data.apps.rows[0].total
      } catch (e) {
        console.error(e)
      }
    },
    logout (context) {
      context.commit('SET_KEYBE_USER', {})
      context.commit('SET_TOKEN', '')
      context.commit('CHANGE_KEYBE_AUTH', false)
      context.commit('CLEAR_USER_STATE')
      context.dispatch('ConversationStore/clearConversationState', true, { root: true })
      context.dispatch('CompanyStore/clearCompanyState', true, { root: true })
      context.dispatch('AppStore/clearAppState', true, { root: true })
    },
    async getAppList (context) {
      try {
        const response = await axios.get(
          'https://analytics.keybe.bio/analytics/crm/app/totalusers',
          {
            headers: {
              Authorization: 'Bearer ' + context.rootState.UserStore.v4Token
            }
          }
        )
        return response.data.apps.rows
      } catch (e) {
        console.error(e)
      }
    },
    async totalCampaigns (context) {
      try {
        const response = await axios.get(
          'https://analytics.keybe.bio/analytics/crm/total/campaign/users/type',
          {
            headers: {
              Authorization: 'Bearer ' + context.rootState.UserStore.v4Token
            }
          }
        )
        return response.data.users.rows
      } catch (e) {
        console.error(e)
      }
    },
    async totalChannels (context) {
      try {
        const response = await axios.get(
          'https://analytics.keybe.bio/analytics/crm/total/conversations/channel?periodDays=5000',
          {
            headers: {
              Authorization: 'Bearer ' + context.rootState.UserStore.v4Token
            }
          }
        )
        return response.data.conversations.rows
      } catch (e) {
        console.error(e)
      }
    },
    setUser (context, user: User) {
      context.commit('SET_KEYBE_USER', user)
    },
    setUserExtradata (context, extradata) {
      const webinarDialogExists = extradata.filter(extra => extra.property === 'Registered webinar may 10')
      if (webinarDialogExists.length > 0) {
        store.dispatch('ConversationStore/setWebinarPopup', false)
        store.dispatch('ConversationStore/setShowTemporalWebinarPopUp', false)
      } else {
        store.dispatch('ConversationStore/setWebinarPopup', true)
        store.dispatch('ConversationStore/setShowTemporalWebinarPopUp', true)
      }
      context.commit('SET_USER_EXTRADATA', extradata)
    },
    setDeviceToken (context, token) {
      context.commit('SET_DEVICE_TOKEN', token)
    }
  },
  getters: {
    isAdmin (state) {
      return state.administratorRoles.includes('ROLE_ADMIN')
    },
    isSuperAdmin (state) {
      return state.user.type === 'superadmin'
    },
    getUser (state) {
      return state.user
    },
    getToken (state) {
      return state.token
    },
    getV4Token (state) {
      return state.v4Token
    },
    getPhone (state: any): string {
      return state.phone ?? ''
    },
    getCountry (state: any): string {
      return state.country ?? ''
    },
    getMetricsToken (state) {
      return state.v4Token
    },
    getSelectedHeaders (state) {
      return state.selectedPeopleHeaders
    },
    getAllHeaders (state) {
      return state.allHeaders
    },
    // has end conversation role
    hasEndConversationRole (state) {
      return state.administratorRoles.includes(UserPermissions.END_CHAT)
    },
    hasTransferConversationRole (state) {
      return state.administratorRoles.includes(UserPermissions.TRANSFER_TO_OTHER_CHATS)
    },
    hasTakeConversationFromAgentRole (state) {
      return state.administratorRoles.includes(UserPermissions.TAKE_FROM_AGENT_CHAT)
    },
    hasTakeConversationFromBot (state) {
      return state.administratorRoles.includes(UserPermissions.TAKE_FROM_BOT_CHAT)
    },
    getRoles (state) {
      return state.administratorRoles
    },
    hasAdminListRole (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_USER)
    },
    hasRecordViewAllPermission (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_RECORDS_VIEW_ALL)
    },
    hasRecordViewOwnPermission (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_RECORDS_VIEW_MY)
    },
    hasJourneysRole (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_JOURNEYS)
    },
    hasRoleList (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_LIST)
    },
    hasFormsRole (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_FORMS)
    },
    hasRoleRecordImport (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_RECORD_IMPORT)
    },
    hasRoleConversations (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_CONVERSATIONS)
    },
    hasCampaignsRole (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_CAMPAIGNS)
    },
    hasRoleTriggers (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_TRIGGERS)
    },
    hasRoleBusiness (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_BUSINESS)
    },
    hasRoleAuth (state) {
      return state.administratorRoles.includes(UserPermissions.ROLE_AUTHENTICATOR_MODIFICATION)
    },
    hasAllConversationsRole (state) {
      return ['VIEW_ALL_CHATS', 'FILTER_ALL_CHATS']
        .every(role => state.administratorRoles.includes(role))
    },
    hasSendAudioRole (state) {
      return state.administratorRoles.includes(UserPermissions.SEND_AUDIO_CHAT)
    },
    hasSendImageRole (state) {
      return state.administratorRoles.includes(UserPermissions.SEND_IMAGE_CHAT)
    },
    hasSendPdfRole (state) {
      return state.administratorRoles.includes(UserPermissions.SEND_PDF_CHAT)
    },
    getDeviceToken (state) {
      return state.deviceToken
    },
    getIsLoggedIn (state) {
      return state.isLoggedIn
    },
    getDarkModeActive (state) {
      return state.darkMode
    },
    hasWaitingChatsRole (state) {
      return state.administratorRoles.includes(UserPermissions.FILTER_WAITING_CHATS)
    },
    getUserExtradata (state) {
      return state.userExtradata
    }
  }
}
