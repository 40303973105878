<template>
  <v-menu min-width="300px">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="HelpMenu-button" :class="glowAnimation? 'glowAnimation':''">
        {{ $t('helpButton') }}
      </div>
    </template>
    <v-list>
      <v-list-item v-if="isKeybeApp" class="cursor-pointer" @click="goToV6">
        <img :src="flashIcon" loading="lazy" alt="" class="mr-4">
        <strong>{{$t('goToV6')}}</strong>
      </v-list-item>
      <v-list-item class="cursor-pointer" @click="$router.push(link.onboarding)">
        <img :src="flashIcon" loading="lazy" alt="" class="mr-4">
        <strong>{{$t('howStart')}}</strong>
      </v-list-item>
      <v-list-item class="cursor-pointer" @click="$router.push(link.newChannelsConfig)">
        <img :src="rocketIcon" loading="lazy" alt="" class="mr-4">
        <strong>{{$t('connectChannels')}}</strong>
      </v-list-item>
<!--      Quiero hablar con soporte-->
      <v-list-item class="cursor-pointer" href="https://cutt.ly/ZkMJH9u" target="_blank">
        <img :src="supportIcon" loading="lazy" alt="" class="mr-4">
        <strong>{{$t('talkWithSupport')}}</strong>
      </v-list-item>

      <v-list-item class="cursor-pointer" href="https://cutt.ly/1Veax1Q" target="_blank">
        <v-icon :color="Colors.dark2" class="-ml-1 mr-4">mdi-file-document-outline</v-icon>
        <strong>{{$t('howToUseKeybe')}}</strong>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import link from '@/utils/links'
import Colors from '@/utils/Colors'
import V6RedirectionController from '@/controllers/V6RedirectionController'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'HelpMenu',
  data () {
    return {
      link,
      rocketIcon: `${process.env.VUE_APP_ASSETS_URL}/common/rocket.svg`,
      flashIcon: `${process.env.VUE_APP_ASSETS_URL}/common/flash.svg`,
      supportIcon: `${process.env.VUE_APP_ASSETS_URL}/common/support.svg`,
      Colors,
      glowAnimation: false
    }
  },
  mounted () {
    if (this.$route.query.firstLogin) {
      this.glowAnimation = true
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    ...mapGetters('AppStore', ['isKeybeApp'])
  },
  methods: {
    async goToV6 () {
      await V6RedirectionController.setRedirectionExtradata(this.selectedApp)
      window.location.href = `${process.env.VUE_APP_V6_URL}?tokenV6=${this.token}`
    }
  }
}
</script>

<style scoped lang="scss">
.HelpMenu-button {
  color: #fff;
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 0.3rem 1rem;
  font-size: 0.75rem;
  min-width: 100px;
  text-align: center;
  font-weight: 700;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
}

.HelpMenu-button:hover {
  background-color: #fff;
  color: #01CFDD;
}

.glowAnimation {
  animation: glowing 2000ms infinite;
}

@keyframes glowing {
  0% {
    background-color: transparent;
    box-shadow: 0 0 3px #fff;
    color: #fff;
  }
  50% {
    background-color: #fff;
    box-shadow: 0 0 20px #fff;
    color: #01CFDD;
  }
  100% {
    background-color: transparent;
    box-shadow: 0 0 3px #fff;
    color: #fff;
  }
}
</style>
