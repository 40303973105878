import ConversationsConfigService from '@/components/configuration/Services/ConversationsConfigService'
import { AuthFormFields } from '@/components/configuration/conversations/AuthFormFields'
import { AuthMethods } from '@/components/configuration/conversations/AuthMethods'
import { i18n } from '@/plugins/i18n/i18n'
import { EventBus } from '@/eventBus'
import store from '@/store'

export default class ConversationsConfigController {
  static defaultConversationConfig = {
    authMethod: AuthMethods.FORM,
    authOptions: 'none',
    urlBase: '',
    formFields: [AuthFormFields.Name, AuthFormFields.Email, AuthFormFields.Phone],
    linkOfTermsAndConditions: '',
    showTermsAndConditions: false,
    textOfTermsAndConditions: `${i18n.t('termsAndConditions')} {{app}}`,
    authTwoFactor: 'onDemand',
    theme: 'light',
    mainColor: '#000000',
    position: 'right',
    logo: '',
    bubbleMessage: '',
    disableMultimedia: false,
    language: 'es',
    welcomeForm: {
      text: '',
      color: '#000000',
      fontSize: 14,
      textAlign: 'left'
    },
    autoClosingAfterMinutes: 1440,
    customizedQuestions: [],
    permanentMessages: [],
    finishOptions: [],
    quickResponses: [],
    inactivityMessage: '',
    inactivitySeconds: 0,
    hideBubbleMessage: false,
    workingHours: {
      message: '',
      customFormTitle: '',
      enable: false,

      blockMessageComposer: false,
      days: [
        {
          code: 0,
          isClosed: false,
          schedules: []
        },
        {
          code: 1,
          isClosed: false,
          schedules: []
        },
        {
          code: 2,
          isClosed: false,
          schedules: []
        },
        {
          code: 3,
          isClosed: false,
          schedules: []
        },
        {
          code: 4,
          isClosed: false,
          schedules: []
        },
        {
          code: 5,
          isClosed: false,
          schedules: []
        },
        {
          code: 6,
          isClosed: false,
          schedules: []
        }
      ],
      welcomeForm: {
        text: '',
        color: '#000000',
        fontSize: 14,
        textAlign: 'left'
      }

    },
    timeZone: { label: 'America/Bogota', offset: '−05:00' },
    // autoassign default config
    isAutoassignActive: false,
    groups: [],
    tags: [],
    maxActiveConversationsNumberPerAdvisor: 0,
    autoassignationType: 'balanced'
  }

  static async getConfig () {
    const response = await ConversationsConfigService.getConversationsConfigV5()
    if (!response.status) {
      return this.defaultConversationConfig
    }
    const config = response.data.data.config
    if (!config) {
      return this.defaultConversationConfig
    }

    if (!config.finishOptions) config.finishOptions = []
    if (!config.quickResponses) config.quickResponses = []
    if (!config.customizedQuestions) config.customizedQuestions = []
    if (!config.welcomeForm) config.welcomeForm = this.defaultConversationConfig.welcomeForm
    if (!config.mainColor) config.mainColor = this.defaultConversationConfig.mainColor
    if (!config?.workingHours) config.workingHours = this.defaultConversationConfig.workingHours
    if (!config?.timeZone) config.timeZone = this.defaultConversationConfig.timeZone
    if (!config?.hideBubbleMessage) config.hideBubbleMessage = this.defaultConversationConfig.hideBubbleMessage
    if (!config?.authMethod || config?.authMethod === AuthMethods.NONE) config.authMethod = this.defaultConversationConfig.authMethod
    if (!config?.formFields) config.formFields = this.defaultConversationConfig.formFields

    return response.data.data.config
  }

  static async getChannels () {
    const response = await ConversationsConfigService.getAllChannels()
    if (!response.status) {
      EventBus.$emit('toast', 'error', i18n.t('errorGettingChannels'))
    }
    const channels = response?.data?.data
    store.dispatch('ConversationStore/setChannels', channels)
    if (channels?.whatsapp?.hasWhatsappProvider) {
      store.dispatch('ConversationStore/setHasWhatsappProvider', true)
      store.dispatch('ConversationStore/setWpProviders', channels?.whatsapp?.accounts)
    }
  }
}
