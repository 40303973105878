import { GenericResponse } from '@/models/apis/GenericResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'

export class RestResponse extends GenericResponse {
    code: number;

    constructor ({
      status,
      message,
      data,
      code
    }: {
        status: boolean;
        message: string;
        data: any;
        code: number;
    }) {
      super({ status, message, data })
      this.code = code
    }
}

export class RestResponseFactory {
  verify ({ response }: { response: any }): GenericResponse {
    if (response?.data?.status === 'ERROR') {
      return new RestResponse({
        status: false,
        message: response?.data || '',
        code: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR,
        data: response.data
      })
    }
    return new RestResponse({
      status: true,
      message: '',
      code: response.status,
      data: response.data
    })
  }

  process ({ response }: { response: any }): GenericResponse {
    const code: number = response.status
    switch (code) {
      case StatusCodes.HTTP_200_OK:
        return new RestResponse({
          status: true,
          message: '',
          code: code,
          data: response.data
        })
      case StatusCodes.HTTP_201_CREATED:
        return this.verify({ response })
      case StatusCodes.HTTP_400_BAD_REQUEST:
        return new RestResponse({
          status: false,
          message: '',
          code: code,
          data: response.data
        })
      case StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR:
        return new RestResponse({
          status: false,
          message: response.message,
          code: code,
          data: response.data
        })
      case StatusCodes.HTTP_409_CONFLICT:
        return new RestResponse({
          status: false,
          message: response.message,
          code: code,
          data: response.data
        })
      default:
        return new RestResponse({
          status: false,
          message: 'Ha ocurrido un error inesperado',
          code: code,
          data: response.data
        })
    }
  }
}
