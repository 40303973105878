const getUrl = (path) => {
  return `${process.env.VUE_APP_ASSETS_URL}/icons/${path}`
}

/** channels */
const messengerActive = getUrl('conversations/room/channels/messengerActive.svg')
const gmailActive = getUrl('conversations/room/channels/gmailActive.svg')
const whatsappActive = getUrl('conversations/room/channels/whatsappActive.svg')
const smsActive = getUrl('conversations/room/channels/smsActive.svg')
const supportActive = getUrl('conversations/room/channels/supportActive.svg')
const webActive = getUrl('conversations/room/channels/webActive.svg')
const messengerInactive = getUrl('conversations/room/channels/messengerInactive.svg')
const gmailInactive = getUrl('conversations/room/channels/gmailInactive.svg')
const whatsappInactive = getUrl('conversations/room/channels/whatsappInactive.svg')
const smsInactive = getUrl('conversations/room/channels/smsInactive.svg')
const supportInactive = getUrl('conversations/room/channels/supportInactive.svg')
const webInactive = getUrl('conversations/room/channels/webInactive.svg')
const instaActive = getUrl('conversations/room/channels/instagramActive.svg')
const instaInactive = getUrl('conversations/room/channels/instagramInactive.svg')
const instaBlack = getUrl('conversations/room/channels/instagramBlack.svg')
const wizardActive = getUrl('conversations/room/channels/wizardActive.svg')
const wizardInactive = getUrl('conversations/room/channels/wizardInactive.svg')

/** indicators  */
const pinWhite = getUrl('conversations/room/indicators/pinnedWhite.svg')
const pinActive = getUrl('conversations/room/indicators/pinnedActive.svg')
const clapActive = getUrl('conversations/room/indicators/clappedActive.svg')
const transferredActive = getUrl('conversations/room/indicators/transferredActive.svg')
const pinInactive = getUrl('conversations/room/indicators/pinnedInactive.svg')
const clapInactive = getUrl('conversations/room/indicators/clappedInactive.svg')
const transferredInactive = getUrl('conversations/room/indicators/transferredInactive.svg')
const chatBot = getUrl('conversations/room/indicators/chat-bot.svg')
// /** toolbar  */
const kbLogo = getUrl('toolbar/kbLogo.svg')
const microsites = getUrl('toolbar/microsites.svg')
const notifications = getUrl('toolbar/notifications.svg')
const share = getUrl('toolbar/share.svg')
const statistics = getUrl('toolbar/statistics.svg')
const menuHamburger = getUrl('toolbar/menuHamburger.svg')
// /** sidebar  */
const menu = getUrl('sidebar/menu.svg')
const setting = getUrl('sidebar/setting.svg')
const automate = getUrl('sidebar/automate.svg')
const communicate = getUrl('sidebar/communicate.svg')
const connect = getUrl('sidebar/connect.svg')
const converse = getUrl('sidebar/converse.svg')
const dashboard = getUrl('sidebar/dashboard.svg')
//
// /** shared */
const search = getUrl('shared/search.svg')
const filter = getUrl('shared/filter.svg')
const account = getUrl('shared/account.svg')
const accountGrey = getUrl('shared/account-grey.svg')
//
// /** Message Composer */
const attachment = getUrl('conversations/chat/messageComposer/attachment.svg')
const camera = getUrl('conversations/chat/messageComposer/camera.svg')
const history = getUrl('conversations/chat/messageComposer/history.svg')
const mic = getUrl('conversations/chat/messageComposer/mic.svg')
const wink = getUrl('conversations/chat/messageComposer/wink.svg')
const musicNote = getUrl('conversations/chat/messageComposer/music-note.svg')
const pdfAcrobat = getUrl('conversations/chat/messageComposer/pdf-acrobat.svg')
//
// /** Chat Details */
const callActive = getUrl('conversations/details/call-active.svg')
const callInactive = getUrl('conversations/details/call-inactive.svg')
const emailActive = getUrl('conversations/details/email-active.svg')
const emailInactive = getUrl('conversations/details/email-inactive.svg')
const emailWhite = getUrl('conversations/details/email-white.svg')
const instagramActive = getUrl('conversations/details/instagram-active.svg')
const instagramInactive = getUrl('conversations/details/instagram-inactive.svg')
const facebookActive = getUrl('conversations/details/messenger-active.svg')
const facebookInactive = getUrl('conversations/details/messenger-inactive.svg')
const telegramActive = getUrl('conversations/details/telegram-active.svg')
const telegramInactive = getUrl('conversations/details/telegram-inactive.svg')
const textActive = getUrl('conversations/details/text-message-active.svg')
const textInactive = getUrl('conversations/details/text-message-inactive.svg')
const whatsAppActive = getUrl('conversations/details/whatsapp-active.svg')
const whatsAppInactive = getUrl('conversations/details/whatsapp-inactive.svg')
const expand = getUrl('conversations/details/expand.svg')
const minimize = getUrl('conversations/details/minimize.svg')
const eyeInactive = getUrl('conversations/details/eye-grey.svg')
const automateCircular = getUrl('conversations/details/automate-circular.svg')
const commerceCircular = getUrl('conversations/details/commerce-circular.svg')
const connectCircular = getUrl('conversations/details/connect-circular.svg')
const outboundCircular = getUrl('conversations/details/outbound-circular.svg')
const seccurityCircular = getUrl('conversations/details/security-circular.svg')
const suitcase = getUrl('conversations/details/suitcase.svg')
const code = getUrl('conversations/details/code.svg')
const tag = getUrl('conversations/details/tag.svg')
const documentBlue = getUrl('conversations/details/document-blue.svg')
const closeWhite = getUrl('conversations/details/close-white.svg')
const heart = getUrl('conversations/details/heart.svg')
const faceID = getUrl('conversations/details/face-id.svg')
const location2 = getUrl('conversations/details/location-2.svg')
const shop = getUrl('conversations/details/shop.svg')
const website = getUrl('conversations/details/website.svg')
//
// /** Chat Quick Responses */
const quickImage = getUrl('conversations/chat/messageComposer/picture.svg')
const quickVideo = getUrl('conversations/chat/messageComposer/video.svg')
const quickPdf = getUrl('conversations/chat/messageComposer/PDF.svg')
const quickText = getUrl('conversations/chat/messageComposer/text.svg')
const quickAudio = getUrl('conversations/chat/messageComposer/audio.svg')
//
// /** Chat Actions */
const chatTransferActive = getUrl('conversations/chat/actions/transfer-active.svg')
const chatTransferInactive = getUrl('conversations/chat/actions/transfer-inactive.svg')
const chatCloseActive = getUrl('conversations/chat/actions/close-active.svg')
const chatCloseInactive = getUrl('conversations/chat/actions/close-inactive.svg')
//
// /** Login */
const eye = getUrl('login/eye.svg')
const eyeOff = getUrl('login/eye-off.svg')
//
// /** Home */
const location = getUrl('home/location.svg')
const business = getUrl('home/business.svg')
const idCard = getUrl('home/id-card.svg')
const homeWs = getUrl('home/whatsapp.svg')
const homeIg = getUrl('home/instagram.svg')
const homeMs = getUrl('home/messenger.svg')
const person = getUrl('home/person.svg')
const sigma = getUrl('home/sigma.svg')
const messageBubble = getUrl('home/message-bubble.svg')
const messageBubbleRed = getUrl('home/message-bubble-red.svg')
//
// // rocket
const rocket = getUrl('home/rocket.svg')
const rocketWhite = getUrl('home/rocket-white.svg')
//
// // Settings
const pencil = getUrl('settings/pencil.svg')
const copy = getUrl('settings/copy.svg')
// // Channel settings
const chatWeb = getUrl('settings/ChannelsConfig/chat-web.svg')
const chatWebGreen = getUrl('settings/ChannelsConfig/chat-web-green.svg')
const facebookMessenger = getUrl('settings/ChannelsConfig/facebook-messenger.svg')
const facebookMessengerGreen = getUrl('settings/ChannelsConfig/facebook-messenger-green.svg')
const whatsapp = getUrl('settings/ChannelsConfig/whatsapp.svg')
const whatsappGreen = getUrl('settings/ChannelsConfig/whatsapp-green.svg')
const instagram = getUrl('settings/ChannelsConfig/instagram.svg')
const instagramGreen = getUrl('settings/ChannelsConfig/instagram-green.svg')
const sms = getUrl('settings/ChannelsConfig/sms.svg')
const smsGreen = getUrl('settings/ChannelsConfig/sms-green.svg')

export const room = {
  channels: {
    facebookMessenger: {
      active: messengerActive,
      inactive: messengerInactive
    },
    smooch_wp: {
      active: whatsappActive,
      inactive: whatsappInactive
    },
    gmail: {
      active: gmailActive,
      inactive: gmailInactive
    },
    whatsapp: {
      active: whatsappActive,
      inactive: whatsappInactive
    },
    sms: {
      active: smsActive,
      inactive: smsInactive
    },
    support: {
      active: supportActive,
      inactive: supportInactive
    },
    web: {
      active: webActive,
      inactive: webInactive
    },
    instagram: {
      active: instaActive,
      inactive: instaInactive,
      black: instaBlack
    },
    wizard: {
      active: wizardActive,
      inactive: wizardInactive
    }
  },
  indicators: {
    pin: {
      active: pinActive,
      inactive: pinInactive,
      white: pinWhite
    },
    clap: {
      active: clapActive,
      inactive: clapInactive
    },
    transferred: {
      active: transferredActive,
      inactive: transferredInactive
    },
    chatBot
  }
}

export const toolbar = {
  rocket,
  rocketWhite,
  logo: {
    kbLogo
  },
  mainMenu: {
    microsites,
    notifications,
    share,
    statistics,
    menuHamburger
  }
}

export const sidebar = {
  menu,
  setting,
  automate,
  communicate,
  converse,
  connect,
  dashboard
}

export const shared = {
  search,
  filter,
  account,
  accountGrey
}

export const messageComposer = {
  attachment,
  camera,
  history,
  mic,
  wink,
  musicNote,
  pdfAcrobat
}

export const quickResponses = {
  quickImage,
  quickVideo,
  quickPdf,
  quickText,
  quickAudio
}

export const chatDetails = {
  call: {
    active: callActive,
    inactive: callInactive
  },
  email: {
    active: emailActive,
    inactive: emailInactive,
    white: emailWhite
  },
  instagram: {
    active: instagramActive,
    inactive: instagramInactive
  },
  facebook: {
    active: facebookActive,
    inactive: facebookInactive
  },
  telegram: {
    active: telegramActive,
    inactive: telegramInactive
  },
  text: {
    active: textActive,
    inactive: textInactive
  },
  whatsapp: {
    active: whatsAppActive,
    inactive: whatsAppInactive
  },
  expand,
  minimize,
  eyeInactive,
  timelineIcons: {
    automateCircular,
    commerceCircular,
    connectCircular,
    outboundCircular,
    seccurityCircular
  },
  suitcase,
  code,
  tag,
  documentBlue,
  closeWhite,
  heart,
  faceID,
  location: location2,
  shop,
  website
}

export const chatActions = {
  transfer: {
    active: chatTransferActive,
    inactive: chatTransferInactive
  },
  close: {
    active: chatCloseActive,
    inactive: chatCloseInactive
  }
}

export const login = {
  eye,
  eyeOff
}

export const home = {
  location,
  business,
  idCard,
  homeWs,
  homeIg,
  homeMs,
  person,
  sigma,
  messageBubble,
  messageBubbleRed
}

export const settings = {
  pencil,
  copy,
  channels: {
    chatWeb,
    chatWebGreen,
    facebookMessenger,
    facebookMessengerGreen,
    whatsapp,
    whatsappGreen,
    instagram,
    instagramGreen,
    sms,
    smsGreen
  }
}
