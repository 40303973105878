
export enum CurrencyEnum {
    COP = 'COP',
    USD = 'USD'
}

export enum AmountEnum {
    MIN_USD = 10,
    MIN_COP = 55000,
    TRANSACTION_PAY = 4
}
