import { User, UserList } from 'src/components/models/User'

export const UsersStore = {
  namespaced: true,
  state: {
    users: [],
    lists: [],
    importer: {
      businessId: '',
      file: null,
      headers: [],
      listId: '',
      listName: '',
      importerSelection: '',
      newList: '',
      persons: [],
      step: 1
    },
    lastSavedPage: null,
    scrollPosition: null,
    count: null,
    advancedSearchFilter: {},
    comesFrom: '',
    savedItemsPerPage: null,
    filtersActive: false
  },

  mutations: {
    SET_USERS (state, users: User[]) {
      state.users = users
    },
    SET_USERS_COUNT (state, count) {
      state.count = count
    },
    SET_USERS_LISTS (state, lists: UserList[]) {
      state.lists = lists
    },
    SET_IMPORTER_FILE (state, file) {
      state.importer.file = file
    },
    SET_IMPORTER_PERSONS (state, persons) {
      state.importer.persons = persons
    },
    SET_IMPORTER_SELECTION (state, selection) {
      state.importer.importerSelection = selection
    },
    SET_IMPORTER_LIST_NAME (state, listName) {
      state.importer.listName = listName
    },
    SET_IMPORTER_HEADERS (state, value) {
      state.importer.headers = value
    },
    SET_LAST_PAGE (state, lastPage) {
      state.lastSavedPage = lastPage
    },
    SET_SCROLL_POSITION (state, position) {
      state.scrollPosition = position
    },
    SET_ADVANCED_SEARCH (state, advancedSearch) {
      state.advancedSearchFilter = advancedSearch
    },
    SET_PREVIOUS_COMPONENT (state, comesFrom) {
      state.comesFrom = comesFrom
    },
    SET_ITEMS_PER_PAGE (state, itemsPerPage) {
      state.savedItemsPerPage = itemsPerPage
    },
    SET_FILTERS_ACTIVE (state, filtersActive) {
      state.filtersActive = filtersActive
    }
  },
  getters: {
    getUsersCount (state) {
      return state.count
    }
  }
}
