// import axios from 'axios'
import store from '@/store'
import axios from 'axios'
import { GenericResponse } from '@/models/apis/GenericResponse'
import { RestResponseFactory } from '@/models/apis/RestResponse'
const cancelToken = axios.CancelToken
let source = cancelToken.source()

export default class SelfManagedBotServices {
  static async getBotAccess (): Promise<GenericResponse> {
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/is-whitelisted/${appId}`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getIndustries () {
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/industries`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async toggleBot (botStatus, botName): Promise<GenericResponse> {
    const botKey = process.env.VUE_APP_CHATBOT_KEY
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/set/status`
    const requestBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      active: !botStatus
    }

    const response = await axios.put(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async toggleAdvisorTransfer (botName, templateCode, transferStatus): Promise<GenericResponse> {
    const botKey = process.env.VUE_APP_CHATBOT_KEY
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/set/template/handoff/status`
    const requestBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      code: templateCode,
      handoffAfterUserResponse: !transferStatus
    }

    const response = await axios.put(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async assignTag (botName, templateCode, tag, tagStatus): Promise<GenericResponse> {
    const botKey = process.env.VUE_APP_CHATBOT_KEY
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/set/template/autoassign/tag/status`
    const requestBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      code: templateCode,
      tagName: tag,
      addTag: tagStatus
    }

    const response = await axios.put(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getAutobotSettings (botName): Promise<GenericResponse> {
    const botKey = process.env.VUE_APP_CHATBOT_KEY
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/get/settings`
    const requestBody = {
      appUUID: appId,
      companyUUID: companyId,
      chatbotName: botName
    }

    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async autobotMenuStatus (botName): Promise<GenericResponse> {
    const botKey = process.env.VUE_APP_CHATBOT_KEY
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/get/menu/status`
    const requestBody = {
      appUUID: appId,
      companyUUID: companyId,
      chatbotName: botName
    }

    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async autobotMenuToggle (botName, toggleStatus): Promise<GenericResponse> {
    const botKey = process.env.VUE_APP_CHATBOT_KEY
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/set/menu/status`
    const requestBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      menuStatus: toggleStatus
    }

    const response = await axios.put(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async autobotChannelToggle (botName, channel, channelStatus): Promise<GenericResponse> {
    const botKey = process.env.VUE_APP_CHATBOT_KEY
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/set/channel/status`
    const requestBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      channel: channel,
      active: channelStatus
    }

    const response = await axios.put(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async autobotInstagramReaction (botName, reactionStatus): Promise<GenericResponse> {
    const botKey = process.env.VUE_APP_CHATBOT_KEY
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/set/behavior/instagram-reaction`
    const requestBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      handoffOnInstagramReaction: reactionStatus
    }

    const response = await axios.put(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getIndustryTemplates (industryCode) {
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/${industryCode}`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async enableTemplate (templateCode, botName, status): Promise<GenericResponse> {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/set/template/status`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const requestBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      // [TODO] template code needs to be dynamic
      code: templateCode,
      active: status
    }

    const response = await axios.put(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }
  // Templates
  // Template Responses

  static async getTemplateResponses (templateCode, botName): Promise<GenericResponse> {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/get/template/responses`
    const botKey = process.env.VUE_APP_CHATBOT_KEY
    source.cancel('Request canceled')
    source = cancelToken.source()
    const chatbotIdentifier = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      // [TODO] Make sure this uses the actual channel
      template: templateCode,
      channel: 'all'
    }

    const response = await axios.post(url, chatbotIdentifier, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      },
      cancelToken: source.token
    })

    return new RestResponseFactory().process({ response })
  }

  static async addInitialTemplateResponse (selectedTemplate, botName) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/add/response`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const templateResponseData = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      templateCode: selectedTemplate,
      response: {
        // [TODO] This value comes from the dropdown
        channel: 'all',
        value: '¡Puedes comenzar a editar!',
        active: true
      }
    }

    const response = await axios.put(url, templateResponseData, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async editTemplateResponse (value, active, templateCode, messageContent, botName): Promise<GenericResponse> {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/set/response/value`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const requestBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      code: templateCode,
      response: {
        channel: 'all',
        value: value,
        active: active
      },
      newValue: messageContent
    }

    const response = await axios.put(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getRegisteredAgents (): Promise<GenericResponse> {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany']?.uuid
    const appId: string = store.getters['AppStore/getSelectedApp']?.uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/registered-agents`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const identifier = {
      appUUID: appId,
      companyUUID: companyId
    }

    const response = await axios.post(url, identifier, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async changeTemplateResponseStatus (selectedTemplate, templateValue, templateActive, newStatus, botName): Promise<GenericResponse> {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/set/response/status`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const requestBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      code: selectedTemplate,
      response: {
        channel: 'all',
        value: templateValue,
        active: templateActive
      },
      active: newStatus
    }
    // console.log('Toggle response body', requestBody)
    const response = await axios.put(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getBotAnswer (message, botName): Promise<GenericResponse> {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/test-answer`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const context = { conversation_id: '2c92fe3b-41a6-42d1-b7d3-4b2506d9e3ef', system: { initialized: true, dialog_stack: [{ dialog_node: 'root' }], dialog_turn_counter: 4, dialog_request_counter: 4, _node_output_map: { Bienvenido: { 0: [0] }, node_2_1636553355391: { 0: [0] } }, last_branch_node: 'node_7_1641314975063', branch_exited: true, branch_exited_reason: 'completed' }, time_stamp: '2022-04-06 17:02:19', metadata: { user_id: '2c92fe3b-41a6-42d1-b7d3-4b2506d9e3ef' }, habeas_data: 'accepted', habeas_data_date: '2022-04-06 16:52:42' }

    const chatbotIdentifierBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      // [TODO] Make sure this uses the actual channel
      channel: 'whatsapp',
      message: message,
      context: JSON.stringify(context)
    }

    const response = await axios.post(url, chatbotIdentifierBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getTemplateStatus (templateCode, botName): Promise<GenericResponse> {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/get/template/status`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const getTemplateStatusBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      code: templateCode
    }

    const response = await axios.post(url, getTemplateStatusBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getTemplatesStatus (templateCodes, botName): Promise<GenericResponse> {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/get/templates/status`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const getTemplateStatusBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      codes: templateCodes
    }

    const response = await axios.post(url, getTemplateStatusBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async getActiveTemplates (templateCodes, botName): Promise<GenericResponse> {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/get/templates/active`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const getTemplateStatusBody = {
      chatbotIdentifier: {
        appUUID: appId,
        companyUUID: companyId,
        chatbotName: botName
      },
      codes: templateCodes
    }

    const response = await axios.post(url, getTemplateStatusBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  static async setupIndustryAutobot (industry) {
    const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId: string = store.getters['AppStore/getSelectedApp'].uuid
    const url: string = `${process.env.VUE_APP_CHATBOT_URL}/chatbot/setup/industry/autobot`
    const botKey = process.env.VUE_APP_CHATBOT_KEY

    const requestBody = {
      chatbotInitInfo: {
        appUUID: appId,
        companyUUID: companyId
      },
      industry: industry
    }

    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': botKey
      }
    })

    return new RestResponseFactory().process({ response })
  }

  // static changeTemplateResponseStatus ({ templateCode, responseValue, responseStatus, finalStatus }) {
  //   const appId: string = store.getters['AppStore/getSelectedApp'].uuid
  //   const companyId: string = store.getters['CompanyStore/getSelectedCompany'].uuid

  //   const botKey = process.env.VUE_APP_CHATBOT_KEY

  //   const requestBody = {
  //     chatbotIdentifier: {
  //       appUUID: appId,
  //       companyUUID: companyId,
  //       chatbotName: 'Chatbot 1'
  //     },
  //     code: templateCode,
  //     response: {
  //       channel: 'all',
  //       value: responseValue,
  //       active: responseStatus
  //     },
  //     active: finalStatus
  //   }

  //   console.log('Changing Response Status Body', requestBody)

  //   if (finalStatus !== responseStatus) {
  //     requestBody.response.active = !finalStatus
  //   } else requestBody.response.active = !requestBody.response.active

  //   fetch('https://selfmanagedchatbot.kbe.ai/chatbot/set/response/status', {
  //     method: 'PUT',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'X-API-KEY': botKey
  //     },
  //     body: JSON.stringify(requestBody)
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.message === 'Response Updated') {
  //         console.log(data)
  //       }
  //       if (data.statusCode === 500 || data.statusCode === 400) {
  //         // Fix in case of request going wrong
  //         // this.enabled = !this.enabled
  //         console.log(data, 'Body of the request was', requestBody)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error', error)
  //     })
  // }
}
