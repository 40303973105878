<template>
  <div class="modal" v-if="show" @click="close">
    <div class="w-full d-flex justify-end pa-6">
      <v-icon @click="close" color="white" >mdi-close</v-icon>
    </div>
    <div :style="{ height:image.h, width:image.w }" class="imageContainer">
      <img @click.prevent :src="image.src" alt=""/>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus'

export default {
  name: 'ImageModal',
  data () {
    return {
      show: false,
      image: ''
    }
  },
  mounted () {
    EventBus.$on('showModalImage', ({ show, image }) => {
      this.show = show
      this.image = image
    })
  },
  methods: {
    close () {
      this.show = false
      this.image = ''
    }
  }
}
</script>

<style scoped lang="scss">
 .modal {
   position: absolute;
   width: 100vw;
   height: 100vh;
   z-index: 999999999;
   background-color: rgba(22, 39, 70, 0.8);
   display: flex;
   flex-direction: column;
   align-items: center;

   .imageContainer {
     margin: auto;
     img {
       width: 80%;
       height:auto;
       max-height: 800px;
       margin: auto;
       border-radius:4px;

       animation-name: zoom;
       animation-duration: 0.6s;
     }

     @keyframes zoom {
       from {transform: scale(0.1)}
       to {transform: scale(1)}
     }
   }
 }
</style>
