export const GENERAL_BALANCE_LIMIT = 0.0
export const WHITE_LIST_ALLOWED_PHONE = ['+13474583147', '+573136050421'] // Esto es por el momento acá quemado porque es una necesidad importante.

export const BillingStore = {
  namespaced: true,
  state: {
    appConfig: {}
  },
  mutations: {
    SET_APP_CONFIG (state, appConfig) {
      state.appConfig = appConfig
    }
  },
  actions: {
    setAppConfig ({ commit }, appConfig) {
      commit('SET_APP_CONFIG', appConfig)
    }
  },
  getters: {
    getAppUUID (state: any): string | null {
      return state?.appConfig?.appUUID
    },
    getBalance (state: any) : number {
      if (!state.appConfig?.plan?.label) return GENERAL_BALANCE_LIMIT
      return state?.appConfig?.balanceUSD
    },
    getHasCredit (state: any): boolean {
      return state.appConfig?.hasCredit
    },
    getNamePlan (state: any) : string | null {
      return state.appConfig?.plan?.label
    }
  }
}
