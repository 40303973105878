import { render, staticRenderFns } from "./NewToolbar.vue?vue&type=template&id=6cd70b7a&scoped=true&"
import script from "./NewToolbar.vue?vue&type=script&lang=js&"
export * from "./NewToolbar.vue?vue&type=script&lang=js&"
import style0 from "./NewToolbar.vue?vue&type=style&index=0&id=6cd70b7a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd70b7a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
