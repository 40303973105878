export class GenericResponse {
    status: boolean
    message: string
    data: any

    constructor ({ status, message, data }: {status: boolean, message: string, data: any}) {
      this.status = status
      this.message = message
      this.data = data
    }
}
