import store from '@/store'
import link from '@/utils/links'
import BetaPreviewerAppsService from '@/components/configuration/Services/BetaPreviewerAppsService'
import { SelfManagedBotController } from '@/components/automate/controllers/SelfManagedBotController'
import AppUUIDsService from '@/services/AppUUIDsService'

export default class SideMenuItemsProvider {
  static token () {
    return store.getters['UserStore/getToken']
  }

  static isAdmin () {
    return store.getters['UserStore/isAdmin']
  }

  static isSuperAdmin () {
    return store.getters['UserStore/isSuperAdmin']
  }

  static showFormsPipe (items) {
    const hasPermission = store.getters['UserStore/hasFormsRole']
    if (hasPermission || this.isAdmin() || this.isSuperAdmin()) {
      items.push({
        text: 'forms',
        link: link.forms,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/forms.svg`
      })
    }
    return items
  }

  static showAuthPipe (items) {
    const hasPermission = store.getters['UserStore/hasRoleAuth']
    if (hasPermission || this.isAdmin() || this.isSuperAdmin()) {
      items.push({
        text: 'auth',
        link: link.auth,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/auth.svg`
      })
    }
    return items
  }

  static showImportsPipe (items) {
    const hasPermission = store.getters['UserStore/hasRoleRecordImport']
    if (hasPermission || this.isAdmin() || this.isSuperAdmin()) {
      items.push({
        text: 'imports',
        link: link.imports,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/import.svg`
      })
    }
    return items
  }

  static showPipelinesPipe (items) {
    items.push({
      text: 'opportunities',
      link: link.pipelines,
      icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/opportunities.svg`
    })
    return items
  }

  static showCampaignsPipe (items) {
    const hasPermission = store.getters['UserStore/hasCampaignsRole']
    if (hasPermission || this.isAdmin() || this.isSuperAdmin()) {
      items.push({
        text: 'massiveSend',
        link: link.campaigns,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/chats.svg`
      })
    }
    return items
  }

  static async showAutobotsPipe (items) {
    const newBluebirdUser = BetaPreviewerAppsService.isNewBlueBirdUser()
    const allowedBrand = await SelfManagedBotController.getBotAccess()
    const canUseAutobot = allowedBrand || newBluebirdUser
    if ((canUseAutobot && this.isAdmin()) || (canUseAutobot && this.isSuperAdmin())) {
      items.push({
        text: 'autobots',
        link: link.automate,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/robot.svg`
      })
    }
    return items
  }

  // static showSuperAdminMetricsPipe (items) {
  //   if (this.isSuperAdmin()) {
  //     items.push({
  //       text: 'Superadmin',
  //       link: link.home,
  //       icon: `${process.env.VUE_APP_ASSETS_URL}/common/metric.svg`
  //     })
  //   }
  //   return items
  // }

  static showTriggersPipe (items) {
    const hasPermission = store.getters['UserStore/hasRoleTriggers']
    if (hasPermission || this.isAdmin() || this.isSuperAdmin()) {
      items.push({
        text: 'actions',
        link: link.triggers,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/node.svg`
      })
    }
    return items
  }

  static showMicrositesPipe (items) {
    const hasPermission = store.getters['UserStore/hasRoleTriggers']
    if (hasPermission || this.isAdmin() || this.isSuperAdmin()) {
      items.push({
        text: 'microsites',
        link: link.micrositeHome,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/planet.svg`
      })
    }
    return items
  }

  static showEventsPipe (items) {
    const appIdSelected = store.getters['AppStore/getSelectedApp']?.uuid
    const modules = store.getters['AppStore/getAppModules']
    const canUseEvents = modules && modules.filter(m => m.name === 'events').length > 0
    if (canUseEvents && (this.isAdmin() || this.isSuperAdmin())) {
      items.push({
        text: 'events',
        link: link.events,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/ticket.svg`
      })
    }
    const canUseOldEvents = [AppUUIDsService.appMedellinMusicWeek(),
      AppUUIDsService.appMedellinStyle()].includes(appIdSelected)
    if (canUseOldEvents && (this.isAdmin() || this.isSuperAdmin())) {
      items.push({
        text: 'oldevents',
        link: `https://app.keybe.ai/apps/bisovs8kwjtk8iyxi/events?token=${this.token()}`,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/ticket.svg`
      })
    }
    return items
  }

  static showBillingPipe (items) {
    if (this.isSuperAdmin()) {
      items.push({
        text: 'billingKB',
        link: `https://app.keybe.ai/invoice/invoices?token=${this.token()}`,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidebar/dollar.svg`
      })
    }
    return items
  }

  static showConsumptionsPipe (items) {
    if (this.isAdmin() || this.isSuperAdmin()) {
      items.push({
        text: 'tabConsumptions',
        link: link.billingConfigs,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidebar/dollar.svg`
      })
    }
    return items
  }

  static getItems (uuid) {
    let items = []
    if (uuid === 'bisovs8kwjtk8iyxi') {
      items = this.showEventsPipe(items)
    } else if (uuid === '4xqa4b8khxrofef') {
      items = this.showBillingPipe(items)
      items = this.showConsumptionsPipe(items)
    } else if (uuid === '4xqaokbkhfbj8ox') {
      items = this.showMicrositesPipe(items)
      // items = this.showFormsPipe(items)
      // items = this.showAuthPipe(items)
      // items = this.showImportsPipe(items)
      // items = this.showPipelinesPipe(items)
      items = this.showCampaignsPipe(items)
      items = this.showTriggersPipe(items)
      // items = this.showSuperAdminMetricsPipe(items)
      items = this.showConsumptionsPipe(items)
      items = this.showEventsPipe(items)
      items.push({
        text: 'extensions',
        link: link.extensions,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/extensions.svg`
      },
      {
        text: 'configuration',
        link: link.config,
        icon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/settings.svg`
      })
    }
    return items
  }

  static async getAutobots () {
    let items = this.getItems(store.getters['AppStore/getSelectedApp']?.uuid)
    items = await this.showAutobotsPipe(items)
    return items
  }
}
