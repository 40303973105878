<template>
    <v-navigation-drawer
            right
            app
            dark
            floating
            v-model="drawer"
            temporary
            color="rgba(76, 83, 92, 0.95)"
            style="z-index: 200"
            :key="render"
    >
        <div class="SideMenu" v-if="shouldShowComponent()">
            <div class="w-full">
                <v-btn icon dark @click="drawer=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <!-- <div class="d-flex justify-center mt-8" v-if="!$vuetify.breakpoint.smAndDown">
              <KeybeButton
                :color="'green'"
                :text="$t('goBackToPreviousVersion')"
                @click.native="goBackToV5()"/>
            </div> -->
            <div class="SideMenu-items -mt-10">
                <div class="profile-container" v-if="$vuetify.breakpoint.smAndDown">
                    <div class="profilePic">
                        <img alt="Usuario" v-if="userComputed.photo" :src="userComputed.photo">
                        <v-icon size="40" v-else>mdi-account-circle</v-icon>
                    </div>
                    <span class="w-3/4 text-center mt-2">{{ fullName }}</span>
                    <span class="text-xs mb-2 w-3/4 text-center">{{ email }}</span>
                    <AdvisorStatus dark="true"/>
                </div>
                <div class="liteVersionContainer" v-if="$vuetify.breakpoint.smAndDown">
                    <div v-for="(item, index) in itemsMobile" :key="'MenuItem'+index" class="SideMenu-item mb-4"
                         @click="goToLink(item)">
                        <img :src="item.icon" alt="icon" height="16" width="16" class="mr-2">
                        <span :class="$route.path===item.link ? 'selected-route':''">
              {{ $t(item.text) }}
            </span>
                    </div>
                    <h3 class="text-center text-sm">{{ $t('liteVersionTitle') }}</h3>
                    <p class="text-center text-sm">{{ $t('liteVersionMessage') }}</p>
                    <DownloadAppButton/>
                </div>
                <div v-for="(item, index) in items" :key="'MenuItem'+index" class="SideMenu-item" v-else
                     @click="goToLink(item)">
                    <img :src="item.icon" alt="icon" height="16" width="16" class="mr-2">
                    <span :class="$route.path===item.link ? 'selected-route':''">
            {{ $t(item.text) }}
          </span>
                </div>
                <div class="SideMenu-item" @click="_logout()">
                    <img :src="logoutIcon" alt="icon" height="16" width="16" class="mr-2">
                    {{ $t('logout') }}
                </div>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script>
import Vue from 'vue'
import { EventBus } from '@/eventBus'
import link from '@/utils/links'
import { mapState, mapGetters, mapActions } from 'vuex'
import AppService from '@/services/AppService'
import AppUUIDsService from '@/services/AppUUIDsService'
import SideMenuItemsProvider from '@/Providers/SideMenuItemsProvider'
import PushNotificationsController from '@/controllers/PushNotificationsController'
import AuthService from '@/services/AuthService'

// components
const AdvisorStatus = () => import(/* webpackChunkName: "shared" */ '@/components/conversations/components/rooms/AdvisorStatus')
const DownloadAppButton = () => import(/* webpackChunkName: "shared" */ '@/components/shared/components/DownloadAppButton')

export default Vue.extend({
  name: 'SideMenu',
  components: {
    DownloadAppButton,
    AdvisorStatus
  },
  data () {
    return {
      drawer: false,
      items: SideMenuItemsProvider.getItems(),
      consumptionIcon: `${process.env.VUE_APP_ASSETS_URL}/common/metric.svg`,
      logoutIcon: `${process.env.VUE_APP_ASSETS_URL}/icons/sidemenu/logout.svg`,
      itemsMobile: [
        {
          text: 'chats',
          link: link.conversations,
          icon: `${process.env.VUE_APP_ASSETS_URL}/common/chat.svg`
        },
        {
          text: 'people',
          link: link.people,
          icon: `${process.env.VUE_APP_ASSETS_URL}/common/person.svg`
        }
      ],
      render: 0
    }
  },
  computed: {
    ...mapState('UserStore', ['user']),
    ...mapState('AppStore', ['language', 'selectedApp']),
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapState('BillingStore', ['appConfig']),
    ...mapGetters('AppStore', ['getSelectedApp']),
    ...mapGetters('UserStore', ['getToken']),
    ...mapGetters('UserStore', ['isSuperAdmin', 'isAdmin', 'hasRoleList', 'hasRoleBusiness', 'hasFormsRole', 'hasRoleRecordImport', 'hasCampaignsRole', 'hasJourneysRole', 'hasRoleTriggers']),
    userComputed: function () {
      return this.user
    },
    fullName () {
      const name = this.userComputed?.name || ''
      const lastName = this.userComputed?.lastName || ''
      return `${name} ${lastName}`
    },
    email () {
      return this.userComputed?.email || ''
    },
    appKeybe () {
      return this.selectedApp?.uuid === AppUUIDsService.appKeybe()
    },
    plan () {
      return this.appConfig?.plan?.label || ''
    }
  },
  mounted () {
    EventBus.$on('openSideMenu', () => {
      this.drawer = true
    })
    EventBus.$on('appModules', () => {
      this.setModules()
    })
  },
  methods: {
    ...mapActions('UserStore', ['logout']),
    shouldShowComponent () {
      const allowedIds = ['g6md71lkn2ix0f', 'cpu4on1lhapaha5', 'c0681liowgag0', '4m3wtp1lnapg91m', '4m3wtp1lnao2bzu', '171xemapnl27rgdcy', '4xqa4b8khxrofef', 'bisovs8kwjtk8iyxi', '4xqaokbkhfbj8ox']
      return allowedIds.includes(this.getSelectedApp.uuid)
    },
    async setModules () {
      console.log('SETTING MODULES')
      this.$store.commit('AppStore/SET_APP_MODULES', [])
      await AppService.getAppModules()
      this.render++
      this.items = SideMenuItemsProvider.getItems(this.getSelectedApp.uuid)
      this.items = await SideMenuItemsProvider.getAutobots()
    },
    goToLink (item) {
      this.$store.commit('UsersStore/SET_LAST_PAGE', null)
      this.$store.commit('UsersStore/SET_ITEMS_PER_PAGE', null)
      this.$store.commit('UsersStore/SET_ADVANCED_SEARCH', {})
      if (item.text === 'billingKB' || item.text === 'oldevents') {
        window.location.replace(item.link)
      } else {
        this.$router.push(item.link)
      }
    },
    goBackToV5 () {
      const appId = this.getSelectedApp.uuid
      const token = this.getToken
      window.location.replace(`https://old.keybe.app/?app=${appId}&token=${token}`)
    },
    async _logout () {
      await PushNotificationsController.setOneSignalStatus({ active: false })
      await AuthService.logout()
      this.logout()
      // window.location.href = `${process.env.VUE_APP_V6_URL}/signin?logout=true`
      this.$router.push(link.signin)
    }
  },
  async created () {
    const response = await SideMenuItemsProvider.getAutobots()
    this.items = response
  },
  watch: {
    async selectedApp () {
      this.setModules()
    }
  }
})
</script>

<style scoped lang="scss">
.SideMenu {
  height: 90%;
  padding: 0 1rem;
}

.SideMenu-items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #FFFFFF;
}

.SideMenu-item {
  margin: 0.8rem 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  @media (max-width: 960px) {
    text-align: center;
    justify-content: center;
    margin: 0.8rem 0;
    width: 100%;
  }
}

.user-avatar {
  border: 2px solid white;
  background: transparent !important;
  width: 32px;
  height: 32px;
}

.profilePic {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #01CFE4;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 90%;
    width: 90%;
    border-radius: 50%;
  }
}

.profile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-size: 1rem;
}

.selected-route {
  border-bottom: 2px solid #FFFFFF;
}

.liteVersionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

small a {
  color: white;
  text-decoration: none;
}
</style>
