import SelfManagedBotServices from '@/components/automate/services/SelfManagedBotService'
import { GenericResponse } from '@/models/apis/GenericResponse'

export class SelfManagedBotController {
  static async getBotAccess (): Promise<any> {
    const response: GenericResponse = await SelfManagedBotServices.getBotAccess()
    return response.data.allowedBrand
  }

  static async getIndustries (): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.getIndustries()
    return response.data
  }

  static async toggleBot (botStatus, botName): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.toggleBot(botStatus, botName)
    return response.data
  }

  static async toggleAdvisorTransfer (botName, templateCode, transferStatus): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.toggleAdvisorTransfer(botName, templateCode, transferStatus)
    return response.data
  }

  static async assignTag (botName, templateCode, tag, tagStatus): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.assignTag(botName, templateCode, tag, tagStatus)
    return response.data
  }

  static async getAutobotSettings (botName): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.getAutobotSettings(botName)
    return response.data
  }

  static async autobotMenuStatus (botName): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.autobotMenuStatus(botName)
    return response.data
  }

  static async autobotMenuToggle (botName, toggleStatus): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.autobotMenuToggle(botName, toggleStatus)
    return response.data
  }

  static async autobotChannelToggle (botName, channel, channelStatus): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.autobotChannelToggle(botName, channel, channelStatus)
    return response.data
  }

  static async autobotInstagramReaction (botName, reactionStatus): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.autobotInstagramReaction(botName, reactionStatus)
    return response.data
  }

  static async getIndustryTemplates (industryCode): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.getIndustryTemplates(industryCode)
    return response.data
  }

  static async toggleTemplate (templateCode, botName, status): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.enableTemplate(templateCode, botName, status)

    // if (response.data) {
    //   console.warn('ENABLING TEMPLATES', response)
    // }
    /* console.log('Fix me', response.data.message) */
    return response
  }

  static async enableTemplate (templateCode, botName): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.enableTemplate(templateCode, botName, true)

    // if (response.data) {
    //   console.warn('ENABLING TEMPLATES', response)
    // }
    /* console.log('Fix me', response.data.message) */
    return response
  }

  static async getTemplateResponses (templateCode, botName): Promise<any> {
    const response: GenericResponse =
      await SelfManagedBotServices.getTemplateResponses(templateCode, botName)

    if (response.status) {
      if (!response.data?.responses) {
        console.log('Oops, no responses found')
        await SelfManagedBotServices.addInitialTemplateResponse(templateCode, botName)
        const initialResponse: GenericResponse =
        await SelfManagedBotServices.getTemplateResponses(templateCode, botName)
        return initialResponse.data?.responses?.responses
      }
      return response.data?.responses?.responses
    }

    /* console.log('Fix me', response.data.message) */
    return []
  }

  static async getRegisteredAgents (): Promise<any> {
    const response: GenericResponse =
    await SelfManagedBotServices.getRegisteredAgents()
    return response.data
  }

  static async getBotStatus (botName): Promise<any> {
    const response: GenericResponse =
      await SelfManagedBotServices.getRegisteredAgents()

    const result = response.data.agents.find(({ name }) => name === botName)
    if (result) {
      console.warn(result)
      return result.active
    } else {
      console.error('NO BOT', response.data.message, botName)
      return false
    }
  }

  static async changeResponseStatus (selectedTemplate, templateValue, templateActive, newStatus, botName): Promise<any> {
    const response: GenericResponse =
      await SelfManagedBotServices.changeTemplateResponseStatus(selectedTemplate, templateValue, templateActive, newStatus, botName)

    if (response.data?.message === 'Response Updated') {
      return newStatus
    }
    return false
    // console.error('Fix me', response.data.message)
  }

  static async getTemplateStatus (templateCode, botName): Promise<any> {
    const response: GenericResponse =
        await SelfManagedBotServices.getTemplateStatus(templateCode, botName)
    return response.data
  }

  static async getTemplatesStatus (templateCodes, botName): Promise<any> {
    const response: GenericResponse =
        await SelfManagedBotServices.getTemplatesStatus(templateCodes, botName)
    return response.data
  }

  static async getActiveTemplates (templateCodes, botName): Promise<any> {
    const response: GenericResponse =
        await SelfManagedBotServices.getActiveTemplates(templateCodes, botName)
    return response.data
  }

  static async getBotAnswer (message, botName): Promise<any> {
    const response: GenericResponse =
      await SelfManagedBotServices.getBotAnswer(message, botName)

    if (response.data?.messageResponse) {
      return response.data.messageResponse.answer
    }

    console.error('Fix me', response)
  }

  static async editTemplateResponse (value, active, templateCode, messageContent, botName): Promise<any> {
    const response: GenericResponse =
      await SelfManagedBotServices.editTemplateResponse(value, active, templateCode, messageContent, botName)

    // console.log('Template Response Data JSON', requestBody)
    console.log(
      'Editing response',
      response.data
    )

    if (response.data?.message === 'Response Updated') {
      return true
    }

    console.error('Fix me', response)
  }

  static async setupIndustryAutobot (industry): Promise<any> {
    const response: GenericResponse =
      await SelfManagedBotServices.setupIndustryAutobot(industry)

    if (!response.data) {
      console.error('Fix me', response)
    }
  }
}
