export default class Colors {
    static dark1: string = '#4c535c'
    static dark2: string = '#8898aa'
    static dark3: string = '#e9eaed'
    static dark4: string = '#ecedf1'
    static light1: string = '#f6f9fc'
    static light2: string = '#f0f4fd'
    static light3: string = '#f9f9fb'
    static light4: string = '#eef1f5'
    static blue1: string = '#b3fdff'
    static blue2: string = '#00cff2'
    static blue3: string = '#3366ff'
    static blue4: string = '#0faef6'
    static facebook: string = '#1877f2'
    static bubble: string = '#258fff'
    static blueNight: string = '#162746'
    static green1: string = '#93ff80'
    static green2: string = '#00cc99'
    static green3: string = '#007d87'
    static yellow1: string = '#ffe9a4'
    static yellow2: string = '#ffcc00'
    static yellow3: string = '#a58500'
    static automate1: string = '#ff99cc'
    static automate2: string = '#fc3473'
    static automate3: string = '#88116d'
    static formDark: string = '#2f2f2fcc'
    static toogleOn: string = '#e2ffc7'
    static white: string = '#ffffff'
    static offWhite: string = '#ffffff'
    static black: string = '#000000'
    static grey: string = '#333538'
    static verdeBird: string = '#01cfe4'
    static gray: string = '#8898AA'
    static green4: string = '#3AF69B'
    static darkThemeDark4: string = '#262f38'
    static darkThemeWhite: string = '#333F4B'
    static darkThemeLight1: string = '#303A44'
    static darkThemeDark3: string = '#303A44'
    static darkThemeLight2: string = '#4c555d'
    static darkThemeLight3: string = '#303A44'
    static darkThemeOffWhite: string = '#3d424a'
    static darkThemeLight4: string = '#2d3741'
}
