import Rollbar from 'rollbar'

export default class ConversationsRollbarService {
    #rollbar: Rollbar

    constructor () {
      this.#rollbar = new Rollbar({
        accessToken: process.env.VUE_APP_CONVERSATIONS_ROLLBAR_TOKEN,
        captureUncaught: false,
        captureUnhandledRejections: false,
        environment: process.env.VUE_APP_NODE_ENV
      })
    }

    static getInstance () {
      // @ts-ignore
      if (!ConversationsRollbarService.instance) {
        // @ts-ignore
        ConversationsRollbarService.instance = new ConversationsRollbarService()
      }
      // @ts-ignore
      return ConversationsRollbarService.instance
    }

    reportError (errorMessage, data = {}) {
      this.#rollbar.error(errorMessage, data)
    }

    reportInfo (info) {
      this.#rollbar.info(info)
    }

    reportCritical (errorMessage, data = {}) {
      this.#rollbar.critical(errorMessage, data)
    }

    reportWarning (message, data = {}) {
      this.#rollbar.warning(message, data)
    }

    reportDebug (message, data = {}) {
      this.#rollbar.debug(message)
    }
}
