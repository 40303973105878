var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-app',[_c('loader')],1):(!_vm.isLoading)?_c('v-app',{attrs:{"color":"dark4"}},[_c('toast-notification'),(_vm.isFrontOfficeView)?_c('v-app',[_c('router-view')],1):_c('div',[(_vm.showTutorial)?_c('div',{staticClass:"blueCover"},[_c('v-btn',{attrs:{"color":"#0FAEF6","rounded":"","dark":"","large":"","elevation":"0"},on:{"click":_vm.cancelTutorial}},[_vm._v(" "+_vm._s(_vm.$t('skipTutorial'))+" ")])],1):_vm._e(),_c('ImageModal'),_c('Dialog',{attrs:{"dialog-component":_vm.flowsPopUpComponent,"background":_vm.$vuetify.theme.dark ? _vm.Colors.darkThemeDark3 : 'white',"persistent":false,"max-width":"594px"},on:{"close":function($event){_vm.flowsPopUp = false},"cancel":function($event){_vm.flowsPopUp = false}},model:{value:(_vm.flowsPopUp),callback:function ($$v) {_vm.flowsPopUp=$$v},expression:"flowsPopUp"}}),_c('Dialog',{attrs:{"dialog-component":_vm.flowsDonePopUpComponent,"background":_vm.$vuetify.theme.dark ? _vm.Colors.darkThemeDark3 : 'white',"persistent":false,"max-width":"594px"},on:{"close":function($event){_vm.flowsDonePopUp = false},"cancel":function($event){_vm.flowsDonePopUp = false}},model:{value:(_vm.flowsDonePopUp),callback:function ($$v) {_vm.flowsDonePopUp=$$v},expression:"flowsDonePopUp"}}),_c('v-app',{staticClass:"app"},[(_vm.shouldShowComponent())?_c('SideMenu'):_vm._e(),_c('v-main',{style:({
        backgroundColor: _vm.$vuetify.theme.dark
          ? _vm.Colors.darkThemeDark4
          : _vm.Colors.dark4,
      })},[_c('NewToolbar'),_c('transition',{attrs:{"name":"slide","mode":"out-in"}},[_c('router-view',{staticClass:"router-view",style:({
            backgroundColor: _vm.$vuetify.theme.dark
              ? _vm.Colors.darkThemeDark4
              : _vm.Colors.dark4,
          })})],1),_c('Footer')],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }