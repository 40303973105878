


































import Vue from 'vue'

import { mapActions, mapState } from 'vuex'

// Mutations
// import MutationAdviser from '@/components/conversations/graphql/mutation-advisor-status.graphql'
// Models
import { Adviser } from '../../../models/Adviser'

// Components
import * as icons from '@/plugins/icons/icons'
import AdvisersService from '@/components/conversations/Services/AdvisersService'
import { EventBus } from '@/eventBus'
import { UserStates } from '@/components/conversations/enums/UserStates'
import PushNotificationsController from '@/controllers/PushNotificationsController'

export default Vue.extend({
  data: function () {
    return {
      icons: icons,
      value: false,
      statuses: [
        { title: this.$t('active'), value: true },
        { title: this.$t('inactive'), value: false }
      ]
    }
  },
  props: ['dark'],
  computed: {
    ...mapState('UserStore', ['user']),
    ...mapState('AppStore', ['advisers', 'selectedApp']),
    statusColor () {
      if (this.value === true) return '#7BFF00'
      else return '#ff0000'
    },

    status () {
      if (this.value === true) {
        return this.$t('active')
      } else {
        return this.$t('inactive')
      }
    }
  },
  watch: {
    selectedApp () {
      this.getStatus()
    },
    advisers (advisers: Adviser[]) {
      const currentAdv = advisers.find(
        (adv) => adv.userId === this.user.userId
      )
      if (currentAdv) {
        this.value = currentAdv.status === 'online'
      } else {
        this.value = false
      }
    }
  },
  methods: {
    ...mapActions('AppStore', ['getAdviserStatus', 'updateAdviser']),
    async handleChange (value: boolean) {
      const status = value ? UserStates.Online : UserStates.Offline
      const userId = this.user.userId
      const variables = {
        userId,
        status,
        appUUID: this.selectedApp.uuid
      }
      const response = await AdvisersService.updateAdviser(variables)
      if (response.status) {
        const adviser = response.data.data.adviser
        this.updateAdviser(adviser)
        await PushNotificationsController.setOneSignalStatus({ active: value })
      } else {
        EventBus.$emit('toast', 'error', this.$t('errorUpdateAdviserStatus'))
      }
    },
    async getStatus () {
      const currentStatus = await this.getAdviserStatus(
        this.user.userId
      )
      this.value = currentStatus === UserStates.Online
      await PushNotificationsController.setOneSignalStatus({ active: this.value })
    }
  },
  mounted () {
    this.getStatus()
  }
})
