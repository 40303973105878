export default class operations {
  static areEqual (a, b): boolean {
    if (a === b) return true
    else return false
  }

  static isJson (str: string | JSON): boolean {
    try {
      JSON.parse(String(str))
    } catch (e) {
      return false
    }

    return true
  }
}
