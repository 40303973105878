export const PipelinesStore = {
  namespaced: true,
  state: {
    pipelineId: null,
    opProducts: [],
    lastSelectedPipelineId: ''
  },
  mutations: {
    SET_SELECTED_PIPELINE (state, pipelineId) {
      state.pipelineId = pipelineId
    },
    SET_OPPORTUNITY_PRODUCTS (state, opProducts) {
      state.opProducts = opProducts
    }
  },
  actions: {
    setSelectedPipeline (context, pipelineId: String) {
      context.commit('SET_SELECTED_PIPELINE', pipelineId)
    }
  },
  getters: {
    getPipelineId (state) {
      return state.pipelineId
    }
  }
}
