<template>
  <div id="RoleSelector" class="RoleSelector">
    <span id="RoleSelector-email" class="mr-4">{{user.email}}</span>
    <img id="RoleSelector-checked" class="mr-4" height="20px" width="20px" :src="checkedIcon" alt=""/>
    <img id="RoleSelector-help" class="mr-4" :src="helpIcon" alt="" height="20px" width="20px"/>
    <div id="RoleSelector-selector">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <div class="selectorButton" v-on="on">
            <span class="mr-2">{{ $t(user.role) }}</span>
            <v-icon size="24" :color="Colors.blue4">mdi-chevron-down</v-icon>
          </div>
        </template>
        <v-list>
          <v-list-item v-for="(role, index) in roles" :key="`role ${index}`" @click="selectRole(role)">
            <v-list-item-title>{{ $t(role) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import Colors from '@/utils/Colors'
import { RolesEnum } from '@/Enums/RolesEnum'
export default {
  name: 'RoleSelector.vue',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      helpIcon: `${process.env.VUE_APP_ASSETS_URL}/common/help.svg`,
      checkedIcon: `${process.env.VUE_APP_ASSETS_URL}/conversation-module/checked.svg`,
      roles: [RolesEnum.ADVISER, RolesEnum.COORDINATOR, RolesEnum.ADMIN],
      Colors
    }
  },
  methods: {
    selectRole (role) {
      this.user.role = role
    }
  }
}
</script>

<style scoped lang="scss">
.RoleSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.selectorButton {
  background-color: white;
  padding: 1rem 1.5rem;
  border-radius: 40px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
