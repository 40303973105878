// import axios from 'axios'
import store from '@/store'
import moment from 'moment'

export default class BetaPreviewerAppsService {
  static isNewBlueBirdUser () {
    const appCreatedAt: string = store.getters['AppStore/getSelectedApp']?.created_at
    const newBluebirdUser = moment(appCreatedAt).isAfter('2022-02-28')
    return newBluebirdUser
  }

  static getPreviewerApps () {
    const selfManagedBotApps = [
      '4xqaokbkhgtrwiq', // REINDUSTRIAS
      'bxynf6aky1eot2i', // Ingenieros de Marketing
      'l6k7uekuslgua8', // Mi Eslabón
      'bxynm7zkuy704gg', // mitsubishi motors
      '4xqanekqgxpqft', // CasaToro - Renault
      '4xqaosvkamm5sm4', // KB.Live
      'l6k72k7kyivj24h', // Based Code
      'bxynonikyiv5x9y', // Arianna Tancredi
      'bxynonikyiv9cyl', // Catalina Velez
      '171xemnhskyknuu84', // Miguel
      'bxynonikyivfwke', // Mauro Cominotti
      'bxynonikyivpmma', // Robot Pizza
      'bxynonikyiw7qbi', // Fernando Vargas
      'bxynonikyiwmiiu', // Jokka Ventures
      '171xemnhskyj8fkb1', // Brandon David
      'l6k72k7kyj9813x', // Keybe-David
      'l6k72k7kyk4l4s7', // Sebastian Giraldo
      'bxynonikyk4shw8', // Diana
      'bxynonikyk4x5d8', // Ximena Restrepo
      'l6k72k7kyk58x9d', // Maria Isabel Sierra
      'l6k72k7kykgu30q', // Felipe Arango
      'bxynonikykgxgfp', // Keybe-Daniel Cuervo
      'bxynonikykcoa9f', // KamuraKB
      'bxynonikykh37bp', // Andres Group
      'bxynonikykhb660', // Coyote
      'bxynonikyj92xtf', // NSO
      'bxynonikyiux80n', // Gossip
      'bxynonikyluaknu', // Ana Karina Jimenez
      'l6k72k7kyludwv8', // Nicolas Saldarriaga
      'bxynonikylugmcm', // Felipe Mantilla
      '171xemnhskyom0unm', // Kosiaka
      'l6k76zmkx271uns', // JSV
      'l6k72k7kyoy5yrp', // Dev0roz
      'l6k72k7kyoxf5ev', // Juanes Rios CIO
      '171xemnhskyow23zd', // Malalarango
      'l6k72k7kyossvyj', // Daniela Uribe Rh
      'l6k72k7kyomwluo', // Sebastian Martinez
      '171xemnhskyomo7c3', // Daniela Villegas Success
      '171xemnhskyomjvyu', // Daniela Garcia
      'bxynonikyof96xr', // ALV
      '171xemnhskyncp4nv', // Reposteria y eventos Laura Posa
      'bxynonikynckkfh', // Koto
      '171xemnhskynchs13', // La loca de los gatos - Johanna Gal
      'l6k72k7kynceww5', // Mi cocina POP - Daniel Gratz
      'l6k72k7kync4bxl', // Cristina ZAZ - Cristina Zuluaga
      '171xemnhskync0tux', // Abella Autos
      '171xemnhskyn5x5xr', // Juan R
      'bxynonikyn5u3fx', // Allan Ulloa
      'bxynonikyn5qc60', // OPS!
      'l6k72k7kyn5efyb', // KAM JV
      '171xemnhskym3panb', // Sara Insuasty
      'bxynonikym3lr96', // The Nius Luis Carlos
      '171xemnhskym3ekl1', // Jonny’s Recs
      'l6k72k7kyne37xu', // Jenn’s Curls
      'l6k72k7kylumcm7', // Mid One - David Zambrano
      'bxynonikylujbj4', // Inpimaca Eduardo Piña
      '3nx5fl1krks99vxr2', // Rocío Bertone
      'l6k72k7kynedr4l', // Pharmapiel
      'hkouqb0hksjdttti', // Euro Supermercados
      '4xqa4ngkr0mrb2v', // TU CACHORRO ESPECIALISTA
      '171xem2t0kvct0dkp', // Rincon Curly
      'bxynl6xkzztj4vx', // Atlantica Casas y Prefabricados
      '171xemnhskyk62v52', // Samuel Urquijo
      'hkouqi17kt0kglt8', // Zuled soluciones sas
      'bxynl6xkzztj4vx', // Atlantica Cass y Prefabricados
      'bxynf6aky31feu8', // CargaYa
      'bxyn2bdkvv2ihry', // Salud Dominicana
      'z947ksn1l2grab0v', // Artesanto Cook
      '4xqaf8pkhmcjeqi', // Paso al exito
      'l6k7flvkx7s8rxg', // Educación sin fronteras
      'l6k7uekv41sbwx', // Servitractor
      '4xqajv2koudlvf3', // YT ROCKET'
      '171xem7pikx6c1lg3', // lumni
      '4xqajv2koxarobp', // Distrito cafetero
      '4xqakq7kl8gv1y4', // Ceu te cuida
      '4xqadhlkq2od4ro', // LikeU
      'l6k7kajl0059xke', //  Nova Studio PRUEBA
      'bisovsjfsjn2bifil', // Inexmoda
      '4xqa6y0kql354n4' // All in Travel
    ]

    return selfManagedBotApps
  }
}
