import RollbarFactory from '@/factory/RollbarFactory'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'
import store from '@/store'
import axios from 'axios'
import { CreateFacebookConfigInput, CreateInstagramConfigInput, UpdateFacebookConfigInput, UpdateInstagramConfigInput } from '../Models/ConversationsConfig'

interface IHibotData {
    appUUID: String;
    companyUUID: String;
    channelId: String;
    phone: String;
    country: String;
    enable: Boolean;
    number: String;
}

export default class ConversationsConfigService {
    static conversationsRollbar = RollbarFactory.getInstance('conversations');

    static defaultConversationConfig = {
      authMethod: 'form',
      authOptions: 'none',
      urlBase: '',
      formFields: [],
      linkOfTermsAndConditions: '',
      showTermsAndConditions: false,
      textOfTermsAndConditions: '',
      authTwoFactor: 'onDemand',
      theme: 'light',
      mainColor: '#000000',
      position: 'right',
      logo: '',
      bubbleMessage: '',
      disableMultimedia: false,
      language: 'es',
      welcomeForm: {
        text: '',
        color: '#000000',
        fontSize: 14,
        textAlign: 'left'
      },
      customizedQuestions: [],
      permanentMessages: [],
      finishOptions: [],
      quickResponses: [],
      inactivityMessage: '',
      inactivitySeconds: 0,
      workingHours: {
        message: '',
        customFormTitle: '',
        enable: false,

        blockMessageComposer: false,
        days: [
          {
            code: 0,
            isClosed: false,
            schedules: []
          },
          {
            code: 1,
            isClosed: false,
            schedules: []
          },
          {
            code: 2,
            isClosed: false,
            schedules: []
          },
          {
            code: 3,
            isClosed: false,
            schedules: []
          },
          {
            code: 4,
            isClosed: false,
            schedules: []
          },
          {
            code: 5,
            isClosed: false,
            schedules: []
          },
          {
            code: 6,
            isClosed: false,
            schedules: []
          }
        ]
      },
      timeZone: { label: 'America/Bogota', offset: '−05:00' }
    };

    static async getConversationsConfigV5 () {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/company/${companyId}/app/${appId}/configurations`
      const token = store.getters['UserStore/getToken']

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        return new RestResponseFactory().process({ response })
      } catch (e) {
        this.conversationsRollbar.reportError('Error getting chat config', e)
        return new RestResponseFactory().process({
          response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR }
        })
      }
    }

    static async getConversationsConfig () {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}`
      const v4Token = store.getters['UserStore/getV4Token']
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${v4Token}`
        },
        params: {
          getStatistics: false
        }
      })
      const appInfo = response.data.app

      if (!appInfo) return this.defaultConversationConfig
      if (appInfo.extra_data.length > 0) {
        let chatSettings = appInfo.extra_data.filter(
          (extraData) => extraData.field === 'chat'
        )
        if (!chatSettings[0] || !chatSettings[0].value) {
          return this.defaultConversationConfig
        }
        chatSettings = JSON.parse(chatSettings[0].value)
        if (!chatSettings.finishOptions) chatSettings.finishOptions = []
        if (!chatSettings.quickResponses) chatSettings.quickResponses = []
        if (!chatSettings.welcomeForm) {
          chatSettings.welcomeForm = {
            text: '',
            color: '#000000',
            fontSize: 14,
            textAlign: 'left'
          }
        }
        return chatSettings
      }
      return this.defaultConversationConfig
    }

    static async updateConversationsConfigV5 (data) {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/company/${companyId}/app/${appId}/configurations`
      const token = store.getters['UserStore/getToken']
      try {
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        return new RestResponseFactory().process({ response })
      } catch (e) {
        this.conversationsRollbar.reportError('Error updating chat config', e)
        return new RestResponseFactory().process({
          response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR }
        })
      }
    }

    // Channels functions
    static async getChannelsV5 () {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/company/${companyId}/app/${appId}/configurations/channels`
        const response = await axios.get(url)
        return new RestResponseFactory().process({ response })
      } catch (error) {
        this.conversationsRollbar.reportError('Get Channels error', error)
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    static async getChannels () {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/channels?appUUID=${appId}`
        const response = await axios.get(url)
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    static async getAllChannels () {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/all-channels?appUUID=${appId}`
        const response = await axios.get(url)
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    // Smooch functions
    static async updateSmoochInfo (data) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const url = `${process.env.VUE_APP_V4_CONVERSATIONS_API}configurations/company/${companyId}/app/${appId}/Smooch`
        const v4Token = store.getters['UserStore/getV4Token']
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${v4Token}`
          }
        })
        return response
      } catch (error) {
        this.conversationsRollbar.reportError('Update smooch error', error)
        console.error(error)
      }
    }

    static async createSmoochInfo (data) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const url = `${process.env.VUE_APP_V4_CONVERSATIONS_API}configurations/company/${companyId}/app/${appId}/Smooch`
        const v4Token = store.getters['UserStore/getV4Token']
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${v4Token}`
          }
        })
        return response
      } catch (error) {
        this.conversationsRollbar.reportError('Create smooch error', error)
      }
    }

    static async getSmoochTemplates () {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const url = `${process.env.VUE_APP_V4_CONVERSATIONS_API}configurations/company/${companyId}/app/${appId}/templates-smooch`
        const v4Token = store.getters['UserStore/getV4Token']
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${v4Token}`
          }
        })
        return { templates: response.data.data }
      } catch (error) {
        this.conversationsRollbar.reportError(
          'get smooch templates error',
          error
        )
      }
    }

    static async updateTemplate (data) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const url = `${process.env.VUE_APP_V4_CONVERSATIONS_API}configurations/company/${companyId}/app/${appId}/templates-smooch`
        const v4Token = store.getters['UserStore/getV4Token']
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${v4Token}`
          }
        })
        return { templates: response.data.data }
      } catch (error) {
        this.conversationsRollbar.reportError(
          'Refresh smooch templates error',
          error
        )
      }
    }

    // Hibot Functions
    static async createHibotInfo (data: IHibotData) {
      try {
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/hibot`
        const v4Token = store.getters['UserStore/getV4Token']
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${v4Token}`
          }
        })
        return response
      } catch (error) {
        this.conversationsRollbar.reportError('create hibot error', error)
      }
    }

    static async updateHibotInfo (data: IHibotData) {
      try {
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/hibot`
        const v4Token = store.getters['UserStore/getV4Token']
        const response = await axios.put(url, data, {
          headers: {
            Authorization: `Bearer ${v4Token}`
          }
        })
        return response
      } catch (error) {
        this.conversationsRollbar.reportError('update hibot error', error)
      }
    }

    static async deleteHibotInfo (hibotId: string) {
      try {
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/hibot/${hibotId}`
        const v4Token = store.getters['UserStore/getV4Token']
        const response = await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${v4Token}`
          }
        })
        return response
      } catch (error) {
        this.conversationsRollbar.reportError('delete hibot error', error)
      }
    }

    static async getHibotInfo () {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/hibot/company/${companyId}/app/${appId}`
        const v4Token = store.getters['UserStore/getV4Token']
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${v4Token}`
          }
        })
        return response.data
      } catch (error) {
        this.conversationsRollbar.reportError('get hibot config error', error)
      }
    }

    // Facebook Functions
    static async createFacebookConfig (data: CreateFacebookConfigInput) {
      try {
        const appUUID = data.appUUID || store.getters['AppStore/getSelectedApp']?.uuid
        const companyUUID = data.companyUUID || store.getters['CompanyStore/getSelectedCompany']?.uuid
        const requestUrl = `${process.env.VUE_APP_CONVERSATIONS_URL}/configurations/company/${companyUUID}/app/${appUUID}/channels/facebook-messenger`
        const response = await axios.post(requestUrl, {
          pageId: data.pageId,
          pageAccessToken: data.pageAccessToken
        })
        if (response.data?.data?.error) {
          throw new Error(response.data.message)
        }
      } catch (error) {
        this.conversationsRollbar.reportError('Create facebook error', error)
        console.error(error)
        throw error
      }
    }

    static async updateFacebookConfig (data: UpdateFacebookConfigInput) {
      try {
        const appUUID = data.appUUID || store.getters['AppStore/getSelectedApp']?.uuid
        const companyUUID = data.companyUUID || store.getters['CompanyStore/getSelectedCompany']?.uuid
        const requestUrl = `${process.env.VUE_APP_CONVERSATIONS_URL}/configurations/company/${companyUUID}/app/${appUUID}/channels/facebook-messenger`
        const response = await axios.put(requestUrl, {
          pageId: data.pageId,
          pageAccessToken: data.pageAccessToken
        })
        if (response.data?.data?.error) {
          throw new Error(response.data.message)
        }
      } catch (error) {
        this.conversationsRollbar.reportError('Update Facebook error', error)
        console.error(error)
        throw error
      }
    }

    // Instagram Functions
    static async createInstagramConfig (data: CreateInstagramConfigInput) {
      try {
        const appUUID = data.appUUID || store.getters['AppStore/getSelectedApp']?.uuid
        const companyUUID = data.companyUUID || store.getters['CompanyStore/getSelectedCompany']?.uuid
        const requestUrl = `${process.env.VUE_APP_CONVERSATIONS_URL}/configurations/company/${companyUUID}/app/${appUUID}/channels/instagram`
        const response = await axios.post(requestUrl, {
          instagramId: data.instagramId,
          pageId: data.pageId,
          pageAccessToken: data.pageAccessToken
        })
        if (response.data?.data?.error) {
          throw new Error(response.data.message)
        }
      } catch (error) {
        this.conversationsRollbar.reportError('Create Instagram error', error)
        console.error(error)
        throw error
      }
    }

    static async updateInstagramConfig (data: UpdateInstagramConfigInput) {
      try {
        const appUUID = data.appUUID || store.getters['AppStore/getSelectedApp']?.uuid
        const companyUUID = data.companyUUID || store.getters['CompanyStore/getSelectedCompany']?.uuid
        const requestUrl = `${process.env.VUE_APP_CONVERSATIONS_URL}/configurations/company/${companyUUID}/app/${appUUID}/channels/instagram`
        const response = await axios.put(requestUrl, {
          instagramId: data.instagramId,
          pageId: data.pageId,
          pageAccessToken: data.pageAccessToken
        })
        if (response.data?.data?.error) {
          throw new Error(response.data.message)
        }
      } catch (error) {
        this.conversationsRollbar.reportError('Update Instagram error', error)
        console.error(error)
        throw error
      }
    }

    // Bots Functions
    static async getBotsConfigInfo () {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/company/${companyId}/app/${appId}/bot-configuration`
        const v4Token = store.getters['UserStore/getV4Token']
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${v4Token}`
          }
        })
        return response.data
      } catch (error) {
        this.conversationsRollbar.reportError('Get Bots Config error', error)
      }
    }

    static async setBotsConfigInfo (data) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/company/${companyId}/app/${appId}/bot-configuration/setWatsonBotData`
        const v4Token = store.getters['UserStore/getV4Token']
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${v4Token}`,
            'Content-Type': 'application/json'
          }
        })
        return response
      } catch (e) {
        this.conversationsRollbar.reportError('Set Bots Config error', e)
        console.error(e)
      }
    }

    static async restoreWatsonContext () {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/company/${companyId}/app/${appId}/bot-configuration/watson-context`
        const token = store.getters['UserStore/getToken']
        const response = await axios.delete(
          url,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        return new RestResponseFactory().process({ response })
      } catch (e) {
        this.conversationsRollbar.reportError('Restore watson context error', e)
        return new RestResponseFactory().process({
          response: {
            status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR,
            message: e.message
          }
        })
      }
    }

    static async saveWhatsappAccessToken (whatsappResponse) {
      try {
        const appUUID = store.getters['AppStore/getSelectedApp']?.uuid
        const companyUUID = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/whatsapp`
        const token = store.getters['UserStore/getToken']
        const response = await axios.post(
          url, {
            whatsappResponse: whatsappResponse,
            appUUID,
            companyUUID
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        return new RestResponseFactory().process({ response })
      } catch (error) {
        this.conversationsRollbar.reportError('delete facebook error', error)
      }
    }

    static async getQuickResponses () {
      // curl --location --request GET 'https://chat.qakbe.com/config/quickResponse?appUUID=4xqa4b8khxrofef&userId=KW4ZEG'
      const appUUID = store.getters['AppStore/getSelectedApp']?.uuid
      const userId = store.getters['UserStore/getUser']?.userId
      const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/quickResponse?appUUID=${appUUID}&userId=${userId}`
      try {
        const token = store.getters['UserStore/getToken']
        const response = await axios.get(
          url,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({
          response: {
            status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR
          }
        })
      }
    }

    static async saveQuickResponse (data) {
      const appUUID = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/quickResponse`
      const { name, value, userId, type, mimetype } = data
      try {
        const token = store.getters['UserStore/getToken']
        const response = await axios.post(
          url, {
            appUUID,
            name,
            value,
            userId,
            type,
            mimetype
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({
          response: {
            status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR
          }
        })
      }
    }

    static async deleteQuickResponse (data) {
      const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/quickResponse`
      const { _id } = data
      const appUUID = store.getters['AppStore/getSelectedApp']?.uuid
      try {
        const token = store.getters['UserStore/getToken']
        const response = await fetch(
          url, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
              quickResponseId: _id,
              appUUID
            })
          })
        const data = await response.json()
        console.log(data)
        return new RestResponseFactory().process({ response: { ...data, status: data.statusCode } })
      } catch (error) {
        return new RestResponseFactory().process({
          response: {
            status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR
          }
        })
      }
    }
}
