<template>
  <div id="InviteYourTeamScreen" class="InviteYourTeamScreen">
    <SuccessModal v-if="showSuccess" @close="showSuccess=false"/>
    <div id="InviteYourTeamScreen-HeaderRow" class="InviteYourTeamScreen-HeaderRow">
      <div @click="$router.push(link.onboarding)" class="cursor-pointer">
        <v-icon>mdi-chevron-left</v-icon>
        <span>{{ $t('howStart') }}</span>
      </div>
    </div>
    <div id="InviteYourTeamScreen-TitleContainer" class="InviteYourTeamScreen-TitleContainer">
      <img
        :src="personIcon"
        width="45px"
        height="45px"
        alt=""
        id="InviteYourTeamScreen-TitleContainer-img"/>
      <h3 id="InviteYourTeamScreen-TitleContainer-title">{{ `${$t('inviteTeamTitle1')} ${plan}${$t('inviteTeamTitle2')} ${limit} ${$t('inviteTeamTitle3')}` }}</h3>
      <span id="InviteYourTeamScreen-TitleContainer-subtitle">{{ $t('inviteTeamSubtitle') }}</span>
    </div>
    <div id="InviteYourTeamScreen-inputContainer" class="InviteYourTeamScreen-inputContainer">
      <KeybeTextfield
        :placeholder="$t('inviteMailPlaceholder')"
        v-model="email"
        :color="'white'"
        id="InviteYourTeamScreen-inputContainer-input"
        :incorrect="isInvalidEmail"
      />
    </div>
    <div id="InviteYourTeamScreen-UserList" class="InviteYourTeamScreen-UserList">
      <div class="InviteYourTeamScreen-UserList-item" v-for="(user, index) in users" :key="`user ${index}`">
        <RoleSelector :user="user"/>
      </div>
    </div>
    <KeybeButton
      :text="$t('add')"
      @click.native="addUser"
      color="blue"
      force-width="200px"
      id="InviteYourTeamScreen-submitButton"
    />
    <KeybeButton
      v-show="users.length > 0"
      :text="$t('sendInvitations')"
      @click.native="sendInvitations"
      color="blue"
      class="mt-6"
      force-width="200px"
      id="InviteYourTeamScreen-sendInvitations"
    />
  </div>
</template>

<script>
import KeybeButton from '@/components/shared/components/KeybeButton'
import KeybeTextfield from '@/components/shared/components/KeybeTextfield'
import RoleSelector from '@/components/Onboarding/Components/RoleSelector'
import link from '@/utils/links'
import { RolesEnum } from '@/Enums/RolesEnum'
import SuccessModal from '@/components/Onboarding/Components/SuccessModal'

export default {
  name: 'InviteYourTeamScreen.vue',
  components: {
    SuccessModal,
    KeybeButton,
    KeybeTextfield,
    RoleSelector
  },
  data () {
    return {
      personIcon: `${process.env.VUE_APP_ASSETS_URL}/common/person-active.svg`,
      plan: 'Essential',
      limit: 3,
      email: '',
      users: [],
      link,
      showSuccess: false
    }
  },
  computed: {
    isInvalidEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!this.email.length) {
        return false
      }
      return !(this.email.length && re.test(this.email))
    }
  },
  methods: {
    addUser () {
      if (!this.isInvalidEmail && this.email) {
        this.users.push({
          email: this.email,
          role: RolesEnum.ADVISER
        })
        this.email = ''
      }
    },
    sendInvitations () {
      this.showSuccess = true
      console.log('send invitations', this.users)
    }
  }
}
</script>

<style scoped lang="scss">
.InviteYourTeamScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.InviteYourTeamScreen-HeaderRow {
  width: 100%;
  max-width: 1512px;
  display: flex;
  align-items: center;
  padding: 2rem 1rem 1rem;
}

.InviteYourTeamScreen-TitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1512px;

  img {
    margin: 2rem 0;
  }
}

.InviteYourTeamScreen-inputContainer {
  border-radius: 20px;
  width: 340px;
  height: 40px;
  margin: 2rem 0;

  input {
    width: 100%;
    height: 100%;
  }
}
</style>
