import axios from 'axios'
import store from '@/store'
import { Room } from 'src/components/models/Room'
import { Template } from 'src/components/models/Template'
import { Message } from 'src/components/models/Message'
import { User } from 'src/components/models/User'
import { AppInfo } from 'src/components/models/App'
import { FilterParams } from 'src/components/models/FilterParams'
import { IWpProvider } from '@/components/conversations/models/WpProviders'
import { Channels } from '@/components/conversations/enums/Channels'
import { RoomStates } from '@/components/conversations/enums/RoomStates'
import { NotificationsSoundKeys } from '@/components/conversations/enums/NotificationsSoundKeys'
// import { EventBus } from '@/eventBus'

export const ConversationStore = {
  namespaced: true,
  state: {
    rooms: [],
    totalRooms: 0,
    wpProviders: [],
    templates: [],
    selectedRoom: {},
    messages: [],
    loadingUser: false,
    selectedUser: {},
    pictures: [],
    loadingRooms: false,
    loadingMessages: false,
    conversationsAppInfo: {},
    oneSignalUserId: '',
    oneSignalRegistered: false,
    pinnedRooms: [],
    filterParams: {
      quickFilterSelected: true,
      allConversations: false,
      onlySelfConversations: true,
      conversationsWithoutAdvisor: true,
      conversationsWithBot: false,
      wizardFilter: true,
      endedConversations: false,
      whatsappFilter: true,
      facebookFilter: true,
      webFilter: true,
      instagramFilter: true,
      olderFirst: false,
      newerFirst: true,
      closedByAi: false,
      closedByAdvisor: false,
      unanswered: false,
      favorites: false,
      advisorId: [],
      channel: [Channels.Hibot, Channels.Smooch, Channels.Gupshup, Channels.KeybeWp, Channels.Facebook, Channels.Instagram, Channels.Web, Channels.Wizard],
      state: [RoomStates.ReadyForAdvisor],
      owner: '',
      customId: '',
      advisers: [],
      internal: '',
      order: -1,
      orderBy: 'updatedAt',
      offset: 0,
      size: 15,
      search: ''
    },
    channels: {},
    sound: true,
    errorLoadingRooms: false,
    autoassignConversationsLimit: 0,
    showTestModal: false,
    selectedRooms: [],
    firstWhatsappSent: false,
    instagramConfig: null,
    facebookConfig: null,
    opportunitiesMessages: [],
    filtersTutorialCompleted: true,
    lastRequestDataMessage: {
      roomId: '',
      message: ''
    },
    darkModeTutorialCompleted: true,
    selectedTone: NotificationsSoundKeys.KB_CLASSIC,
    sentimentalAnalysisEnabled: false,
    multipleExtradataEditTutorialCompleted: false,
    keybeIntelTutorial: false,
    configsTutorial: false,
    actionSuggestionsEnabled: false,
    messageSuggestionsEnabled: false,
    instagramFixDialog: true,
    hasWhatsappProvider: false,
    webinarPopup: true,
    webinarPopUpCounter: 0,
    temporalWebinarPopup: true,
    canGetMoreRooms: true
  },
  mutations: {
    SET_TEST_MODAL_SHOW (state, showModal: Boolean) {
      state.showTestModal = showModal
    },
    SET_SELECTED_ROOM (state, room: Room | null) {
      state.selectedRoom = room
    },
    SET_ROOMS (state, rooms: Room[]) {
      state.rooms = []
      state.rooms = rooms
    },
    SET_MESSAGES (state, messages: Message[]) {
      state.messages = []
      state.messages = messages
    },
    ADD_MESSAGE (state, message: Message) {
      state.messages.push(message)
    },
    UPDATE_MESSAGE (state, message: Message) {
      const messageIndex = state.messages.findIndex((m) => m._id === message._id)
      const updatedMessage = { ...state.messages[messageIndex], ...message }
      state.messages.splice(messageIndex, 1, updatedMessage)
    },
    UPDATE_ROOM (state, { room, order }: { room: Room, order: number }) {
      const roomIndex = state.rooms.findIndex((r) => r?._id === room?._id)
      if (order === -1) {
        state.rooms.splice(roomIndex, 1) // delete the room in the current position
        state.rooms.splice(0, 0, room) // insert the room in the index 0
      } else {
        state.rooms.splice(roomIndex, 1, room)
      }
    },
    UPDATE_LOCAL_ROOM (state, room: Room) {
      const roomIndex = state.rooms.findIndex((r) => r?._id === room?._id)
      if (roomIndex > -1) {
        state.rooms.splice(roomIndex, 1, room)
      }
    },
    ADD_ROOM (state, { room, order }: { room: Room, order: number }) {
      if (order === -1) {
        state.rooms.splice(0, 0, room)
      } else {
        state.rooms.push(room)
      }
    },
    REMOVE_ROOM (state, room: Room) {
      const roomIndex = state.rooms.findIndex((r) => r._id === room._id)
      state.rooms.splice(roomIndex, 1) // delete the room in the current position
    },
    UNSELECT_ROOM (state, room: Room) {
      const roomIndex = state.rooms.findIndex((r) => r._id === room._id)
      state.rooms.splice(roomIndex, 1, { ...room, isSelected: false })
    },
    SET_TEMPLATES (state, templates: Template[]) {
      state.templates = []
      state.templates = templates
    },
    SET_WP_PROVIDERS (state, wpProviders) {
      state.wpProviders = wpProviders
    },
    SET_LOADING_USER (state, loadingUser: boolean) {
      state.loadingUser = loadingUser
    },
    SET_SELECTED_USER (state, selectedUser: User) {
      state.selectedUser = selectedUser
    },
    SET_LOADING_ROOMS (state, loadingRooms: boolean) {
      state.loadingRooms = loadingRooms
    },
    SET_TOTAL_ROOMS (state, totalRooms: number) {
      state.totalRooms = totalRooms
    },
    SET_LOADING_MESSAGES (state, loadingMessages) {
      state.loadingMessages = loadingMessages
    },
    SET_CONVERSATIONS_APP_INFO (state, conversationsAppConfig: AppInfo) {
      state.conversationsAppInfo = conversationsAppConfig
    },
    SET_ONE_SIGNAL_USER_ID (state, oneSignalUserId: string) {
      state.oneSignalUserId = oneSignalUserId
    },
    SET_ONE_SIGNAL_REGISTERED (state, oneSignalRegistered: boolean) {
      state.oneSignalRegistered = oneSignalRegistered
    },
    SET_PINNED_ROOMS (state, pinnedRooms: Room[]) {
      state.pinnedRooms = pinnedRooms
    },
    SET_FILTER_PARAMS (state, filterParams: FilterParams) {
      state.filterParams = filterParams
    },
    SET_SOUND (state, sound: boolean) {
      state.sound = sound
    },
    SET_CHANNELS (state, channels) {
      state.channels = channels
    },
    SET_ERROR_LOADING_ROOMS (state, errorLoadingRooms: boolean) {
      state.errorLoadingRooms = errorLoadingRooms
    },
    ADD_SELECTED_ROOM (state, room: Room) {
      state.selectedRooms.push(room)
    },
    REMOVE_SELECTED_ROOM (state, room: Room) {
      const roomIndex = state.selectedRooms.findIndex((r) => r._id === room._id)
      state.selectedRooms.splice(roomIndex, 1)
    },
    CLEAR_SELECTED_ROOMS (state) {
      state.selectedRooms = []
    },
    SET_AUTOASSIGN_CONVERSATIONS_LIMIT (state, autoassignConversationsLimit: number) {
      state.autoassignConversationsLimit = autoassignConversationsLimit
    },
    SET_FIRST_WHATSAPP_SENT (state, firstWhatsappSent: boolean) {
      state.firstWhatsappSent = firstWhatsappSent
    },
    SET_INSTAGRAM_CONFIG (state, config) {
      state.instagramConfig = config
    },
    SET_FACEBOOK_CONFIG (state, config) {
      state.facebookConfig = config
    },
    SET_FILTERS_TUTORIAL_COMPLETED (state, filtersTutorialCompleted: boolean) {
      state.filtersTutorialCompleted = filtersTutorialCompleted
    },
    SET_DARK_MODE_TUTORIAL_COMPLETED (state, darkModeTutorialCompleted: boolean) {
      state.darkModeTutorialCompleted = darkModeTutorialCompleted
    },
    SET_MULTIPLE_EXTRADATA_EDIT_TUTORIAL_COMPLETED (state, multipleExtraDataEditTutorialCompleted: boolean) {
      state.multipleExtradataEditTutorialCompleted = multipleExtraDataEditTutorialCompleted
    },
    SET_ACTION_SUGGESTIONS_ENABLED (state, actionSuggestionsEnabled: boolean) {
      state.actionSuggestionsEnabled = actionSuggestionsEnabled
    },
    SET_MESSAGE_SUGGESTIONS_ENABLED (state, messageSuggestionsEnabled: boolean) {
      state.messageSuggestionsEnabled = messageSuggestionsEnabled
    },
    CLEAR_CONVERSATION_STATE (state) {
      state.errorLoadingRooms = false
      state.rooms = []
      state.wpProviders = []
      state.templates = []
      state.selectedRoom = {}
      state.messages = []
      state.loadingUser = false
      state.selectedUser = {}
      state.pictures = []
      state.loadingRooms = false
      state.loadingMessages = false
      state.conversationsAppInfo = {}
      state.oneSignalRegistered = false
      state.pinnedRooms = []
      state.filterParams = {
        allConversations: false,
        onlySelfConversations: true,
        conversationsWithoutAdvisor: true,
        conversationsWithBot: false,
        endedConversations: false,
        whatsappFilter: true,
        facebookFilter: true,
        webFilter: true,
        instagramFilter: true,
        closedByAi: false,
        wizardFilter: true,
        olderFirst: false,
        newerFirst: true,
        advisorId: [],
        channel: [Channels.Hibot, Channels.Smooch, Channels.Gupshup, Channels.KeybeWp, Channels.Facebook, Channels.Instagram, Channels.Web, Channels.Wizard],
        state: ['readyForAdvisor'],
        owner: '',
        customId: '',
        search: '',
        advisers: [],
        internal: '',
        unanswered: false,
        order: -1,
        orderBy: 'updatedAt'
      }
      state.sound = true
      state.channels = {}
      state.selectedRooms = []
      state.autoassignConversationsLimit = 0
      state.instagramConfig = null
      state.facebookConfig = null
      state.instagramFixDialog = true
      state.hasWhatsappProvider = false
    },
    ADD_OPPORTUNITY_MESSAGE (state, opportunity) {
      if (!state.opportunitiesMessages) {
        state.opportunitiesMessages = [opportunity]
      }
      state.opportunitiesMessages.push(opportunity)
    },
    UPDATE_SELECTED_ROOM (state, room: Room) {
      const newRoom = {
        ...state.selectedRoom,
        ...room
      }
      state.selectedRoom = newRoom
    },
    SET_LAST_REQUEST_DATA_MESSAGE (state, lastRequestDataMessage) {
      state.lastRequestDataMessage = lastRequestDataMessage
    },
    SET_SELECTED_TONE (state, selectedTone) {
      state.selectedTone = selectedTone
    },
    SET_SENTIMENTAL_ANALYSIS_ENABLED (state, value) {
      state.sentimentalAnalysisEnabled = value
    },
    SET_KEYBE_INTEL_TUTORIAL (state, value) {
      state.keybeIntelTutorial = value
    },
    SET_CONFIGS_TUTORIAL (state, value) {
      state.configsTutorial = value
    },
    SET_INSTAGRAM_FIX_DIALOG (state: any, value: boolean) {
      state.instagramFixDialog = value
    },
    SET_HAS_WHATSAPP_PROVIDER (state: any, value: boolean) {
      state.hasWhatsappProvider = value
    },
    SET_WEBINAR_POPUP (state, value) {
      state.webinarPopup = value
    },
    SET_WEBINAR_COUNTER (state, value) {
      state.webinarPopUpCounter = value
    },
    SET_TEMPORAL_POPUP (state, value) {
      state.temporalWebinarPopup = value
    },
    SET_CAN_GET_MORE_ROOMS (state: any, value: boolean) {
      state.canGetMoreRooms = value
    }
  },
  actions: {
    selectRoom (context, room: Room) {
      context.dispatch('setMessages', [])
      context.commit('SET_SELECTED_ROOM', room)
    },
    setTemplates ({ commit }, templates: Template[]) {
      commit('SET_TEMPLATES', templates)
    },
    setRooms ({ commit }, rooms: Room[]) {
      commit('SET_ROOMS', rooms)
    },
    setMessages ({ commit }, messages: Message[]) {
      commit('SET_MESSAGES', messages)
    },
    setHasWhatsappProvider ({ commit }, value: boolean) {
      commit('SET_HAS_WHATSAPP_PROVIDER', value)
    },
    setWpProviders ({ commit }, wpProviders: IWpProvider[]) {
      commit('SET_WP_PROVIDERS', wpProviders)
    },
    addMessage (context, message: Message) {
      if (
        !context.state.messages.find((m) => m._id === message._id) &&
                message.roomId === context.state.selectedRoom._id
      ) {
        context.commit('ADD_MESSAGE', message)
      }
    },
    updateMessage (context, message: Message) {
      if (
        context.state.messages.findIndex((m) => m?._id === message?._id) &&
                message?.roomId === context.state.selectedRoom?._id
      ) {
        context.commit('UPDATE_MESSAGE', message)
      }
    },
    updateRoom (context, data: { room: Room, order: number }) {
      const room = data.room
      const roomIndex = context.state.rooms.findIndex((r) => r?._id === room?._id)
      if (roomIndex >= 0) {
        const updatedAtChanged = context.state.rooms[roomIndex]?.updatedAt !== room?.updatedAt
        if (updatedAtChanged) {
          context.commit('UPDATE_ROOM', data)
        } else {
          context.commit('UPDATE_LOCAL_ROOM', room)
        }
      } else {
        context.commit('ADD_ROOM', data)
      }
    },
    addRoom (context, data: { room: Room, order: number }) {
      const room = data.room
      if (
        !context.state.rooms.find((r) => r._id === room._id)
      ) {
        context.commit('ADD_ROOM', data)
        context.commit('SET_TOTAL_ROOMS', context.state.totalRooms + 1)
      }
    },
    removeRoom (context, room: Room) {
      const roomsExist = context.state?.rooms?.findIndex((r) => r?._id === room?._id) >= 0
      if (roomsExist) {
        context.commit('REMOVE_ROOM', room)
        context.commit('SET_TOTAL_ROOMS', context.state?.totalRooms - 1)
      }
    },
    unselectRoom ({ commit, state }) {
      const selectedRooms = state.rooms.filter((c) => c.isSelected === true)
      for (const room of selectedRooms) {
        commit('UNSELECT_ROOM', room)
      }
      commit('SET_SELECTED_ROOM', undefined)
    },
    cleanRoomsList ({ commit }) {
      commit('SET_ROOMS', [])
      commit('SET_TOTAL_ROOMS', 0)
      commit('SET_LOADING_ROOMS', false)
      commit('SET_PINNED_ROOMS', [])
      commit('CLEAR_SELECTED_ROOMS')
    },
    async getUserDetails (context, { userId, fromChat }) {
      try {
        context.commit('SET_LOADING_USER', true)
        const appId = context.rootState.AppStore.selectedApp.uuid
        const companyId = context.rootState.CompanyStore.selectedCompany.UUID
        const token = context.rootState.UserStore.state.v4Token
        const response = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/users/${userId}`,
                    {
                      headers: {
                        Authorization: 'Bearer ' + token
                      },
                      params: {
                        entryPoint: fromChat
                      }
                    }
        )
        context.commit('SET_SELECTED_USER', response.data.user)
        context.commit('SET_LOADING_USER', false)
        return response.data.user
      } catch (e) {
        console.error(e)
        context.commit('SET_SELECTED_USER', {})
        context.commit('SET_LOADING_USER', false)
      }
    },
    async getUserLists (context, data) {
      try {
        const appId = context.rootState.AppStore.selectedApp.uuid
        const companyId = context.rootState.CompanyStore.selectedCompany.uuid
        const token = context.rootState.UserStore.v4Token
        const { userId, limit, page } = data

        const response = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/users/${userId}/lists?page=${page}&&limit=${limit}`,
                    {
                      headers: {
                        Authorization: 'Bearer ' + token
                      }
                    }
        )
        return response.data.lists.lists
      } catch (e) {
        console.error(e)
      }
    },
    async updateUserExtradata (context, data) {
      try {
        const appId = context.rootState.AppStore.selectedApp.uuid
        const companyId = context.rootState.CompanyStore.selectedCompany.uuid
        const token = context.rootState.UserStore.v4Token
        const userId = data.userId
        const extra = data.extradata
        const response = await axios.put(
                    `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/users/${userId}`,
                    {
                      extradata: extra
                    },
                    {
                      headers: {
                        Authorization: 'Bearer ' + token
                      }
                    }
        )
        return response.data
      } catch (e) {
        console.error(e)
      }
    },
    setOneSignalUserId (context, oneSignalUserId: string) {
      context.commit('SET_ONE_SIGNAL_USER_ID', oneSignalUserId)
    },
    // Filter Actions
    setDefaultFilters (context) {
      const newFilterParams = {
        quickFilterSelected: true,
        allConversations: false,
        onlySelfConversations: true,
        conversationsWithoutAdvisor: false,
        attendingConversations: false,
        conversationsWithBot: false,
        endedConversations: false,
        whatsappFilter: true,
        facebookFilter: true,
        wizardFilter: true,
        closedByAi: false,
        closedByAdvisor: false,
        webFilter: true,
        instagramFilter: true,
        olderFirst: false,
        newerFirst: true,
        advisorId: [],
        channel: [Channels.Hibot, Channels.Smooch, Channels.Gupshup, Channels.KeybeWp, Channels.Facebook, Channels.Instagram, Channels.Web, Channels.Wizard],
        state: [],
        internal: '',
        unanswered: false,
        favorites: false,
        owner: context.rootState.UserStore.user.userId,
        customId: context.rootState.UserStore.user.customId || '',
        advisers: context.rootState.AppStore.advisers.map((adviser) => {
          adviser.value = false
          return adviser
        }),
        order: -1,
        orderBy: 'updatedAt',
        offset: 0,
        size: 15,
        search: ''
      }
      context.dispatch('setFilterParams', newFilterParams)
    },
    setTeamFilter (context) {
      const newFilterParams = {
        ...context.state.filterParams,
        quickFilterSelected: false,
        allConversations: false,
        onlySelfConversations: false,
        conversationsWithoutAdvisor: false,
        attendingConversations: false,
        conversationsWithBot: false,
        endedConversations: false,
        closedByAi: false,
        closedByAdvisor: false,
        wizardFilter: false,
        whatsappFilter: false,
        facebookFilter: false,
        webFilter: false,
        instagramFilter: false,
        olderFirst: false,
        newerFirst: true,
        unanswered: false,
        favorites: false,
        advisorId: [],
        channel: [Channels.Keybe],
        state: [],
        internal: store.getters['UserStore/getUser']?.userId,
        owner: '',
        customId: '',
        advisers: context.rootState.AppStore.advisers.map((adviser) => {
          adviser.value = false
          return adviser
        }),
        order: -1,
        orderBy: 'updatedAt',
        offset: 0,
        size: 15
      }
      context.dispatch('setFilterParams', newFilterParams)
    },
    setAllConversationsFilter (context) {
      const newFilterParams = {
        ...context.state.filterParams,
        quickFilterSelected: false,
        allConversations: true,
        onlySelfConversations: false,
        conversationsWithoutAdvisor: false,
        attendingConversations: false,
        conversationsWithBot: false,
        endedConversations: false,
        closedByAi: false,
        closedByAdvisor: false,
        wizardFilter: true,
        whatsappFilter: true,
        facebookFilter: true,
        webFilter: true,
        instagramFilter: true,
        olderFirst: false,
        unanswered: false,
        favorites: false,
        newerFirst: true,
        advisorId: [],
        channel: [Channels.Hibot, Channels.Smooch, Channels.Gupshup, Channels.KeybeWp, Channels.Facebook, Channels.Instagram, Channels.Web, Channels.Wizard],
        state: [],
        internal: '',
        owner: '',
        customId: '',
        advisers: context.rootState.AppStore.advisers.map((adviser) => {
          adviser.value = false
          return adviser
        }),
        order: -1,
        orderBy: 'updatedAt',
        offset: 0,
        size: 15
      }
      context.dispatch('setFilterParams', newFilterParams)
    },
    setQuickAllConversationsFilter (context) {
      const newFilterParams = {
        ...context.state.filterParams,
        quickFilterSelected: true,
        allConversations: true,
        onlySelfConversations: false,
        conversationsWithoutAdvisor: false,
        attendingConversations: false,
        conversationsWithBot: false,
        endedConversations: false,
        wizardFilter: true,
        whatsappFilter: true,
        facebookFilter: true,
        webFilter: true,
        instagramFilter: true,
        closedByAi: false,
        closedByAdvisor: false,
        olderFirst: false,
        newerFirst: true,
        unanswered: false,
        favorites: false,
        advisorId: [],
        channel: [Channels.Hibot, Channels.Smooch, Channels.Gupshup, Channels.KeybeWp, Channels.Facebook, Channels.Instagram, Channels.Web, Channels.Wizard],
        state: [],
        internal: '',
        owner: '',
        customId: '',
        advisers: context.rootState.AppStore.advisers.map((adviser) => {
          adviser.value = false
          return adviser
        }),
        order: -1,
        orderBy: 'updatedAt',
        offset: 0,
        size: 15
      }
      context.dispatch('setFilterParams', newFilterParams)
    },
    setOnlySelfConversationsFilter (context) {
      const newFilterParams = {
        allConversations: false,
        quickFilterSelected: true,
        onlySelfConversations: true,
        conversationsWithoutAdvisor: false,
        attendingConversations: false,
        conversationsWithBot: false,
        endedConversations: false,
        whatsappFilter: true,
        facebookFilter: true,
        wizardFilter: true,
        webFilter: true,
        instagramFilter: true,
        unanswered: false,
        favorites: false,
        closedByAdvisor: false,
        closedByAi: false,
        olderFirst: false,
        newerFirst: true,
        advisorId: [],
        channel: [Channels.Hibot, Channels.Smooch, Channels.Gupshup, Channels.KeybeWp, Channels.Facebook, Channels.Instagram, Channels.Web, Channels.Wizard],
        state: [],
        internal: '',
        owner: context.rootState.UserStore.user.userId,
        customId: context.rootState.UserStore.user.customId || '',
        advisers: context.rootState.AppStore.advisers.map((adviser) => {
          adviser.value = false
          return adviser
        }),
        order: -1,
        orderBy: 'updatedAt',
        offset: 0,
        size: 15,
        search: ''
      }
      context.dispatch('setFilterParams', newFilterParams)
    },
    setWaitingForAdvisorFilter (context) {
      const newFilterParams = {
        quickFilterSelected: true,
        allConversations: false,
        onlySelfConversations: false,
        conversationsWithoutAdvisor: true,
        attendingConversations: false,
        conversationsWithBot: false,
        endedConversations: false,
        whatsappFilter: true,
        facebookFilter: true,
        wizardFilter: true,
        webFilter: true,
        instagramFilter: true,
        closedByAi: false,
        unanswered: false,
        favorites: false,
        closedByAdvisor: false,
        olderFirst: false,
        newerFirst: true,
        advisorId: [],
        channel: [Channels.Hibot, Channels.Smooch, Channels.Gupshup, Channels.KeybeWp, Channels.Facebook, Channels.Instagram, Channels.Web, Channels.Wizard],
        state: ['readyForAdvisor'],
        internal: '',
        owner: '',
        customId: '',
        advisers: context.rootState.AppStore.advisers.map((adviser) => {
          adviser.value = false
          return adviser
        }),
        order: -1,
        orderBy: 'updatedAt',
        offset: 0,
        size: 15,
        search: ''
      }
      context.dispatch('setFilterParams', newFilterParams)
    },
    setClosedByAiFilter (context) {
      const newFilterParams = {
        quickFilterSelected: true,
        allConversations: false,
        onlySelfConversations: false,
        conversationsWithoutAdvisor: false,
        attendingConversations: false,
        conversationsWithBot: false,
        endedConversations: false,
        whatsappFilter: true,
        facebookFilter: true,
        wizardFilter: true,
        webFilter: true,
        instagramFilter: true,
        unanswered: false,
        favorites: false,
        closedByAi: true,
        closedByAdvisor: false,
        olderFirst: false,
        newerFirst: true,
        advisorId: [],
        channel: [Channels.Hibot, Channels.Smooch, Channels.Gupshup, Channels.KeybeWp, Channels.Facebook, Channels.Instagram, Channels.Web, Channels.Wizard],
        state: ['closed'],
        internal: '',
        owner: '',
        customId: '',
        advisers: context.rootState.AppStore.advisers.map((adviser) => {
          adviser.value = false
          return adviser
        }),
        order: -1,
        orderBy: 'updatedAt',
        offset: 0,
        size: 15,
        search: ''
      }
      context.dispatch('setFilterParams', newFilterParams)
    },
    setClosedByAdvisorFilter (context) {
      const newFilterParams = {
        quickFilterSelected: true,
        allConversations: false,
        onlySelfConversations: false,
        conversationsWithoutAdvisor: false,
        attendingConversations: false,
        conversationsWithBot: false,
        endedConversations: false,
        whatsappFilter: true,
        facebookFilter: true,
        wizardFilter: true,
        webFilter: true,
        instagramFilter: true,
        closedByAi: false,
        closedByAdvisor: true,
        olderFirst: false,
        newerFirst: true,
        unanswered: false,
        favorites: false,
        advisorId: [],
        channel: [Channels.Hibot, Channels.Smooch, Channels.Gupshup, Channels.KeybeWp, Channels.Facebook, Channels.Instagram, Channels.Web, Channels.Wizard],
        state: ['closed'],
        internal: '',
        owner: '',
        customId: '',
        advisers: context.rootState.AppStore.advisers.map((adviser) => {
          adviser.value = false
          return adviser
        }
        ),
        order: -1,
        orderBy: 'updatedAt',
        offset: 0,
        size: 15,
        search: ''
      }
      context.dispatch('setFilterParams', newFilterParams)
    },
    setFavoritesConversationsFilter (context) {
      const newFilterParams = {
        quickFilterSelected: true,
        allConversations: false,
        onlySelfConversations: false,
        conversationsWithoutAdvisor: false,
        attendingConversations: false,
        conversationsWithBot: false,
        endedConversations: false,
        whatsappFilter: true,
        facebookFilter: true,
        wizardFilter: true,
        webFilter: true,
        instagramFilter: true,
        closedByAi: false,
        closedByAdvisor: false,
        olderFirst: false,
        newerFirst: true,
        unanswered: false,
        favorites: true,
        advisorId: [],
        channel: [],
        state: [],
        internal: '',
        owner: '',
        customId: '',
        advisers: [],
        order: -1,
        orderBy: 'updatedAt',
        offset: 0,
        size: 15,
        search: ''
      }
      context.dispatch('setFilterParams', newFilterParams)
    },
    setFilterParams (context, filterParams: FilterParams) {
      context.commit('SET_FILTER_PARAMS', filterParams)
    },
    setSound (context, sound) {
      context.commit('SET_SOUND', sound)
    },
    setChannels (context, channels) {
      context.commit('SET_CHANNELS', channels)
    },
    clearConversationState (context) {
      context.commit('CLEAR_CONVERSATION_STATE')
    },
    setErrorLoadingRooms (context, error) {
      context.commit('SET_ERROR_LOADING_ROOMS', error)
    },
    addSelectedRoom (context, room) {
      context.commit('ADD_SELECTED_ROOM', room)
    },
    removeSelectedRoom (context, room) {
      context.commit('REMOVE_SELECTED_ROOM', room)
    },
    clearSelectedRooms (context) {
      context.commit('CLEAR_SELECTED_ROOMS')
    },
    cleanTemporalData (context) {
      context.commit('SET_SELECTED_ROOM', [])
      context.commit('SET_PINNED_ROOMS', [])
      context.commit('SET_SELECTED_ROOM', null)
      context.commit('SET_MESSAGES', [])
      context.commit('SET_WP_PROVIDERS', [])
      context.commit('SET_TEMPLATES', [])
      context.commit('SET_TOTAL_ROOMS', 0)
      context.commit('CLEAR_SELECTED_ROOMS')
    },
    setAutoassignConversationsLimit (context, limit) {
      context.commit('SET_AUTOASSIGN_CONVERSATIONS_LIMIT', limit)
    },
    setFirstWhatsappSent (context, firstWhatsappSent) {
      context.commit('SET_FIRST_WHATSAPP_SENT', firstWhatsappSent)
    },
    setOneSignalRegistered (context, value) {
      context.commit('SET_ONE_SIGNAL_REGISTERED', value)
    },
    setInstagramConfig (context, value) {
      context.commit('SET_INSTAGRAM_CONFIG', value)
    },
    setFacebookConfig (context, value) {
      context.commit('SET_FACEBOOK_CONFIG', value)
    },
    updateSelectedRoom (context, room) {
      context.commit('UPDATE_SELECTED_ROOM', room)
    },
    updateLocalRoom (context, room) {
      context.commit('UPDATE_LOCAL_ROOM', room)
    },
    setFiltersTutorialCompleted (context, value) {
      context.commit('SET_FILTERS_TUTORIAL_COMPLETED', value)
    },
    setDarkModeTutorialCompleted (context, value) {
      context.commit('SET_DARK_MODE_TUTORIAL_COMPLETED', value)
    },
    setSelectedTone (context, value) {
      context.commit('SET_SELECTED_TONE', value)
    },
    setMultipleExtradataEditTutorialCompleted (context, value) {
      context.commit('SET_MULTIPLE_EXTRADATA_EDIT_TUTORIAL_COMPLETED', value)
    },
    setSentimentalAnalysisEnabled (context, value) {
      context.commit('SET_SENTIMENTAL_ANALYSIS_ENABLED', value)
    },
    setKeybeIntelTutorial (context, value) {
      context.commit('SET_KEYBE_INTEL_TUTORIAL', value)
    },
    setConfigsTutorial (context, value) {
      context.commit('SET_CONFIGS_TUTORIAL', value)
    },
    setActionSuggestionsEnabled (context, value) {
      context.commit('SET_ACTION_SUGGESTIONS_ENABLED', value)
    },
    setMessageSuggestionsEnabled (context, value) {
      context.commit('SET_MESSAGE_SUGGESTIONS_ENABLED', value)
    },
    setInstagramFixDialog (context: any, value: boolean) {
      context.commit('SET_INSTAGRAM_FIX_DIALOG', value)
    },
    setWebinarPopup (context, value) {
      context.commit('SET_WEBINAR_POPUP', value)
    },
    setWebinarCounter (context, value) {
      context.commit('SET_WEBINAR_COUNTER', value)
    },
    setShowTemporalWebinarPopUp (context, value) {
      context.commit('SET_TEMPORAL_POPUP', value)
    },
    setCanGetMoreRooms (context: any, value: boolean) {
      context.commit('SET_CAN_GET_MORE_ROOMS', value)
    }

  },
  getters: {
    getFilterParams (state) {
      return state.filterParams
    },
    getTemplates (state) {
      return state.templates
    },
    getChannels (state) {
      return state.channels
    },
    getWpProviders (state) {
      return state.wpProviders
    },
    getRooms (state) {
      return state.rooms
    },
    getPinnedRooms (state) {
      return state.pinnedRooms
    },
    getTotalRooms (state) {
      return state.totalRooms
    },
    getOneSignalUserId (state) {
      return state.oneSignalUserId
    },
    isMineConversationsFilter (state) {
      return state.filterParams.onlySelfConversations && state.filterParams.quickFilterSelected
    },
    isWaitingForAdviserFilter (state) {
      return state.filterParams.conversationsWithoutAdvisor && state.filterParams.quickFilterSelected
    },
    selectedRoomLastMessage (state) {
      return state.selectedRoom?.lastMessage || ''
    }
  }
}
