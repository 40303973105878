import axios from 'axios'
import store from '@/store'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'
import { CurrencyEnum } from '@/components/configuration/Billing/CurrencyEnum'

interface IAddBalance {
  amount: number,
  tcId: number,
  appUUID?: string,
  code?: string,
}

export default class BillingService {
  static async getSimpleBalance (uuid: string) {
    if (!uuid) return
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}app-config/${uuid}/balance/simple`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      console.log(error)
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed getting information' } })
    }
  }

  static async getLastInvoices (appUUID: string) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}invoices`
      const params = {
        appConfigUuid: appUUID,
        page: 1,
        itemsPerPage: 3,
        sortBy: 'dateIni',
        descending: true
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, try again' } })
    }
  }

  static async getLabelsNaming () {
    const token = store.getters['UserStore/getToken']
    const url = `${process.env.VUE_APP_BILLING_URL}charge-types/labels`
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed getting information' } })
    }
  }

  static async getPartnerApps (appUUID: string, companyUUID: string) {
    const token = store.getters['UserStore/getToken']
    const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/company/${companyUUID}/app/${appUUID}/configurations/channels`

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed getting information' } })
    }
  }

  static async getPartnerCreditCards (uuid: string) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BASE_URL}/companies/${uuid}/payments/cards`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          limit: 1000
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      console.error(error)
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed getting information' } })
    }
  }

  static async getPartnerAppConfig (uuid: string) {
    if (!uuid) return
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}app-config/${uuid}`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      console.log(error)
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed getting information' } })
    }
  }

  static async prepaidBalanceStripe (payload: IAddBalance) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      payload.appUUID = appId
      payload.code = process.env.VUE_APP_SCHEDULER_ID
      const url = `${process.env.VUE_APP_BILLING_URL}charge-balance/stripe`
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      // FIX: Arreglar con el error proveniente del servivor.
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, try again' } })
    }
  }

  // Invoices
  static async getInvoices () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appUUID = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_BILLING_URL}invoices`
      const params = {
        appConfigUuid: appUUID,
        page: 1,
        itemsPerPage: 50,
        details: true
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, try again' } })
    }
  }

  static async getColombiaTRM () {
    try {
      const params = new URLSearchParams({
        currencyFrom: CurrencyEnum.USD,
        currencyTo: CurrencyEnum.COP
      }).toString()
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}trm?${params}`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      // FIX: Arreglar con el error proveniente del servivor.
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_409_CONFLICT, message: 'Failed with transaction, try again' } })
    }
  }

  // FIX: Tener en cuenta que esta funcion esta refactorizada de getPlans, toca remplanzar getPlans por getPlansNew reconstruyendo la logica del data
  // y el status.
  static async getPlansNew () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}plans`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      // FIX: Arreglar con el error proveniente del servivor.
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_404_NOT_FOUND, message: 'There aren`t plans' } })
    }
  }

  static async getAppConfig () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_BILLING_URL}app-config/${appId}`
      if (!appId) return
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  static async updateAppCongif (appConfig) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_BILLING_URL}app-config/${appId}`
      const response = await axios.put(url, appConfig, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (e) {
      console.error(e)
    }
  }

  static async getBalance () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_BILLING_URL}app-config/balance/${appId}`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (e) {
      console.error(e)
    }
  }

  static async getPlans () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}plans`
      const response = axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response
    } catch (e) {
      console.error(e)
    }
  }

  static async getEsentialPlan () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}plans`
      const response = axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          name: 'Essential'
        }
      })
      return response
    } catch (e) {
      console.error(e)
    }
  }

  static async getProfessionalPlan () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}plans`
      const response = axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          name: 'Professional'
        }
      })
      return response
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Credit Cards
   **/
  static async deleteCreditCard (card) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/payments/cards/${card.cardId}`

      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  static async setCardAsMain (card) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/payments/cards/change/to/main`
      const response = await axios.post(url, {
        toChange: card.cardId
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  static async addCreditCard ({ cardToken, transactionProvider }) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/payments/cards`
      const response = await axios.post(url, {
        cardToken,
        transactionProvider
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  static async getCreditCards () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/payments/cards`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          limit: 1000
        }
      })
      const cards = response.data.paymentCards.rows
      return cards.map((card, i) => {
        card.main = i === 0
        return card
      })
    } catch (error) {
      console.error(error)
    }
  }

  // Consumptions
  static async getConsumptions (data) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}charge-types-app/consumptions`
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (e) {
      console.error(e)
    }
  }

  // Usages
  static async getUsage (data) {
    try {
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/payments/transactions`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          limit: data.limit,
          page: data.page,
          fromDate: data.fromDate,
          toDate: data.toDate
        }
      })
      return response.data
    } catch (e) {
      console.error(e)
    }
  }

  // Payments
  static async getPayments (data) {
    try {
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/payments`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          page: data.page,
          limit: data.itemsPerPage
        }
      })
      return response.data
    } catch (e) {
      console.error(e)
    }
  }

  static async newPayment (data) {
    try {
      const { amount, cardId } = data
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/payments/cards/payment`
      const response = await axios.post(url, {
        amount,
        cardId
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (e) {
      console.error(e)
    }
  }

  // Sms or Email Reports
  static async getEmailOrSmsReports (data) {
    try {
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/list/${data.type}/sent`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          limit: data.itemsPerPage,
          page: data.page,
          fromDate: data.fromDate,
          toDate: data.toDate
        }
      })
      return response.data
    } catch (e) {
      console.error(e)
    }
  }

  // Invite administrator
  static async inviteAdministrators (invitations) {
    try {
      const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/invite`

      const response = await axios.post(url, { invitations }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  // Create Invoice (Billing for campaigns)
  static async createInvoice (entity: string, data: any) {
    try {
      const token = store.getters['UserStore/getV4Token']
      const url = `${process.env.VUE_APP_BILLING_URL}${entity}`
      const response = await axios.post(url, data, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return response.data
    } catch (e) {
      console.log(e)
    }
  }
}
