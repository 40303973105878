import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMoment from 'vue-moment'
import moment from 'moment'
import 'moment/locale/es'
import { i18n } from '@/plugins/i18n/i18n'
import OneSignalVue from 'onesignal-vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import router from '@/router/router'
import App from '@/App.vue'
import VueTelInput from 'vue-tel-input'
import Toast, { POSITION } from 'vue-toastification'
import LottieAnimation from 'lottie-web-vue'
import VueCroppie from 'vue-croppie'
import VueGtm from '@gtm-support/vue2-gtm'
import './assets/css/vue-toastification/index.scss'
import 'vue-tel-input/dist/vue-tel-input.css'
import './input.scss'
import 'regenerator-runtime'
import Hotjar from 'vue-hotjar'
import * as VueGoogleMaps from 'vue2-google-maps'
import interceptorSetup from '@/utils/interceptor'
import VueYoutube from 'vue-youtube'
import './components/conversations/styles/Mentionable.scss'

// import './registerServiceWorker'

Vue.use(VueGtm, {
  id: 'GTM-P2QRQ94',
  enabled: false,
  trackOnNextTick: false
})

Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_KEY,
  isProduction: true,
  snippetVersion: 6
})

Vue.use(VueRouter)

Vue.use(VueMoment, { moment })
Vue.use(VueTelInput)
Vue.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
  containerClassName: 'custom-toast',
  icon: false
})
Vue.use(LottieAnimation)

Vue.use(OneSignalVue)

Vue.use(VueCroppie)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLEMAPS,
    libraries: 'places'
  }
})

Vue.use(VueYoutube)

interceptorSetup(store, router, i18n)
Vue.config.productionTip = false
new Vue({
  i18n,
  render: h => h(App),
  store,
  vuetify,
  router,
  beforeCreate () {
    this.$store.commit('initialiseStore')
  },
  beforeMount () {
    const fileName: string = 'OneSignalSDKWorker.js'
    this.$OneSignal.init({
      appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
      safari_web_id: process.env.VUE_APP_ONESIGNAL_SAFARI_WEB_ID,
      notificationClickHandlerMatch: 'origin',
      notificationClickHandlerAction: 'focus',
      serviceWorkerParam: {
        scope: `${process.env.VUE_APP_BASE_ROUTE}/`
      },
      serviceWorkerPath: `${process.env.VUE_APP_BASE_ROUTE.replace('/', '')}/${fileName}`,
      serviceWorkerUpdaterPath: `${process.env.VUE_APP_BASE_ROUTE.replace('/', '')}/${fileName}`,
      persistNotification: false
    })
  }
}).$mount('#app')
