<template>
  <div class="keybe-toast">
    <h4>⏰</h4>
    <h3>{{ message }}</h3>
  </div>
</template>
<script>
import Vue from 'vue'
export default Vue.extend({
  props: ['message']
})
</script>
<style lang="scss" scoped>
.keybe-toast {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  h4 {
    margin-right: 1rem;
  }
}
</style>
