<template>
<div class="SuccessModal">
  <div class="closeRow">
    <v-icon @click="close" color="white">mdi-close</v-icon>
  </div>
  <img
    width="160px"
    height="160px"
    loading="lazy"
    alt=""
    class="mb-8"
    :src="handIcon">
  <h3>{{ $t('invitationsSent') }}</h3>
</div>
</template>

<script>
import link from '@/utils/links'

export default {
  name: 'SuccessModal',
  data () {
    return {
      handIcon: `${process.env.VUE_APP_ASSETS_URL}/conversation-module/ok-hand-icon.svg`,
      link
    }
  },
  methods: {
    close () {
      this.$emit('close')
      this.$router.push(link.onboarding)
    }
  }
}
</script>

<style scoped>
.SuccessModal {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(22, 39, 70, 0.90);
  color: white;
}

.closeRow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 4rem;
  position: absolute;
  top: 20%;
  right: 0;
}
</style>
