import axios from 'axios'
import store from '@/store/index'
import { EventBus } from '@/eventBus'
import { RestResponseFactory } from '@/models/apis/RestResponse'

export default class UserService {
  static async habeasDataRead (userId, habeasFile, bucket) {
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId = store.getters['AppStore/getSelectedApp'].uuid
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/users/${userId}/read/habeas/data`,
        {
          data: { companyId, appId, userId, habeasFile, bucket },
          headers: {
            Authorization: 'Bearer ' + token
          },
          responseType: 'blob'
        }
      )
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'habeasData.pdf')
      document.body.appendChild(link)
      link.click()
      return true
    } catch (error) {
      console.error(error)
      EventBus.$emit('toast', 'error', 'Ocurrió un error obteniendo el documento')
    }
  }

  static async changePasswordLogged (currentPassword: string, newPassword: string) {
    const token = store.getters['UserStore/getToken']

    const data = {
      currentPassword,
      newPassword
    }

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_AUTH_SERVICE}auth/change-password`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async uploadImage (img) {
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/image`
    const token = store.getters['UserStore/getToken']
    const file = new FormData()
    if (img) {
      file.append('file', img)
    }
    try {
      const response = await axios.post(url, file, {
        headers: {
          Authorization: 'Bearer ' + token,
          contentType: 'multipart/form-data'
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async updateProfile (data) {
    const appId = store.getters['AppStore/getSelectedApp'].uuid
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/profile/${appId}`
    const token = store.getters['UserStore/getToken']
    try {
      const response = await axios.patch(url, data, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async getRoles () {
    const userId = store.getters['UserStore/getUser'].userId
    const token = store.getters['UserStore/getToken']
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_AUTH_SERVICE}user/${userId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      const roles = response.data.data.user.permissionNames
      store.commit('UserStore/SET_ROLES', roles)
      return new RestResponseFactory().process({ response }).data.data.user.permissionNames
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }

  static async updateUserExtradata (data) {
    const userUUID = store.getters['UserStore/getUser'].uuid
    const token = store.getters['UserStore/getToken']
    const url = `${process.env.VUE_APP_AUTH_SERVICE}user/${userUUID}`
    const appId = store.getters['AppStore/getSelectedApp'].uuid
    try {
      const response = await axios.put(url, {
        appUUID: appId,
        extradata: [
          data
        ]
      }, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return new RestResponseFactory().process({ response })
    } catch (error) {
      return new RestResponseFactory().process({ response: { status: 500 } })
    }
  }
}
