import Vue from 'vue'
import Vuex from 'vuex'
import { SigninStore } from './modules/SigninStore'
import { ConversationStore } from '@/store/modules/ConversationStore'
import { AppStore } from '@/store/modules/AppStore'
import { BusinessStore } from '@/store/modules/BusinessStore'
import { UserStore } from '@/store/modules/UserStore'
import { UsersStore } from '@/store/modules/UsersStore'
import { CompanyStore } from '@/store/modules/CompanyStore'
import { PipelinesStore } from '@/store/modules/PipelinesStore'
import { SegmentsStore } from '@/store/modules/SegmentsStore'
import { FormsStore } from '@/store/modules/FormsStore'
import { BillingStore } from '@/store/modules/BillingStore'
import { ConversationsFilterStore } from '@/store/modules/ConversationsFilterStore'
import { TriggersStore } from '@/store/modules/TriggersStore'
import { ExtensionStore } from '@/store/modules/ExtensionStore'
import { BannerStore } from '@/store/modules/BannerStore'
import { EventsStore } from '@/store/modules/EventsStore'
import { OpportunityStore } from '@/store/modules/OpportunityStore'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    UserStore,
    UsersStore,
    SigninStore,
    ConversationStore,
    AppStore,
    BusinessStore,
    CompanyStore,
    PipelinesStore,
    SegmentsStore,
    FormsStore,
    BillingStore,
    ConversationsFilterStore,
    TriggersStore,
    ExtensionStore,
    BannerStore,
    EventsStore,
    OpportunityStore
  },
  mutations: {
    initialiseStore (state) {
      if (localStorage.getItem('keybeStore')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('keybeStore')))
        )
      }
    }
  }
})
store.subscribe((mutation, state) => {
  localStorage.setItem('keybeStore', JSON.stringify(state))
})

// @ts-ignore
window.initialState = store.state
export default store
