<template>
    <div class="d-flex items-center justify-end">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                    <v-btn fab
                           class="elevation-0 user-avatar">
                        <!-- <KeybeAvatar
                            :name="userComputed.name || 'K B'"
                            :size="30"
                            :img="userComputed.photo || ''"
                            :isKeybeProfile="false"
                        /> -->
                        <status-avatar/>
                        <!-- <v-avatar class="profile-indicator" :color="isActive?'#7BFF00':'#ff0000'" size="8"></v-avatar> -->
                    </v-btn>
                    <!-- <div class="d-flex align-center -ml-2" style="background:#ffffff40; border-radius:8px;">
                      <span style="color: white" class="mx-4">{{isActive? $t('active'):$t('inactive')}}</span>
                      <v-icon color="white">mdi-chevron-down</v-icon>
                    </div> -->
                </div>
            </template>
            <v-list style="width: 240px">
                <div class="d-flex flex-column justify-center align-center pa-6">
                    <div class="profilePic">
                        <KeybeAvatar
                                :name="userComputed.name || 'K B'"
                                :size="50"
                                :img="userComputed.photo || ''"
                                :isKeybeProfile="false"
                        />
                    </div>
                    <span class="text-center mt-2">{{ fullName }}</span>
                    <span class="text-xs mb-2 text-center">{{ email }}</span>
                    <AdvisorStatus/>
                </div>
                <v-divider></v-divider>
                <v-list-item class="d-flex justify-center text-xs" v-if="plan">
                    Plan
                    <v-chip @click="upgradePlan" color="#162746" dark small class="ml-1"><strong
                            class="text-xs">{{ $t(plan) }}</strong></v-chip>
                </v-list-item>
                <!--        <v-list-item @click="$router.push(link.config)">-->
                <!--          <v-list-item-title class="d-flex justify-center text-xs">-->
                <!--            {{$t('configuration')}}-->
                <!--          </v-list-item-title>-->
                <!--        </v-list-item>-->
                <v-list-item @click="_logout()">
                    <v-list-item-title class="d-flex justify-center text-xs">
                        {{ $t('logout') }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <UpgradeToPro v-model="dialog" @input="dialog = $event"/>
        <div id="rp-token" class="rp-container" style="margin: 1px 8px 1px 8px"
             data-param="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwcm9kdWN0X2lkIjo5OX0.VmthK0aEpIAvOarURLNImQzjCdfJ2W0sF0uk4vlu2RE"
             lang="es">
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import * as icons from '@/plugins/icons/icons'
import { mapActions, mapState, mapGetters } from 'vuex'
import link from '@/utils/links'
import op from '@/utils/operations'
import AppUUIDsService from '@/services/AppUUIDsService'
import { PlansEnum } from '@/components/configuration/PlansEnum'
// const UpgradeToPro = () => import(/* webpackChunkName: "main" */ '@/components/Dialogs/CreditCard/UpgradeToPro')
import UpgradeToPro from '@/components/Dialogs/CreditCard/UpgradeToPro'
import AdvisorStatus from '@/components/conversations/components/rooms/AdvisorStatus'
import AdvisersService from '@/components/conversations/Services/AdvisersService'
import PushNotificationsController from '@/controllers/PushNotificationsController'
import AuthService from '@/services/AuthService'

const KeybeAvatar = () => import('@/components/shared/components/KeybeAvatar.vue')
const StatusAvatar = () => import('@/components/shared/components/StatusAvatar.vue')
export default Vue.extend({
  components: {
    AdvisorStatus,
    UpgradeToPro,
    KeybeAvatar,
    StatusAvatar
  },
  created () {
    this.adviserStatus = AdvisersService.getAdviserStatus(this.user.userId)
  },
  data: function () {
    return {
      icons,
      op,
      dialog: false,
      link,
      adviserStatus: 'offline'
    }
  },
  computed: {
    ...mapState('UserStore', ['user']),
    ...mapState('AppStore', ['language', 'selectedApp', 'advisers']),
    ...mapState('BillingStore', ['appConfig']),
    ...mapGetters('ConversationStore', ['getOneSignalUserId']),
    userComputed: function () {
      return this.user
    },
    fullName () {
      const name = this.userComputed?.name || ''
      const lastName = this.userComputed?.lastName || ''
      return `${name} ${lastName}`
    },
    email () {
      return this.userComputed?.email || ''
    },
    appKeybe () {
      if (this.selectedApp?.uuid === AppUUIDsService.appKeybe()) return true
      else return false
    },
    plan () {
      return this.appConfig?.plan?.label || ''
    },
    isActive () {
      return this.adviserStatus === 'online'
    }
  },
  methods: {
    ...mapActions('UserStore', ['logout']),
    async _logout () {
      if (this.getOneSignalUserId && !this.op.areEqual(this.getOneSignalUserId, undefined)) await PushNotificationsController.setOneSignalStatus({ active: false })
      await AuthService.logout()
      this.logout()
      // window.location.href = `${process.env.VUE_APP_V6_URL}/signin?logout=true`
      this.$router.push(link.signin)
    },
    upgradePlan () {
      if (this.plan !== PlansEnum.ESSENTIAL) {
        return
      }
      this.dialog = true
    }
  },
  watch: {
    advisers () {
      this.adviserStatus = AdvisersService.getAdviserStatus(this.user.userId)
    }
  }
})

</script>
<style lang="scss" scoped>
.user-avatar {
  border: 4px solid;
  background: transparent !important;
  width: 32px;
  height: 32px;
  position: relative;
}

.profile-indicator {
  position: absolute;
  bottom: 0;
  right: 0;
}

::v-deep .releasepad-widget-btn {
  height: 32px !important;
  width: 32px !important;
}

.profilePic {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #01CFE4;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 92%;
    width: 90%;
    border-radius: 50%;
  }
}
</style>
