import ConversationsRollbarService from '@/services/Rollbar/ConversationsRollbarService'
import ConnectRollbarService from '@/services/Rollbar/ConnectRollbarService'
import AutomateRollbarService from '@/services/Rollbar/AutomateRollbarService'

export default class RollbarFactory {
  static getInstance (type) {
    const rollbarServices = {
      conversations: ConversationsRollbarService,
      connect: ConnectRollbarService,
      automate: AutomateRollbarService
    }

    return rollbarServices[type].getInstance()
  }
}
