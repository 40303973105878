import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import 'regenerator-runtime'
import { GraphqlVariables } from '@/Enums/GraphqlVariables'

export default class ConversationsApolloProvider {
  provider
  // Name of the localStorage item
  AUTH_TOKEN = 'apollo-token'

  // Http endpoint
  httpEndpoint = process.env.VUE_APP_CONVERSATIONS_GRAPHQL_HTTP || GraphqlVariables.HTTP
  // Files URL root
  filesRoot = process.env.VUE_APP_FILES_ROOT || this.httpEndpoint.substring(0, this.httpEndpoint.indexOf('/graphql'))
  // Config
  defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint: this.httpEndpoint,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    wsEndpoint: process.env.VUE_APP_CONVERSATIONS_GRAPHQL_WS || GraphqlVariables.WS,
    // LocalStorage token
    tokenName: this.AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    // link: myLink

    // Override default cache
    // cache: myCache

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => ...

    // Additional ApolloClient options
    // apollo: { ... }

    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }
  }

  constructor () {
    const httpLink = new HttpLink({
      // You should use an absolute URL here
      uri: process.env.VUE_APP_CONVERSATIONS_GRAPHQL_HTTP
    })

    // Create the subscription websocket link
    const wsLink = new WebSocketLink({
      uri: process.env.VUE_APP_CONVERSATIONS_GRAPHQL_WS,
      options: {
        reconnect: true,
        lazy: true
      }
    })

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === GraphqlVariables.OPERATION_DEFINITION &&
          definition.operation === GraphqlVariables.SUBSCRIPTION
      },
      wsLink,
      httpLink
    )

    this.provider = this.createProvider({
      link,
      cache: new InMemoryCache(),
      connectToDevTools: true
    })
  }

  createProvider (options = {}) {
    // Create apollo client
    const { apolloClient, wsClient } = createApolloClient({
      ...this.defaultOptions,
      ...options
    })
    apolloClient.wsClient = wsClient

    // Create vue apollo provider
    return new VueApollo({
      defaultClient: apolloClient,
      defaultOptions: {
        $query: {
          // fetchPolicy: 'cache-and-network',
        }
      },
      errorHandler (error) {
        // eslint-disable-next-line no-console
        console.log('%cError',
          'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
          error.message)
      }
    })
  }

  static getInstance () {
    // @ts-ignore
    if (!ConversationsApolloProvider.instance) {
      // @ts-ignore
      ConversationsApolloProvider.instance = new ConversationsApolloProvider()
    }
    // @ts-ignore
    return ConversationsApolloProvider.instance
  }
}
