



































































import { App } from '@/components/models/App'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import AppService from '@/services/AppService'
import PushNotificationsController from '@/controllers/PushNotificationsController'
import { UserStates } from '@/components/conversations/enums/UserStates'
import ConversationsConfigController from '@/components/configuration/controllers/ConversationsConfigController'

export default Vue.extend({
  props: {
    centerClass: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      show: false,
      search: '',
      loadingCompanies: false,
      filteredApps: [],
      closeOnClick: false,
      awaitingSearch: false,
      limit: 50
    }
  },
  methods: {
    ...mapActions('AppStore', ['getAdviserStatus']),
    async _getCompanies () {
      try {
        this.loadingCompanies = true
        this.filteredApps = this.apps.slice(0, this.limit)
        this.loadingCompanies = false
      } catch (e) {
        console.error(e)
        this.loadingCompanies = false
      }
    },
    async filterApps () {
      if (!this.awaitingSearch) {
        setTimeout(async () => {
          const search = this.search ? this.search.toLowerCase() : ''
          await AppService.getApps(search)
          this.awaitingSearch = false
          if (this.apps.length > this.limit) {
            this.filteredApps = this.apps.slice(0, this.limit)
          } else {
            this.filteredApps = this.apps
          }
        }, 500)
      }
      this.awaitingSearch = true
    },
    async selectApp (app: App) {
      this.show = false
      // const data = V6RedirectionController.validateRedirect(window.location.origin, app)
      // if (data?.shouldRedirect) {
      //   await V6RedirectionController.setRedirectionExtradata(app)
      //   window.location.href = `${data.url}?tokenV6=${this.token}`
      //   return
      // }

      if (this.selectedApp && this.selectedApp.uuid && (app.uuid !== this.selectedApp.uuid)) {
        await PushNotificationsController.setOneSignalStatus({
          active: false,
          appUUID: this.selectedApp.uuid
        })
      }
      await AppService.selectByApp(app)
      const currentStatus = await this.getAdviserStatus(
        this.user.userId
      )
      const value = currentStatus === UserStates.Online
      await PushNotificationsController.setOneSignalStatus({ active: value })
    },
    async getChannels () {
      await ConversationsConfigController.getChannels()
    }
  },
  computed: {
    ...mapState('AppStore', ['selectedApp', 'apps']),
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapState('UserStore', ['user', 'token']),
    appName () {
      return this.selectedApp?.name || this.$t('selectApp')
    }
  },
  created () {
    this._getCompanies()
  },
  watch: {
    async selectedCompany (newVal) {
      if (!newVal) {
        return
      }
      await this.getChannels()
    }
  }
})
