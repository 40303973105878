














import Vue from 'vue'

export default Vue.extend({
  props: ['value', 'color', 'placeholder', 'incorrect', 'disabled', 'autofocus', 'autofocusDelay', 'type', 'square'],
  data () {
    return {
      colorClass: {
        light: 'keybe-light',
        white: 'keybe-white',
        dark: 'keybe-dark',
        transparent: 'keybe-transparent',
        darkPersistent: 'keybe-dark-persistent'
      },
      textfieldValue: this.value
    }
  },
  methods: {
    setValue () {
      this.$emit('input', this.textfieldValue)
    }
  },
  watch: {
    value (value) {
      if (value) {
        this.textfieldValue = value
      }
    }
  },
  mounted () {
    const delay = this.autofocusDelay || 0
    if (this.autofocus) {
      setTimeout(() => {
        this.$el.focus()
      }, delay)
    }
  }
})
