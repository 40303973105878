













































































import Vue from 'vue'
import Colors from '@/utils/Colors'
import AppService from '@/services/AppService'
import AppUUIDsService from '@/services/AppUUIDsService'
import ToastNotification from '@/components/shared/components/vue-toastification/ToastNotification.vue'
import { loadLanguageAsync } from '@/plugins/i18n/i18n'
import { mapActions, mapGetters, mapState } from 'vuex'
import onUser from '@/components/shared/graphql/subscription-on-user.graphql'
import link from '@/utils/links'
import NewToolbar from '@/components/shared/components/NewToolbar.vue'
import SideMenu from '@/components/shared/components/SideMenu.vue'
import ImageModal from '@/components/shared/components/ImageModal.vue'
import { Languages } from '@/components/shared/enums/Languages'
import operation from '@/utils/operations'
import UserService from '@/services/UserService'
import { EventBus } from '@/eventBus'
import { KeybeUserExtradata } from '@/models/KeybeUserExtradata'
import ConversationsApolloProvider from '@/Providers/ConversationsApolloProvider'
import EventsApolloProvider from '@/Providers/EventsApolloProvider'
import { GraphqlEventTypes } from '@/components/shared/enums/GraphqlEventTypes'
import { UserStates } from '@/components/conversations/enums/UserStates'
import PushNotificationsController from '@/controllers/PushNotificationsController'
import { StatusActive } from '@/Enums/StatusActive'
import AuthService from '@/services/AuthService'

const Footer = () =>
  import(/* webpackChunkName: "main" */ '@/components/Footer.vue')
// components
const Loader = () => import('@/components/shared/components/Loader.vue')

const Dialog = () => import('@/components/shared/components/Dialog.vue')

export default Vue.extend({
  name: 'App',
  components: {
    // TaskCenter,
    // BottomMenu,
    SideMenu,
    NewToolbar,
    ImageModal,
    ToastNotification,
    Loader,
    Footer,
    Dialog
  },
  data () {
    return {
      Colors,
      mini: false,
      expanded: true,
      loadingApps: false,
      selectingCompany: false,
      oneSignalUserId: null,
      refreshing: false,
      extradata: {},
      conversationsApolloClient:
            ConversationsApolloProvider.getInstance().provider.defaultClient,
      eventsApolloClient:
            EventsApolloProvider.getInstance().provider.defaultClient,
      UUID: AppUUIDsService,
      oneSignalRetryAttempts: 0,
      showFormDialog: false,
      flowsPopUp: false,
      flowsDonePopUp: false,
      showWebinar: false
    }
  },
  computed: {
    ...mapState('UserStore', [
      'isLoggedIn',
      'isLoading',
      'firstRender',
      'user',
      'changePassword',
      'userExtradata',
      'user',
      'deviceToken',
      'token'
    ]),
    ...mapState('AppStore', [
      'selectedApp',
      'showTutorial',
      'language',
      'showWebinarPopup',
      'formDialogCompleted',
      'formDialogSkipped',
      'kbFlowsRequestedInfo'
    ]),
    ...mapState('CompanyStore', ['selectedCompany']),
    ...mapGetters('AppStore', ['getSelectedApp']),
    ...mapGetters('UserStore', ['getV4Token', 'getDarkModeActive']),
    ...mapState('ConversationStore', ['instagramFixDialog', 'webinarPopUpCounter', 'temporalWebinarPopup', 'webinarPopup']),
    signin (): string {
      return link.signin
    },
    signup (): string {
      return link.signup
    },
    resetPassword (): string {
      return link.resetPassword
    },
    formPreview (): string {
      return link.formPreview
    },
    invitedWizard (): string {
      return link.invitedWizard
    },
    facebookMobile (): string {
      return link.facebookMobileConfig
    },
    instagramMobile (): string {
      return link.instagramMobileConfig
    },
    isFrontOfficeView (): boolean {
      return (
        this.$route.path === this.signin ||
                this.$route.path === this.signup ||
                this.$route.path === this.formPreview ||
                this.$route.path === this.resetPassword ||
                this.$route.path === this.invitedWizard ||
                this.$route.path.includes(this.facebookMobile.toLowerCase()) ||
                this.$route.path.includes(this.instagramMobile.toLowerCase())
      )
    },
    changePasswordDialog () {
      return () =>
        import('@/components/shared/components/ChangePasswordPopUp.vue')
    },
    WebinarPopUp () {
      return () => import('@/components/shared/components/WebinarPopUp.vue')
    },
    FormDialog () {
      return () => import('@/components/shared/components/FormPopUp.vue')
    },
    isBetaUser () {
      const betaUsers = ['ZEVDDP', 'KDD8B1', 'W4VPE2Q', 'VXZ1MVZ', 'GBZ2MB']
      return betaUsers.includes(this.user?.userId)
    },
    flowsPopUpComponent () {
      return () => import('@/components/shared/components/KBFlowsPopUp.vue')
    },
    flowsDonePopUpComponent () {
      return () => import('@/components/shared/components/KBFlowsDonePopUp.vue')
    },
    WebinarDialog () {
      return () => import('@/components/conversations/dialogs/WebinarDialog.vue')
    },
    showPopup () {
      return this.webinarPopup && this.showWebinar
    }
  },
  async created () {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.refreshOnUpdate, {
      once: true
    })

    if (this.token) {
      await AuthService.validateToken()
    }

    window.onfocus = async () => {
      if (this.token) {
        await AuthService.validateToken()
      }
    }
  },
  mounted () {
    const webinarPopUpState = this.userExtradata.filter(item => item.property === 'Registered webinar may 10')
    if (this.userExtradata.length === 0) {
      this.setShowTemporalWebinarPopUp(true)
      this.setWebinarPopup(true)
      this.showWebinar = true
    }
    if (webinarPopUpState.length > 0) {
      this.setShowTemporalWebinarPopUp(false)
      this.setWebinarPopup(false)
      this.showWebinar = false
    }

    EventBus.$on('openKbFlows', () => {
      if (this.kbFlowsRequestedInfo) {
        this.flowsDonePopUp = true
        this.flowsPopUp = false
      } else {
        this.flowsDonePopUp = false
        this.flowsPopUp = true
      }
    })
    EventBus.$on('kbFlowsPopUpSuccess', () => {
      this.flowsPopUp = false
      this.flowsDonePopUp = true
      this.setKbFlowsRequestedInfo(true)
    })
    if (!this.getDarkModeActive) {
      if (
        window.matchMedia &&
                window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
      this.$store.commit(
        'UserStore/SET_DARK_MODE',
        this.$vuetify.theme.dark ? StatusActive.ACTIVE : StatusActive.INACTIVE
      )
    } else {
      if (operation.areEqual(this.getDarkModeActive, StatusActive.ACTIVE)) {
        this.$vuetify.theme.dark = true
      } else if (
        operation.areEqual(this.getDarkModeActive, StatusActive.INACTIVE)
      ) {
        this.$vuetify.theme.dark = false
      }
    }

    if (!this.language) {
      const language = navigator.language
      let lang = language.split('-')[0]
      if (lang !== Languages.ES) {
        lang = Languages.EN
      }
      this.$store.commit('AppStore/SET_APP_LANGUAGE', lang)
    }
    loadLanguageAsync(this.language)

    this.cleanTemporalData()
    const url = window.location.hostname
    const bluebirdOldUrl = 'bluebird.keybe.ai'
    if (url === bluebirdOldUrl) {
      const baseURL = process.env.VUE_APP_BASE_KEYBE_URL
      const URL = `${baseURL}conversations?app=${this.getSelectedApp.uuid}&token=${this.getV4Token}`
      window.location.replace(URL)
    }

    const notificationHandler = (data) => {
      if (data.data) {
        if (data.data.type === 'room-redirection') {
          if (this.$route.path !== link.conversations) {
            this.$router.push(link.conversations)
          }
          setTimeout(() => {
            this.selectRoom(data.data.payload)
          }, 500)
        }
      } else {
        this.$router.push(link.conversations)
      }

      this.$OneSignal.addListenerForNotificationOpened(notificationHandler)
    }
    this.validateOneSignal()
    this.$OneSignal.addListenerForNotificationOpened(notificationHandler)
    if (this.selectedApp?.uuid && this.isLoggedIn) {
      this.createEventSubscription()
    }

    if (!this.formDialogCompleted) {
      this.showFormDialog = true
    }
  },
  beforeCreate () {
    const path = this.$route.path

    if (path === link.login) {
      const query = this.$route.query
      this.$router.push({ path: link.signin, query, replace: true })
      return
    }

    if (path === link.register) {
      const query = this.$route.query
      this.$router.push({ path: link.signup, query, replace: true })
    }
  },
  methods: {
    shouldShowComponent (): boolean {
      const allowedIds = ['g6md71lkn2ix0f', 'cpu4on1lhapaha5', 'c0681liowgag0', '4m3wtp1lnapg91m', '4m3wtp1lnao2bzu', '171xemapnl27rgdcy', '4xqa4b8khxrofef', 'bisovs8kwjtk8iyxi', '4xqaokbkhfbj8ox']
      return allowedIds.includes(this.getSelectedApp.uuid)
    },
    ...mapActions('AppStore', [
      'getAdviserStatus',
      'setShowTutorial',
      'setWebinarPopup',
      'setFormDialogSkipped',
      'setKbFlowsRequestedInfo'
    ]),
    ...mapActions('ConversationStore', [
      'setOneSignalUserId',
      'selectRoom',
      'cleanTemporalData',
      'setShowTemporalWebinarPopUp',
      'setWebinarPopup'
    ]),
    ...mapActions('UserStore', ['logout', 'setUserExtradata']),
    ...mapActions('PipelinesStore', ['setSelectedPipeline']),
    closeWebinarPopUp () {
      this.showWebinar = false
    },
    async updateUserExtradata (data) {
      const extradata = new KeybeUserExtradata(this.userExtradata)
      const response = await UserService.updateUserExtradata(data)
      if (response.status) {
        extradata.updateExtradata(data)
        const newExtradata = extradata.getExtradata()
        this.setUserExtradata(newExtradata)
      }
    },
    async getApps () {
      try {
        this.loadingApps = true
        await AppService.getApps('')
        this.loadingApps = false
      } catch (e) {
        console.error(e)
        this.loadingApps = false
      }
    },
    async selectCompany (app) {
      try {
        this.selectingCompany = true
        await AppService.selectByApp(app)
        this.selectingCompany = false
      } catch (e) {
        console.error(e)
        this.selectingCompany = false
      }
    },
    toggleSidebar (mini: boolean) {
      this.mini = mini
      this.expanded = false
    },
    validateOneSignal () {
      this.$OneSignal.isPushNotificationsEnabled((isEnabled) => {
        if (isEnabled) {
          this.$OneSignal.getUserId((userId) => {
            this.oneSignalUserId = userId
          })
        } else {
          this.$OneSignal.showNativePrompt()
          this.$OneSignal.on('subscriptionChange', (isSubscribed) => {
            if (isSubscribed) {
              this.$OneSignal.getUserId((userId) => {
                this.oneSignalUserId = userId
              })
            } else {
              this.oneSignalUserId = 'null'
            }
          })
        }
      })
    },
    createEventSubscription () {
      this.closeSubscriptions()
      // old Events subscription
      // this.onEventSubscription = this.conversationsApolloClient
      //   .subscribe({
      //     query: onEvent,
      //     variables: {
      //       appUUID: this.selectedApp?.uuid
      //     }
      //   })
      //   .subscribe(async (res) => {
      //     if (res.data?.onEvent) {
      //       const { type } = res.data?.onEvent
      //       if (type === EventTypes.ForceLogout && this.isLoggedIn) {
      //         this.logout()
      //         this.$router.push(link.signin)
      //       }
      //       if (
      //         type === EventTypes.WizardNotification &&
      //         this.$route.path === link.conversations
      //       ) {
      //         this.$store.dispatch('AppStore/setShowTutorial', true)
      //         this.$store.dispatch('AppStore/setTutorialStep', 1)
      //       }
      //       if (type === EventTypes.ForceRefresh) {
      //         window.location.reload()
      //       }
      //     }
      //   })
      // New Events Subsctiption
      this.onUserSubscription = this.eventsApolloClient
        .subscribe({
          query: onUser,
          variables: {
            userUUID: this.user?.uuid
          }
        })
        .subscribe((res) => {
          const data = res.data.onUser
          const isCloseSession = data.type === GraphqlEventTypes.CLOSE_SESSION
          const isDevice = data.deviceToken === this.deviceToken
          const message = data.message
          if (isCloseSession && isDevice) {
            EventBus.$emit('toast', 'error', message)
            setTimeout(() => {
              this.logout()
              this.$router.push(link.signin)
            }, 500)
          }
        })
    },
    closeSubscriptions () {
      // if (this.onEventSubscription) {
      //   this.onEventSubscription.unsubscribe()
      // }
      if (this.onUserSubscription) {
        this.onUserSubscription.unsubscribe()
      }
    },
    cancelTutorial () {
      this.setShowTutorial(false)
      this.$store.dispatch('AppStore/setTutorialStep', 1)
    },
    closeChangePasswordDialog () {
      this.$store.commit('UserStore/SET_CHANGE_PASSWORD', false, {
        root: true
      })
    },
    // closeSeptemberPopup () {
    //   this.showPopup = false
    //   this.updateUserExtradata(false)
    //   this.setSeptemberPopup(false)
    // },
    closeWebinarPopup () {
      this.setWebinarPopup(false)
    },
    refreshOnUpdate (event) {
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!event.detail || !event.detail.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      event.detail.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    // closeInstagramFixDialog () {
    //   this.setInstagramFixDialog(false)
    //   this.showInstagramFixDialog = this.instagramFixDialog
    // }
    closeFormDialog () {
      if (this.formDialogCompleted) {
        this.showFormDialog = false
        return
      }

      if (!this.formDialogSkipped) {
        this.setFormDialogSkipped(1)
        this.showFormDialog = false
        return
      }

      if (this.formDialogSkipped >= 2) {
        EventBus.$emit('toast', 'error', this.$t('completeForm'))
        return
      }

      this.setFormDialogSkipped(this.formDialogSkipped + 1)
      this.showFormDialog = false
    }
  },
  watch: {
    async oneSignalUserId (newId) {
      if (!this.isLoggedIn) return

      if (newId) {
        this.setOneSignalUserId(newId)
        if (this.isLoggedIn) {
          const currentStatus = await this.getAdviserStatus(this.user.userId)
          await PushNotificationsController.setOneSignalStatus({
            active: currentStatus === UserStates.Online
          })
        }
      } else {
        if (this.oneSignalRetryAttempts < 3) {
          this.oneSignalRetryAttempts++
          setTimeout(() => {
            this.validateOneSignal()
          }, 1000)
        }
      }
    },
    selectedApp (newValue) {
      this.setSelectedPipeline(null)
      this.$store.commit('UserStore/SET_ORDERED_EXTRADATA', [])
      this.$store.commit('UserStore/SET_PERSONALIZED_EXTRADATA', [])
      this.$store.commit('UserStore/SET_ORDERED_TAGS', [])
      this.$store.commit('UserStore/SET_PERSONALIZED_TAGS', [])
      this.closeSubscriptions()
      if (newValue?.uuid && this.isLoggedIn) {
        this.createEventSubscription()
      }
    },
    selectedCompany: {
      immediate: true,
      deep: true,
      handler () {
        this.cleanTemporalData()
      }
    },
    changePassword (newVal) {
      const query = this.$route.query
      if (query?.wizard) {
        this.showChangePasswordDialog = false
      }
      if (this.$route.path !== link.onboarding) {
        this.showChangePasswordDialog = false
      }
      if (!this.user?.terms) {
        this.showChangePasswordDialog = false
      }
      this.showChangePasswordDialog = newVal
    },
    language (newVal) {
      loadLanguageAsync(newVal)
    },
    isLoggedIn (newVal) {
      if (newVal) {
        this.validateOneSignal()
      }
    },
    kbFlowsRequestedInfo (newVal) {
      if (newVal) {
        this.flowsDonePopUp = true
      }
    }
  }
})
