import axios from 'axios'
import store from '@/store/index'
import { ApiResponse } from 'src/components/models/ApiResponse'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import UserService from './UserService'
import { EventBus } from '@/eventBus'
const cancelToken = axios.CancelToken
let source = cancelToken.source()

export default class AppService {
  static async getApp () {
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId = store.getters['AppStore/getSelectedApp'].uuid
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response }).data.app
    } catch (e) {
      return new RestResponseFactory().process({ response: { staus: 500 } })
    }
  }

  static async getImportQueues (pagination, search, sortBy, descending) {
    source.cancel('Request canceled')
    source = cancelToken.source()
    const token = store.getters['UserStore/getV4Token']
    const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId = store.getters['AppStore/getSelectedApp'].uuid
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/users/import/queue`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            limit: pagination.itemsPerPage,
            page: pagination.page,
            search: search,
            sortBy: sortBy,
            descending: descending
          },
          cancelToken: source.token
        }
      )
      return new RestResponseFactory().process({ response }).data.queues
    } catch (e) {
      return new RestResponseFactory().process({ response: { staus: 500 } })
    }
  }

  static async getApps (search) {
    source.cancel('Request canceled')
    source = cancelToken.source()
    const token = store.getters['UserStore/getToken']
    // const user = store.getters['UserStore/getUser']
    const selectedApp = store.getters['AppStore/getSelectedApp']
    try {
      const response = await axios.get(
                  `${process.env.VUE_APP_AUTH_SERVICE}apps/list`,
                  {
                    params: {
                      search: search
                    },
                    headers: {
                      Authorization: 'Bearer ' + token
                    },
                    cancelToken: source.token
                  }
      )
      if (response.data.status === 'ERROR') {
        throw new Error(response.data.message)
      }
      store.commit('AppStore/SET_APPS', response.data.data.apps)

      if (!selectedApp) {
        await this.selectByApp(response.data.data.apps[0])
        EventBus.$emit('appModules')
      }
    } catch (e) {
      store.commit('AppStore/SET_APPS', [])
    }
  }

  static async getAdvisers () {
    const token = store.getters['UserStore/getToken']
    const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
    const appId = store.getters['AppStore/getSelectedApp'].uuid
    try {
      const response = await axios.get(
                `${process.env.VUE_APP_CONVERSATIONS_URL}/company/${companyId}/app/${appId}/advisers`,
                {
                  headers: {
                    Authorization: 'Bearer ' + token
                  },
                  params: {
                    size: 1000
                  }
                }
      )

      const result: ApiResponse = response.data
      if (result.code === 0) {
        const advisers = result.data.items.sort(function (a, b) {
          if (!a.name || !b.name) return 0
          const nameA = a.name.toUpperCase() // ignore upper and lowercase
          const nameB = b.name.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
        await store.dispatch('AppStore/setAdvisers', advisers)
        return new RestResponseFactory().process({ response }).data
      }
    } catch (e) {
      return new RestResponseFactory().process({ response: { staus: 500 } })
    }
  }

  static async getListCreditCards () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const response = await axios.get(
                  `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/payments/cards`,
                  {
                    headers: {
                      Authorization: 'Bearer ' + token
                    },
                    params: {
                      page: null,
                      sortBy: null,
                      descending: null,
                      limit: 1000
                    }
                  }
      )
      return new RestResponseFactory().process({ response }).data
    } catch (e) {
      return new RestResponseFactory().process({ response: { staus: 500 } })
    }
  }

  static async getAppCustomExtradatas () {
    try {
      const token = store.getters['UserStore/getV4Token']
      const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const response = await axios.get(
                  `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/extraDataCustom`,
                  {
                    headers: {
                      Authorization: 'Bearer ' + token
                    }
                  }
      )
      store.commit('AppStore/SET_APP_CUSTOM_EXTRADATA', response.data.extraData)
      return new RestResponseFactory().process({ response }).data.extraData
    } catch (e) {
      return new RestResponseFactory().process({ response: { staus: 500 } })
    }
  }

  static async selectByApp (app) {
    const token = store.getters['UserStore/getToken']
    store.commit('AppStore/SET_SELECTED_APP', app)
    await store.dispatch('ConversationStore/setTemplates', [], { root: true })
    if (app) {
      const response = await axios.get(
                  `${process.env.VUE_APP_AUTH_SERVICE}company/${app?.company?.id}`,
                  {
                    headers: {
                      Authorization: 'Bearer ' + token
                    }
                  })
      if (response.data.data.status === 'ERROR') {
        throw new Error(response.data.message)
      } else {
        store.commit('CompanyStore/SET_SELECTED_COMPANY', response.data.data.company)
        await UserService.getRoles()
      }
    } else {
      store.commit('CompanyStore/SET_SELECTED_COMPANY', null)
    }
  }

  static async getAppModules () {
    try {
      const token = store.getters['UserStore/getToken']
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      store.commit('AppStore/SET_APP_MODULES', [])
      const response = await axios.get(
        `${process.env.VUE_APP_AUTH_SERVICE}app-modules/${appId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )

      store.commit('AppStore/SET_APP_MODULES', response.data.data)
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { staus: 500 } })
    }
  }

  static async generateRDLink () {
    try {
      const token = store.getters['UserStore/getToken']
      const appId = store.getters['AppStore/getSelectedApp'].uuid
      const url = `${process.env.VUE_APP_RDSTATION_INTEGRATION_URL}/rdstation-integration/auth/request/permission/app/${appId}`
      const response = await axios.get(url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { staus: 500 } })
    }
  }

  static async importRD (code, state) {
    try {
      const token = store.getters['UserStore/getToken']
      const url = `${process.env.VUE_APP_RDSTATION_INTEGRATION_URL}/rdstation-integration/sync/callback`
      const response = await axios.get(
        url,
        {
          headers: {
            Authorization: 'Bearer ' + token
          },
          params: {
            code,
            state
          }
        }
      )
      return new RestResponseFactory().process({ response })
    } catch (e) {

    }
  }
}
