export enum UserPermissions {
  // Apply auto assignation rules
  APPLY_FOR_ASSIGN_RULES_CHATS = 'APPLY_FOR_ASSIGN_RULES_CHATS',
  // End chat
  END_CHAT = 'END_CHAT',
  // Filter all chats
  FILTER_ALL_CHATS = 'FILTER_ALL_CHATS',
  // Filter end chats
  FILTER_END_CHATS = 'FILTER_END_CHATS',
  // Filter waiting chats
  FILTER_WAITING_CHATS = 'FILTER_WAITING_CHATS',
  // NLP_READ
  NLP_READ = 'NLP_READ',
  // Administrator
  ROLE_ADMIN = 'ROLE_ADMIN',
  // Modified Authenticator
  ROLE_AUTHENTICATOR_MODIFICATION = 'ROLE_AUTHENTICATOR_MODIFICATION',
  // View Business
  ROLE_BUSINESS = 'ROLE_BUSINESS',
  // Create Business
  ROLE_BUSINESS_CREATE = 'ROLE_BUSINESS_CREATE',
  // Create Business Contact
  ROLE_BUSINESS_CREATE_CONTACT = 'ROLE_BUSINESS_CREATE_CONTACT',
  // Delete Business
  ROLE_BUSINESS_DELETE = 'ROLE_BUSINESS_DELETE',
  // Delete Business Contact
  ROLE_BUSINESS_DELETE_CONTACT = 'ROLE_BUSINESS_DELETE_CONTACT',
  // Import Business
  ROLE_BUSINESS_IMPORT = 'ROLE_BUSINESS_IMPORT',
  // Call
  ROLE_CALL = 'ROLE_CALL',
  // View Campaigns
  ROLE_CAMPAIGNS = 'ROLE_CAMPAIGNS',
  // Create Campaigns
  ROLE_CAMPAIGNS_CREATE = 'ROLE_CAMPAIGNS_CREATE',
  // Send Campaigns
  ROLE_CAMPAIGNS_SEND = 'ROLE_CAMPAIGNS_SEND',
  // Change Edition mode
  ROLE_CHANGE_EDITION_MODE = 'ROLE_CHANGE_EDITION_MODE',
  // Role Client
  ROLE_CLIENT = 'ROLE_CLIENT',
  // Conversations
  ROLE_CONVERSATIONS = 'ROLE_CONVERSATIONS',
  // Configure Conversations
  ROLE_CONVERSATIONS_CONFIG = 'ROLE_CONVERSATIONS_CONFIG',
  // View Documents
  ROLE_DOCUMENTS = 'ROLE_DOCUMENTS',
  // Delete Documents
  ROLE_DOCUMENTS_DELETE = 'ROLE_DOCUMENTS_DELETE',
  // Edit Document description
  ROLE_DOCUMENTS_DESCRIPTION = 'ROLE_DOCUMENTS_DESCRIPTION',
  // Download Documents
  ROLE_DOCUMENTS_DOWNLOAD = 'ROLE_DOCUMENTS_DOWNLOAD',
  // Upload Documents
  ROLE_DOCUMENTS_UPLOAD = 'ROLE_DOCUMENTS_UPLOAD',
  // View Events
  ROLE_EVENTS = 'ROLE_EVENTS',
  // Add Events Attendees
  ROLE_EVENTS_ATTENDEES = 'ROLE_EVENTS_ATTENDEES',
  // Checking Events
  ROLE_EVENTS_CHECKING = 'ROLE_EVENTS_CHECKING',
  // Create Events
  ROLE_EVENTS_CREATE = 'ROLE_EVENTS_CREATE',
  // Delete Events
  ROLE_EVENTS_DELETE = 'ROLE_EVENTS_DELETE',
  // View Forms
  ROLE_FORMS = 'ROLE_FORMS',
  // Create Forms
  ROLE_FORMS_CREATE = 'ROLE_FORMS_CREATE',
  // Delete Forms
  ROLE_FORMS_DELETE = 'ROLE_FORMS_DELETE',
  // View Journeys
  ROLE_JOURNEYS = 'ROLE_JOURNEYS',
  // Create Journeys
  ROLE_JOURNEYS_CREATE = 'ROLE_JOURNEYS_CREATE',
  // Delete Journeys
  ROLE_JOURNEYS_DELETE = 'ROLE_JOURNEYS_DELETE',
  // View List
  ROLE_LIST = 'ROLE_LIST',
  // Create List
  ROLE_LIST_CREATE = 'ROLE_LIST_CREATE',
  // Delete List
  ROLE_LIST_DELETE = 'ROLE_LIST_DELETE',
  // View Charges
  ROLE_PAYMENTS = 'ROLE_PAYMENTS',
  // Export Charges
  ROLE_PAYMENTS_EXPORT = 'ROLE_PAYMENTS_EXPORT',
  // Pay amount
  ROLE_PAYMENTS_PAY = 'ROLE_PAYMENTS_PAY',
  // Add records to list
  ROLE_RECORDS_ADD_TO_LIST = 'ROLE_RECORDS_ADD_TO_LIST',
  // Disable records
  ROLE_RECORDS_DISABLE = 'ROLE_RECORDS_DISABLE',
  // View Roles
  ROLE_RECORDS_ROLES = 'ROLE_RECORDS_ROLES',
  // Create Roles
  ROLE_RECORDS_ROLES_CREATE = 'ROLE_RECORDS_ROLES_CREATE',
  // Delete Roles
  ROLE_RECORDS_ROLES_DELETE = 'ROLE_RECORDS_ROLES_DELETE',
  // View all records
  ROLE_RECORDS_VIEW_ALL = 'ROLE_RECORDS_VIEW_ALL',
  // View my records
  ROLE_RECORDS_VIEW_MY = 'ROLE_RECORDS_VIEW_MY',
  // Create records
  ROLE_RECORD_CREATE = 'ROLE_RECORD_CREATE',
  // Delete records
  ROLE_RECORD_DELETE = 'ROLE_RECORD_DELETE',
  // Send Email to records
  ROLE_RECORD_DIRECT_EMAIL = 'ROLE_RECORD_DIRECT_EMAIL',
  // Send Direct sms to records
  ROLE_RECORD_DIRECT_SMS = 'ROLE_RECORD_DIRECT_SMS',
  // Download records card
  ROLE_RECORD_DOWNLOAD_CARD = 'ROLE_RECORD_DOWNLOAD_CARD',
  // Edit records
  ROLE_RECORD_EDIT = 'ROLE_RECORD_EDIT',
  // Export records
  ROLE_RECORD_EXPORT = 'ROLE_RECORD_EXPORT',
  // Import records
  ROLE_RECORD_IMPORT = 'ROLE_RECORD_IMPORT',
  // Set habeas data for the record
  ROLE_RECORD_SET_HABEAS_DATA = 'ROLE_RECORD_SET_HABEAS_DATA',
  // View rules
  ROLE_RULES = 'ROLE_RULES',
  // Create rules
  ROLE_RULES_CREATE = 'ROLE_RULES_CREATE',
  // Delete rules
  ROLE_RULES_DELETE = 'ROLE_RULES_DELETE',
  // View Sequences
  ROLE_SEQUENCES = 'ROLE_SEQUENCES',
  // Create Sequences
  ROLE_SEQUENCES_CREATE = 'ROLE_SEQUENCES_CREATE',
  // Delete Sequences
  ROLE_SEQUENCES_DELETE = 'ROLE_SEQUENCES_DELETE',
  // init Sequence
  ROLE_SEQUENCES_INIT = 'ROLE_SEQUENCES_INIT',
  // stop Sequence
  ROLE_SEQUENCES_STOP = 'ROLE_SEQUENCES_STOP',
  // view service hours
  ROLE_SERVICE_HOURS = 'ROLE_SERVICE_HOURS',
  // create service hours
  ROLE_SERVICE_HOURS_CREATE = 'ROLE_SERVICE_HOURS_CREATE',
  // delete service hours
  ROLE_SERVICE_HOURS_DELETE = 'ROLE_SERVICE_HOURS_DELETE',
  // view templates
  ROLE_TEMPLATES = 'ROLE_TEMPLATES',
  // create templates
  ROLE_TEMPLATES_CREATE = 'ROLE_TEMPLATES_CREATE',
  // delete templates
  ROLE_TEMPLATES_DELETE = 'ROLE_TEMPLATES_DELETE',
  // role trainer
  ROLE_TRAINER = 'ROLE_TRAINER',
  // view triggers
  ROLE_TRIGGERS = 'ROLE_TRIGGERS',
  // create triggers
  ROLE_TRIGGERS_CREATE = 'ROLE_TRIGGERS_CREATE',
  // disable triggers
  ROLE_TRIGGERS_DISABLE = 'ROLE_TRIGGERS_DISABLE',
  // emit custom event trigger
  ROLE_TRIGGERS_EMIT_CUSTOM_EVENT = 'ROLE_TRIGGERS_EMIT_CUSTOM_EVENT',
  // view user
  ROLE_USER = 'ROLE_USER',
  // add user
  ROLE_USER_ADD = 'ROLE_USER_ADD',
  // configure user call
  ROLE_USER_CONFIG_CALL = 'ROLE_USER_CONFIG_CALL',
  // delete user
  ROLE_USER_DELETE = 'ROLE_USER_DELETE',
  // invite user
  ROLE_USER_INVITE = 'ROLE_USER_INVITE',
  // update user
  ROLE_USER_UPDATE = 'ROLE_USER_UPDATE',
  // send audios in chat
  SEND_AUDIO_CHAT = 'SEND_AUDIO_CHAT',
  // send images in chat
  SEND_IMAGE_CHAT = 'SEND_IMAGE_CHAT',
  // send pdf in chat
  SEND_PDF_CHAT = 'SEND_PDF_CHAT',
  // take chat from agent
  TAKE_FROM_AGENT_CHAT = 'TAKE_FROM_AGENT_CHAT',
  // TAKE CONVERSATIONS FROM BOT
  TAKE_FROM_BOT_CHAT = 'TAKE_FROM_BOT_CHAT',
  // TRANSFER TO ME CHATS
  TRANSFER_TO_ME_CHATS = 'TRANSFER_TO_ME_CHATS',
  // TRANSFER TO OTHER CHATS
  TRANSFER_TO_OTHER_CHATS = 'TRANSFER_TO_OTHER_CHATS',
  // view all chats
  VIEW_ALL_CHATS = 'VIEW_ALL_CHATS',
  // view all user chats
  VIEW_MY_CHATS = 'VIEW_MY_CHATS',
  // view records from chat
  VIEW_RECORDS_FROM_CHAT = 'VIEW_RECORDS_FROM_CHAT',
  // beta preview
  ROLE_BETA_PREVIEW = 'ROLE_BETA_PREVIEW',
  // checkin attendees
  ROLE_EVENTS_CHECKIN_ATTENDEES = 'ROLE_EVENTS_CHECKIN_ATTENDEES',
  // get event list
  ROLE_EVENTS_GET_LIST = 'ROLE_EVENTS_GET_LIST',
  // ACCESS PARTNER MODULE
  // ROLE_PARTNER_ACCESS = 'ROLE_PARTNER_ACCESS',
}
