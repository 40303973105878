<template>
    <div class="NewToolbar" :style="{ background: $vuetify.theme.dark ? '#171d24' : '#01cfe4' }">
        <div class="NewToolbar-logoAndList">
            <img class="cursor-pointer" @click="goToChats" alt="keybe" height="20px" width="40px"
                 :src="logo">
            <CompanyList/>
            <!-- <div v-if="isInConversations && (isAdmin || isSuperAdmin)" @click="showKeybeIntelMenu" class="cursor-pointer">
              <img style="height:30px; width:30px" :src="keybeIntelIcon" alt=""/>
            </div> -->
        </div>
        <div class="NewToolbar-tabs ml-20" id="NewToolbar-tabs" v-if="!$vuetify.breakpoint.xs">
            <div :id="'NewToolbar-button-'+index" class="NewToolbar__button cursor-pointer"
                 :class="`${selectedTab === index+1? 'selected' : ''}`" v-for="(tab,index) in computedTabs"
                 :key="'Tab '+index" @click="goToLink(tab)">
                <img :src="tab.icon" :alt="tab.name" class="NewToolbar-tabs_icon"/>
            </div>
            <div v-if="isAdmin || isSuperAdmin"
                 class="NewToolbar__button cursor-pointer NewToolbar__button__new"
                 @click="goToKbFlows">
                <div class="NewToolbar__button__new--decoration">
                    <lottie-animation
                            ref="anim"
                            :loop="true"
                            :animationData="require('@/assets/animations/decoration.json')"
                    />
                </div>
                <img class="NewToolbar-tabs_icon" :src="kbFlowsIcon" alt=""/>

                <div class="NewToolbar__button__new--box">
                    <span class="NewToolbar__button__new--box--text">{{ $t('new') }}</span>
                </div>
            </div>
        </div>
        <div class="NewToolbar-actions" id="NewToolbar-actions">
            <HelpMenu v-if="!$vuetify.breakpoint.smAndDown"/>
            <AccountStatus v-if="!$vuetify.breakpoint.smAndDown"/>
            <MainMenu v-if="!$vuetify.breakpoint.smAndDown"/>
            <!--      <v-btn icon @click="openTaskCenter" class="mr-2 -ml-1 taskCenterButton" v-if="!$vuetify.breakpoint.smAndDown">-->
            <!--        <v-avatar size="10px" color="red" class="taskCenterButton-redDot" v-if="hasPendingTasks"/>-->
            <!--        <img :src="notificationsIcon" alt="notifications">-->
            <!--      </v-btn>-->
            <v-btn id="NewToolbar-hamburgerMenu" icon @click="openMenu">
                <img :src="menuIcon" alt="menu">
            </v-btn>
        </div>
    </div>
</template>

<script>
import CompanyList from '@/components/layouts/components/toolbar/companyList'
import AccountStatus from '@/components/layouts/components/toolbar/AccountStatus'
import MainMenu from '@/components/layouts/components/toolbar/mainMenu'
import link from '@/utils/links'
import operation from '@/utils/operations'
import { EventBus } from '@/eventBus'
import HelpMenu from '@/components/shared/components/HelpMenu'
import { mapGetters, mapState } from 'vuex'
import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'NewToolbar',
  components: { HelpMenu, MainMenu, AccountStatus, CompanyList, LottieAnimation },
  computed: {
    ...mapState('AppStore', ['hasPendingTasks']),
    ...mapState('PipelinesStore', ['pipelineId']),
    ...mapState('UserStore', ['user']),
    ...mapGetters('UserStore', ['isAdmin', 'isSuperAdmin']),
    computedTabs () {
      let tabs = []
      if (this.isSuperAdmin) {
        tabs = [
          {
            name: this.$t('chats'),
            link: link.conversations,
            icon: `${process.env.VUE_APP_ASSETS_URL}/common/chat.svg`
          },
          {
            name: this.$t('contacts'),
            link: link.people,
            icon: `${process.env.VUE_APP_ASSETS_URL}/common/person.svg`
          },
          {
            name: this.$t('sales'),
            link: link.pipelines,
            icon: `${process.env.VUE_APP_ASSETS_URL}/common/sales-icon.svg`
          },
          {
            name: this.$t('metrics'),
            link: link.clientDashboard,
            icon: `${process.env.VUE_APP_ASSETS_URL}/common/metric.svg`
          }
        ]
      } else if (this.administratorRoles.includes('ROLE_ADMIN')) {
        tabs = [
          {
            name: this.$t('chats'),
            link: link.conversations,
            icon: `${process.env.VUE_APP_ASSETS_URL}/common/chat.svg`
          },
          {
            name: this.$t('contacts'),
            link: link.people,
            icon: `${process.env.VUE_APP_ASSETS_URL}/common/person.svg`
          },
          {
            name: this.$t('sales'),
            link: link.pipelines,
            icon: `${process.env.VUE_APP_ASSETS_URL}/common/sales-icon.svg`
          },
          {
            name: this.$t('metrics'),
            link: link.clientDashboard,
            icon: `${process.env.VUE_APP_ASSETS_URL}/common/metric.svg`
          }
        ]
      } else {
        tabs = this.tabs
      }
      return tabs
    },
    ...mapState('UserStore', ['administratorRoles']),
    ...mapGetters('UserStore', ['isSuperAdmin']),
    isInConversations () {
      return this.$route.path === link.conversations
    }
  },
  data () {
    return {
      selectedTab: 0,
      wowMagic: false,
      kbFlowsIcon: `${process.env.VUE_APP_ASSETS_URL}/kb-flows-1.svg`,
      newLabelDecorationIcons: `${process.env.VUE_APP_ASSETS_URL}/new_label_decoration.png`,
      tabs: [
        {
          name: 'Chats',
          link: link.conversations,
          icon: `${process.env.VUE_APP_ASSETS_URL}/common/chat.svg`
        },
        {
          name: 'Contactos',
          link: link.people,
          icon: `${process.env.VUE_APP_ASSETS_URL}/common/person.svg`
        },
        {
          name: 'Ventas',
          link: link.pipelines,
          icon: `${process.env.VUE_APP_ASSETS_URL}/common/sales-icon.svg`
        }
      ],
      menuIcon: `${process.env.VUE_APP_ASSETS_URL}/common/menu.svg`,
      notificationsIcon: `${process.env.VUE_APP_ASSETS_URL}/common/notifications.svg`,
      logo: `${process.env.VUE_APP_ASSETS_URL}/logo.svg`,
      keybeIntelIcon: `${process.env.VUE_APP_ASSETS_URL}/conversation-module/keybe-intel.svg`
    }
  },
  methods: {
    goToKbFlows () {
      EventBus.$emit('openKbFlows')
    },
    goToLink (tab) {
      this.$store.commit('UsersStore/SET_LAST_PAGE', null)
      this.$store.commit('UsersStore/SET_ITEMS_PER_PAGE', null)
      this.$store.commit('UsersStore/SET_ADVANCED_SEARCH', {})
      if (tab.link === link.pipelines && this.pipelineId) {
        this.$router.push({
          name: 'Pipeline',
          params: { id: this.pipelineId }
        })
      } else this.$router.push(tab.link)
    },
    openMenu () {
      EventBus.$emit('openSideMenu')
    },
    getSelectedTab () {
      if (operation.areEqual(this.$route.path, link.conversations)) {
        return 1
      } else if (operation.areEqual(this.$route.path, link.people) || operation.areEqual(this.$route.path, link.segments) ||
                operation.areEqual(this.$route.path, link.businesses) || operation.areEqual(this.$route.name, 'Person') ||
                operation.areEqual(this.$route.name, 'Segment') || operation.areEqual(this.$route.name, 'Business')) {
        return 2
      } else if (operation.areEqual(this.$route.path, link.pipelines) || operation.areEqual(this.$route.name, 'Pipeline')) {
        return 3
      } else if (operation.areEqual(this.$route.path, link.clientDashboard)) {
        return 4
      } else return 0
    },
    openTaskCenter () {
      EventBus.$emit('openTaskCenter')
    },
    goToChats () {
      if (this.$route.path === link.conversations) {
        return
      }
      this.$router.push(link.conversations)
    },
    showKeybeIntelMenu () {
      EventBus.$emit('showKeybeIntelMenu')
    }
  },
  mounted () {
    this.selectedTab = this.getSelectedTab()
  },
  watch: {
    '$route.path' () {
      this.selectedTab = this.getSelectedTab()
    }
  }
}
</script>

<style scoped lang="scss">
.NewToolbar {
  height: 58px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 10px;
  box-sizing: border-box;
}

.NewToolbar-logoAndList {
  display: flex;
  align-items: center;
  max-width: 600px;
}

.NewToolbar-tabs {
  display: flex;
  align-items: center;
  color: #FFFFFF;

  .selected {
    background-color: rgba(255, 255, 255, 0.32);
    border-radius: 30px;
  }
}

.NewToolbar-tabs_text {
  color: #FFFFFF;
  margin-left: 0.3rem;

  @media (max-width: 1220px) {
    font-size: 0.7rem;
  }
}

.NewToolbar-tabs_icon {
  height: 18px;
  width: 18px;

  @media (max-width: 1100px) {
    height: 12px;
    width: 12px;
  }
}

.NewToolbar-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  max-width: 400px;
}

.taskCenterButton {
  position: relative;

  .taskCenterButton-redDot {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.NewToolbar__button {
  padding: 13px;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 17px;
    width: auto;
  }
}

.NewToolbar__button__new {
  position: relative;

  &--decoration {
    position: absolute;
    top: -26px;
    right: -50px;
    z-index: 2;
  }

  &--box {
    position: absolute;
    top: 3px;
    right: -20px;
    width: 34px;
    height: 15px;
    border-radius: 5px;
    background-color: #3AF69B;
    display: flex;
    align-items: center;
    justify-content: center;

    &--text {
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      color: black;
    }
  }
}
</style>
