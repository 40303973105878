import { EventBus } from '@/eventBus'
import AppService from '@/services/AppService'
import axios from 'axios'

export const SigninStore = {
  namespaced: true,
  actions: {
    async loginEmail (context, userData: { email: string; password: string }) {
      const response = await axios.post(
                `${process.env.VUE_APP_AUTH_SERVICE}auth/email`,
                userData
      )

      if (response.data.status === 'SUCCESS') {
        context.commit('UserStore/SET_KEYBE_USER', response.data.data.user, { root: true })
        context.commit('UserStore/SET_TOKEN', response.data.data.token, { root: true })
        context.commit('UserStore/SET_V4TOKEN', response?.data?.data?.token, { root: true })
        context.commit('UserStore/CHANGE_KEYBE_AUTH', true, { root: true })
        context.commit('UserStore/SET_FIRST_RENDER', false, { root: true })
        await AppService.getApps('')

        return true
      } else {
        EventBus.$emit('toast', 'error', response.data.message)
        return false
      }
    },
    async loginSocial (
      { commit },
      user
    ): Promise<{ success: boolean; message: string }> {
      const response = {
        success: false,
        message: ''
      }
      const authResponse = await axios.post(
                `${process.env.VUE_APP_AUTH_SERVICE}auth/social`,
                user
      )

      if (authResponse.data.status === 'SUCCESS') {
        commit('UserStore/SET_KEYBE_USER', authResponse.data.data.user, { root: true })
        commit('UserStore/SET_TOKEN', authResponse.data.data.token, { root: true })
        commit('UserStore/CHANGE_KEYBE_AUTH', true, { root: true })
        response.success = true
      } else {
        response.message = authResponse.data.message
      }

      return response
    }
  }
}
