<template>
  <v-dialog :value="value" persistent max-width="800px">
    <div class="modal-container">
      <div class="d-flex w-full justify-end">
        <v-btn icon @click="$emit('input', false)" class="-mt-12 -mr-12">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <img loading="lazy" src="https://storage.googleapis.com/keybe/files/bluebird/billing/rocket.svg"
           alt="credit card" class="mb-8">
      <strong>{{ $t('upgradeToPro') }}</strong>
      <span>{{ $t('upgradeToProMessage') }}</span>
      <v-btn @click="upgrade" large elevation="0" dark color="#0FAEF6" rounded
             class="mt-6">{{ $t('upgradeToProButton') }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/eventBus'

export default {
  name: 'UpgradeToPro',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    upgrade () {
      this.$emit('input', false)
      EventBus.$emit('upgrade-to-pro')
    }
  }
}
</script>

<style scoped>
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  padding: 4rem
}
</style>
