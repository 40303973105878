import axios from 'axios'

export const SegmentsStore = {
  namespaced: true,
  state: {
    segments: [],
    lastPage: null,
    scrollPosition: null
  },
  mutations: {
    SET_SEGMENTS (state, segments) {
      state.segments = segments
    },
    SET_LAST_PAGE (state, lastPage) {
      state.lastSavedPage = lastPage
    },
    SET_SCROLL_POSITION (state, position) {
      state.scrollPosition = position
    }
  },
  actions: {
    async getSegments (context, { pagination, search }) {
      try {
        const companyId = context.rootState.CompanyStore.selectedCompany.uuid
        const appId = context.rootState.AppStore.selectedApp.uuid
        const token = context.rootState.UserStore.v4Token
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/lists`,
          {
            headers: {
              Authorization: 'Bearer ' + token
            },
            params: {
              page: pagination.page,
              limit: pagination.itemsPerPage,
              sortBy: '',
              descending: true,
              search: search
            }
          }
        )
        return response.data.lists
      } catch (e) {
        console.error(e)
      }
    },
    async getSegmentDetailsUsers (context, { segmentId, pagination, search }) {
      try {
        const companyId = context.rootState.CompanyStore.selectedCompany.uuid
        const appId = context.rootState.AppStore.selectedApp.uuid
        const token = context.rootState.UserStore.v4Token
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/lists/${segmentId}/users`,
          {
            headers: {
              Authorization: 'Bearer ' + token
            },
            params: {
              page: pagination.page,
              limit: 20,
              sortBy: '',
              descending: true,
              search: search
            }
          }
        )
        return response.data.listUsers.rows
      } catch (e) {
        console.error(e)
      }
    },
    async deleteUserFromSegment (context, { segmentId, userId }) {
      try {
        const appId = context.rootState.AppStore.selectedApp.uuid
        const companyId = context.rootState.CompanyStore.selectedCompany.uuid
        const token = context.rootState.UserStore.v4Token
        const response = await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/lists/${segmentId}/users/${userId}`,
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        )
        return response
      } catch (e) {
        console.error(e)
      }
    },
    async updateSegment (context, { segmentId, name }) {
      try {
        const appId = context.rootState.AppStore.selectedApp.uuid
        const companyId = context.rootState.CompanyStore.selectedCompany.uuid
        const token = context.rootState.UserStore.v4Token
        const data = {
          name: name
        }
        const response = await axios.put(
          `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/lists/${segmentId}`,
          data,
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        )
        return response
      } catch (e) {
        console.error(e)
      }
    },
    async deleteSegment (context, segmentId) {
      try {
        const appId = context.rootState.AppStore.selectedApp.uuid
        const companyId = context.rootState.CompanyStore.selectedCompany.uuid
        const token = context.rootState.UserStore.v4Token
        const response = await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/lists/${segmentId}`,
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        )
        return response
      } catch (e) {
        console.error(e)
      }
    },
    async createSegment (context, { name }) {
      try {
        const appId = context.rootState.AppStore.selectedApp.uuid
        const companyId = context.rootState.CompanyStore.selectedCompany.uuid
        const token = context.rootState.UserStore.v4Token
        const data = {
          name: name
        }
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/companies/${companyId}/apps/${appId}/lists`,
          data,
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        )
        return response
      } catch (e) {
        console.error(e)
      }
    }
  }
}
