import axios from 'axios'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'

export default class LoginService {
  static async login (data : { email: string; password: string }) {
    const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/email`
    try {
      const response = await axios.post(url, data)
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async resetEmailPassword (email: string) {
    try {
      const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/reset/email`
      const response = await axios.post(url, { email })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async resetPhonePassword (phone: string) {
    const url = `${process.env.VUE_APP_BASE_URL}/login/phone/reset`
    const response = await axios.post(url, { phone })
    return response.data
  }

  static async resetPassword (password: string, code: string) {
    try {
      const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/reset-password`
      const response = await axios.post(url, { code, password, confirmPassword: password })
      return new RestResponseFactory().process({ response })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }

  static async validateV4Token (token: string) {
    try {
      const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/info`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = await response.json()
      const axiosResponse = {
        data: data.data,
        status: response.status,
        statusText: response.statusText
      }
      return new RestResponseFactory().process({ response: axiosResponse })
    } catch (e) {
      return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
    }
  }
}
