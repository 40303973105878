export enum Channels {
    Facebook = 'facebookMessenger',
    Instagram = 'instagram',
    Smooch = 'smooch_wp',
    Gupshup = 'gupshup_wp',
    Hibot = 'hibot_wp',
    KeybeWp = 'keybe_wp',
    Web = 'web',
    Wizard = 'wizard',
    Keybe = 'keybe',
}
