<template>
  <button
    :class="disabled ? 'keybe-button_disabled' : `keybe-button ${colorClass[color]}`"
    :style="{ minWidth: forceWidth ? forceWidth + '!important' : '' }"
    ref="button_body">
    <div v-if="loading"
         :style="{ width: (width + 'px'),
                   height: (height + 'px') }">
      <v-progress-circular
        size="20"
        :color="iconColor[color]"
        indeterminate/>
    </div>
    <div class="keybe-button_content" v-else>
      <v-icon
        v-if="icon"
        size="22"
        :color="iconColor[color]"
        class="keybe-button_icon">
        {{icon}}
      </v-icon>
      <img v-if="img"
           :src="img"
           class="keybe-button_img"/>
      <h4>{{ text }}</h4>
    </div>
  </button>
</template>

<script>
import Colors from '@/utils/Colors'

export default {
  name: 'KeybeButton',
  mounted () {
    this.width = this.$refs.button_body.clientWidth - 48
    this.height = this.$refs.button_body.clientHeight - 22.4
  },
  props: {
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'blue',
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    img: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    forceWidth: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      width: 0,
      height: 0,
      colorClass: {
        gray: 'keybe-gray',
        blue: 'keybe-blue',
        green: 'keybe-green',
        outline: 'keybe-outline',
        outlineWhite: 'keybe-outline-white'
      },
      iconColor: {
        outline: Colors.dark2,
        gray: Colors.white,
        blue: Colors.white,
        green: Colors.white
      }
    }
  }
}
</script>

<style scoped lang="scss">

.keybe-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 1.5rem;
  border-radius: 50px;
  min-width: 160px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
  outline: none;
  box-sizing: border-box;
  height: 50px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom-left-radius: 50px !important;
  border-bottom-right-radius: 50px !important;

    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 0.5;
    }
  }

.keybe-button_disabled {
  padding: 0.7rem 1.5rem;
  border-radius: 50px;
  min-width: 160px;
  font-style: normal;
  text-align: center;
  cursor: default;
  background-color: $dark-3;
  color: $dark-1;
  border-bottom-left-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.keybe-button_content {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer!important;;
}

.keybe-blue {
  color: #fff;
  background-color: $blue-4;
}

.keybe-green {
  color: #fff;
  background-color: $verde-bird;
}

.keybe-outline {
  background-color: transparent;
  border: 2px solid $dark-2;
  color: $dark-2;
}

.keybe-gray {
  color: #fff;
  background-color: $dark-2;
}

.keybe-button_img {
  height: 22px;
  width: 22px;
  margin-right: 0.5rem;
}

.keybe-button_icon {
  height: 22px;
  width: 22px;
  margin-right: 0.5rem;
}

.keybe-outline-white {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

h4 {
  font-size: 1rem !important;
  cursor: pointer!important;
}
</style>
