import axios from 'axios'
import store from '@/store/index'
import { RestResponseFactory } from '@/models/apis/RestResponse'
import { StatusCodes } from '@/models/apis/StatusCodes'
import RollbarFactory from '@/factory/RollbarFactory'
import { GenericResponse } from '@/models/apis/GenericResponse'

export default class ConversationsService {
    static conversationsRollbar = RollbarFactory.getInstance('conversations')

    static async getUtm (userId: string) {
      try {
        const url = `${process.env.VUE_APP_AUTH_SERVICE}utm/${userId}`
        const token = store.getters['UserStore/getToken']
        const response = await axios.get(url, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        return response.data.data
      } catch (error) {
        console.log(error)
        return ''
      }
    }

    static async getUserId (roomId) {
      try {
        const appId = store.getters['AppStore/getSelectedApp'].uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
        const token = store.getters['UserStore/getToken']
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/company/${companyId}/app/${appId}/room/${roomId}/userDetails`
        const response = await axios.get(url, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        // return response.data.data.item.userId
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    static async getTotalMessages (data) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const token = store.getters['UserStore/getToken']
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/http-billing/messages`
        const response = await axios.post(url, {
          companyUUID: companyId,
          appUUID: appId,
          fromDate: data.from + 'T00:00:00.000Z',
          toDate: data.to + 'T23:59:59.999Z'
        }, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    static async getAutomaticMessages (data) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const token = store.getters['UserStore/getToken']
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/http-billing/messages-automatic`
        const response = await axios.post(url, {
          companyUUID: companyId,
          appUUID: appId,
          fromDate: data.from + 'T00:00:00.000Z',
          toDate: data.to + 'T23:59:59.999Z'
        }, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    static async getProactiveMessages (data: { from: string; to: string, type?: string }) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const token = store.getters['UserStore/getToken']
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/http-billing/messages-proactive`
        const response = await axios.post(url, {
          companyUUID: companyId,
          appUUID: appId,
          fromDate: data.from + 'T00:00:00.000Z',
          toDate: data.to + 'T23:59:59.999Z',
          type: data.type
        }, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    static async getProactiveMessagesByCountry (data: { country: string; from: string; to: string, type?: string }) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const token = store.getters['UserStore/getToken']
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/http-billing/messages-proactiveByCountry`
        const response = await axios.post(url, {
          companyUUID: companyId,
          appUUID: appId,
          indicative: data.country,
          fromDate: data.from + 'T00:00:00.000Z',
          toDate: data.to + 'T23:59:59.999Z',
          type: data.type
        }, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    static async getMediaMessages (data) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const token = store.getters['UserStore/getToken']
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/http-billing/messages-proactiveByMediaType`
        const response = await axios.post(url, {
          companyUUID: companyId,
          appUUID: appId,
          fromDate: data.from + 'T00:00:00.000Z',
          toDate: data.to + 'T23:59:59.999Z'
        }, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    static async getSessionTypeMessages (data) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const token = store.getters['UserStore/getToken']
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/http-billing/messages-sessionType`
        const response = await axios.post(url, {
          companyUUID: companyId,
          appUUID: appId,
          fromDate: data.from + 'T00:00:00.000Z',
          toDate: data.to + 'T23:59:59.999Z',
          type: data.type,
          line: data.line
        }, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    static async getSessionTypeMessagesByType (data) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const token = store.getters['UserStore/getToken']
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/http-billing/messages-provider-sessionType`
        const response = await axios.post(url, {
          companyUUID: companyId,
          appUUID: appId,
          fromDate: data.from + 'T00:00:00.000Z',
          toDate: data.to + 'T23:59:59.999Z',
          line: data.line,
          type: data.type
        }, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    static async getFreeLines (data) {
      try {
        const appId = store.getters['AppStore/getSelectedApp']?.uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany']?.uuid
        const token = store.getters['UserStore/getToken']
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/http-billing/free-lines`
        const response = await axios.post(url, {
          companyUUID: companyId,
          appUUID: appId,
          fromDate: data.from + 'T00:00:00.000Z',
          toDate: data.to + 'T23:59:59.999Z'
        }, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: 500 } })
      }
    }

    static async setOneSignalStatus (data: {
        appUUID?: string,
        companyUUID?: string,
        oneSignalPlayerId?: string,
        device?: string,
        active: boolean,
        phone?: string,
        email?: string,
    }) {
      try {
        const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/config/notifier`
        const user = store.getters['UserStore/getUser']
        const token = store.getters['UserStore/getToken']
        const selectedApp = store.getters['AppStore/getSelectedApp']
        const oneSignalUserId = store.getters['ConversationStore/getOneSignalUserId']

        const postData = {
          ...data,
          userId: user?.userId,
          email: user?.email || data.email || '',
          oneSignalPlayerId: oneSignalUserId,
          device: 'web',
          appUUID: data.appUUID || selectedApp?.uuid
        }
        const response = await axios.post(url, postData,
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          })
        // store.commit('ConversationStore/SET_ONE_SIGNAL_REGISTERED',
        //   response.data.code === 0 ? data.active : false
        // )
        return new RestResponseFactory().process({ response })
      } catch (error) {
        this.conversationsRollbar.reportError('Register user for notifications error', error)
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    static async restoreUserContext () {
      try {
        const appId = store.getters['AppStore/getSelectedApp'].uuid
        const companyId = store.getters['CompanyStore/getSelectedCompany'].uuid
        const token = store.getters['UserStore/getToken']
        const userId = store.getters['UserStore/getUser'].customId
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/company/${companyId}/app/${appId}/restart-context/${userId}`
        const response = await axios.post(url, {}, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    // KB 6 function
    static async getRoomEmotions (roomId) {
      if (!roomId) return
      try {
        const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/sentiments/room/${roomId}`
        const response = await axios.get(url)
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    static async getSentimentsConfig (): Promise<GenericResponse> {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/configurations/app/${appId}/sentiments`
      try {
        const response = await axios.get(url)
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    static async setSentimentsConfig (data): Promise<GenericResponse> {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/configurations/app/${appId}/sentiments`
      const user = store.getters['UserStore/getUser']
      const advisorId = user?.userId
      const advisorName = user?.name
      try {
        const response = await axios.put(url, { ...data, advisorId, advisorName })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    // Action Suggestions Funcions
    static async getActionSuggestionsConfig (): Promise<GenericResponse> {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/configurations/app/${appId}/action-suggestions`
      try {
        const response = await axios.get(url)
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    static async setActionSuggestionsConfig (data): Promise<GenericResponse> {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/configurations/app/${appId}/action-suggestions`
      try {
        const response = await axios.put(url, data)
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    // Message Suggestions Funcions
    static async getMessageSuggestionsConfig (): Promise<GenericResponse> {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/configurations/app/${appId}/message-suggestions`
      try {
        const response = await axios.get(url)
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    static async setMessageSuggestionsConfig (data): Promise<GenericResponse> {
      const appId = store.getters['AppStore/getSelectedApp']?.uuid
      const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/configurations/app/${appId}/message-suggestions`
      const user = store.getters['UserStore/getUser']
      const advisorId = user?.userId
      const advisorName = user?.name

      try {
        const response = await axios.put(url, { ...data, advisorId, advisorName })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    // function to update the state of an action suggestion in a message
    static async updateActionSuggestionState (messageId, actionSuggestionId, state): Promise<GenericResponse> {
      const url = `${process.env.VUE_APP_CONVERSATIONS_URL}/message/${messageId}/action-suggestions/${actionSuggestionId}`
      try {
        const response = await axios.put(url, { state })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }

    // mark a message as read
    static async markAsRead ({ roomId, advisorId }): Promise<GenericResponse> {
      const url = `${process.env.VUE_APP_CONVERSATIONS_API_URL}/inbox/room/read`
      try {
        const response = await axios.post(url, {
          roomId,
          userId: advisorId
        })
        return new RestResponseFactory().process({ response })
      } catch (error) {
        return new RestResponseFactory().process({ response: { status: StatusCodes.HTTP_500_INTERNAL_SERVER_ERROR } })
      }
    }
}
